import React from 'react';
import { useSelector } from 'react-redux';

import './sidenav.scss';

const SideNav = (props) => {
  const isPresentation = useSelector((state) => state.home.isPresentation);
  const isTransparent = useSelector((state) => state.home.isTransparent);

  return (
    <div
      id={props.id}
      className={`sidenav ${props.isDark && 'sidenav--dark'} ${
        props.position === 'left' ? 'float-start' : 'float-end'
      } d-flex-column h-100 ${isTransparent ? 'invisible' : ''}`}
    >
      {props.heading && (
        <div className="sidenav__heading">
          <h2 className="text-capitalize f-title">{props.heading}</h2>
        </div>
      )}
      <div className="sidenav__body d-flex-column">{props.children}</div>
    </div>
  );
};

export default SideNav;
