import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FAQ_ENUM } from '../../constants/master-data';
import { reqGetUserProfile } from '../../reduxs/user/action';
import ConfirmMessage from './confirm-message-modal';
import { faqQuestion } from './dist/config';
import SupportTicket from './support-ticket-modal';

const BackendCMSFrequentlyAQS = () => {
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [questionType, setQuestionType] = useState(FAQ_ENUM.SALES_GALLERY_HARDWARE);
  const authUser = useSelector((state) => state.user.data);
  useEffect(() => {
    dispatch(reqGetUserProfile());
  }, []);
 
  return (
    <>
      <div className="container">
        <div className="page-header d-flex align-items-center">
          <h1 className="f-title mb-0">Frequently Asked Questions</h1>
        </div>
        <div className="page-body">
          <div className="table-responsive table-question wrapper-scroll-y">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>
                    <a
                      href="#"
                      className={
                        questionType === FAQ_ENUM.SALES_GALLERY_HARDWARE ? 'text-underline' : ''
                      }
                      onClick={(e) => setQuestionType(FAQ_ENUM.SALES_GALLERY_HARDWARE)}
                    >
                      Sales gallery hardware
                    </a>
                  </th>
                  <th className="text-center">
                    <a
                      href="#"
                      className={questionType === FAQ_ENUM.PHYSICAL_MODEL ? 'text-underline' : ''}
                      onClick={(e) => setQuestionType(FAQ_ENUM.PHYSICAL_MODEL)}
                    >
                      Physical model
                    </a>
                  </th>
                  <th className="text-center">
                    <a
                      href="#"
                      className={questionType === FAQ_ENUM.TV_DISPLAY ? 'text-underline' : ''}
                      onClick={(e) => setQuestionType(FAQ_ENUM.TV_DISPLAY)}
                    >
                      TV Display
                    </a>{' '}
                  </th>
                  <th className="text-center">
                    <a
                      href="#"
                      className={questionType === FAQ_ENUM.IPAD ? 'text-underline' : ' '}
                      onClick={(e) => setQuestionType(FAQ_ENUM.IPAD)}
                    >
                      Ipad
                    </a>
                  </th>
                  <th className="text-center">
                    <a
                      href="#"
                      className={questionType === FAQ_ENUM.APPLICATION ? 'text-underline' : ''}
                      onClick={(e) => setQuestionType(FAQ_ENUM.APPLICATION)}
                    >
                      Application
                    </a>
                  </th>
                  <th style={{ textAlign: 'right' }}>
                    <a href="#" className="text-underline" onClick={(e) => setIsOpenModal(true)}>
                      Support Tickets
                    </a>
                  </th>
                </tr>
              </thead>
              <tbody>
                {questionType === FAQ_ENUM.SALES_GALLERY_HARDWARE &&
                  faqQuestion.saleGalleryHardware.map((item, index) => (
                    <tr key={index}>
                      <td colSpan="6">
                        <p>Q. {item.question}</p>
                        <p>A. {item.answer}</p>
                      </td>
                    </tr>
                  ))}
                {questionType === FAQ_ENUM.PHYSICAL_MODEL &&
                  faqQuestion.physicalModel.map((item, index) => (
                    <tr key={index}>
                      <td colSpan="6">
                        <p>Q. {item.question}</p>
                        <p>A. {item.answer}</p>
                      </td>
                    </tr>
                  ))}
                {questionType === FAQ_ENUM.TV_DISPLAY &&
                  faqQuestion.tvDisplay.map((item, index) => (
                    <tr key={index}>
                      <td colSpan="6">
                        <p>Q. {item.question}</p>
                        {item.answer.suggest ? (
                          <p>
                            A. {item.answer.suggest}
                            <br />
                            &ensp;{item.answer.option1}
                            <br />
                            &ensp;{item.answer.option2}
                            <br />
                            &ensp;{item.answer.option3}
                            <br />
                            &ensp;{item.answer.option4}
                            <br />
                            &ensp;{item.answer.option5}
                            <br />
                          </p>
                        ) : (
                          <p>A. {item.answer}</p>
                        )}
                      </td>
                    </tr>
                  ))}
                {questionType === FAQ_ENUM.IPAD &&
                  faqQuestion.ipad.map((item, index) => (
                    <tr key={index}>
                      <td colSpan="6">
                        <p>Q. {item.question}</p>
                        <p>A. {item.answer}</p>
                      </td>
                    </tr>
                  ))}
                {questionType === FAQ_ENUM.APPLICATION &&
                  faqQuestion.application.map((item, index) => (
                    <tr key={index}>
                      <td colSpan="6">
                        <p>Q. {item.question}</p>
                        <p>A. {item.answer}</p>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="page-footer d-flex flex-column justify-content-center align-items-center">
          <span>HAVE AN UNANSWERED QUESTION?</span>
          <span>Please complete a support ticket. Thank you</span>
        </div>
      </div>
      {isOpenModal && (
        <SupportTicket
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          setSuccessModal={setSuccessModal}
          user={authUser}
        />
      )}
      {successModal && (
        <ConfirmMessage successModal={successModal} setSuccessModal={setSuccessModal} />
      )}
    </>
  );
};
export default BackendCMSFrequentlyAQS;
