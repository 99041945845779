import ApiClient from '../apiClient';
import { authFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(authFetcher);

const getAssetsList = (data) => client.get(endPoint.TEST_OBJECT_GET_LIST, data);

export default {
  getAssetsList,
};
