export const UNIT_ENUM = {
  SQUARE: 'square',
  SQUARE_METER: 'squareMeter',
  ACRE: 'acre',
  HECTARE: 'hectare',
};
export const PROPERTY_TYPE_ENUM = {
  HOUSE: 'house',
  UNIT: 'unit',
  APARTMENT: 'apartment',
  OFFICE: 'office',
  VILLA: 'villa',
  LAND: 'land',
  TOWNHOUSE: 'townhouse',
};
export const AVAILABILITY_STATUS_ENUM = {
  AVAILABLE_NOW: 'available_now',
  RELEASING_SOON: 'releasing_soon',
  SOLD: 'sold',
  IN_CONTRACT: 'in_contract',
};
export const MEDIA_TYPE_ENUM = {
  INTERIOR_ID: 'interior_id',
  FLOORPLAN: 'floorplan',
  IMAGE: 'image',
  VIDEO: 'video',
  '360_PANO_TOUR': '360_pano_tour',
};

export default {
  UNIT_ENUM,
  PROPERTY_TYPE_ENUM,
  AVAILABILITY_STATUS_ENUM,
  MEDIA_TYPE_ENUM,
};

export const FAQ_ENUM = {
  SALES_GALLERY_HARDWARE: 1,
  PHYSICAL_MODEL: 2,
  TV_DISPLAY: 3,
  IPAD: 4,
  APPLICATION: 5,
};
