export default {
  EXAMPLE: '/example',

  SIGN_IN: '/v1/auth/sign-in',
  SIGN_OUT: '/v1/auth/sign-out',
  RESET_PASSWORD: '/v1/auth/reset-password',
  FORGOT_PASSWORD: '/v1/auth/forgot-password',
  GET_ME: '/v1/me',

  UNITS_GET_LIST: '/v1/units',
  UNITS_PUT_UPDATE: '/v1/units/:unitId',
  UNITS_GET_DETAIL: '/v1/units/:unitId',

  AMENITIES_GET_LIST: '/v1/amenities',
  AMENITIES_DETAIL: '/v1/amenities/:id',

  ASSSETS_GET_LIST: '/v1/assets',
  ASSSETS_GET_DETAIL: '/v1/assets/:assetId',

  GALLERY_GET_DETAIL: '/v1/gallery/:galleryId',

  LANDING_GET_DETAIL: '/v1/landings',

  MEDIA_GET_LIST: '/v1/media',
  MEDIA_GET_DETAIL: '/v1/media/:mediaId',

  AREA_GET_LIST: '/v1/areas',
  AREA_GET_DETAIL: '/v1/areas/:areaId',

  HOTSPOT_GET_LIST: '/v1/hotspots',
  HOTSPOT_GET_DETAIL: '/v1/hotspots/:hotspotId',

  LIFX_SET_STATE: '/v1/lights/:selector/state',
  LIFX_SET_STATES: '/v1/lights/states',
  LIFX_GET_STATES: '/v1/lights/all',

  POI_GET_LIST: '/v1/pois',
  POI_GET_DETAIL: '/v1/pois',

  GET_TEAM_DETAIL: '/v1/cms/teams/:teamId',

  TEST_OBJECT_GET_LIST: '/v1/test-objects',

  GET_EXCUTIVE_SUMMARY_DETAIL: '/v1/cms/excutive-summary/:excutiveSummaryId',

  _3D_SETTINGS: '/v1/3d-settings',

  CUSTOMER_LIST: '/v1/customers',
  CUSTOMER_DETAIL: '/v1/customers/:id',
  CUSTOMER_CREATE: '/v1/customers',
  CUSTOMER_UPDATE: '/v1/customers/:id',
  CUSTOMER_DELETE: '/v1/customers/:id',

  AGENT_LIST: '/v1/agents',
  AGENT_DETAIL: '/v1/agents/:id',
  AGENT_CREATE: '/v1/agents',
  AGENT_UPDATE: '/v1/agents/:id',
  AGENT_DELETE: '/v1/agents/:id',

  SESSION_LIST: '/v1/sessions',
  SESSION_DETAIL: '/v1/sessions/:id',
  SESSION_CREATE: '/v1/sessions',
  SESSION_UPDATE: '/v1/sessions/:id',
  SESSION_DELETE: '/v1/sessions/:id',
  SESSION_INVITATION: '/v1/sessions/send-invitation',

  USER_LIST: '/v1/users',
  USER_DETAIL: '/v1/users/:id',

  MODAL_DETAIL: '/v1/modals/:id',
  MODAL_LIST: '/v1/modals',

  PREFERENCE_UPDATE: '/v1/preference',
  PREFERENCE_CUSTOMER: '/v1/preference-detail',

  SUPPORT_TICKET: '/api/v2/requests.json'
};
