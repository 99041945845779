import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import PropertyRow from './property-row';
import { reqGetListProperty, reqSetEditableProperty } from '../../reduxs/property/action';
import { toast } from 'react-toastify';
import unitApi from '../../apis/api/unit';
import * as yup from 'yup';

const BackendCMSProperties = () => {
  const dispatch = useDispatch();
  const [isSortAsc, toggleSortAsc] = useState(true);
  const [search, setSearch] = useState('');
  const properties = useSelector((state) => state.property.properties);
  const editableProperty = useSelector((state) => state.property.editableProperty);
  const [price, setPrice] = useState();
  const [availability, setAvailability] = useState();
  const [propertyId, setPropertyId] = useState();

  useEffect(() => {
    getListProperties();
  }, []);

  useEffect(() => {
    getListProperties();
  }, [search, isSortAsc]);

  const getListProperties = async () => {
    dispatch(
      reqGetListProperty({
        search,
        sortBy: JSON.stringify({
          orderNumber: isSortAsc ? 1 : -1,
        }),
      })
    );
  };

  const onCancelEditProperty = () => {
    dispatch(reqSetEditableProperty());
  };

  const validationSchema = yup.object().shape({
    price: yup.string().required(),
    availability: yup.string().notRequired(),
  });

  const handleUpdateProperty = async () => {
    try {
      const data = {
        price: price,
        availabilityStatus: availability,
      };
      await validationSchema.validate(data);
      await unitApi.putUnitUpdate(propertyId, data);
      toast.info('Updated property successfully!');
      dispatch(reqSetEditableProperty());
      dispatch(reqGetListProperty());
    } catch (err) {}
  };

  return (
    <div className="container">
      <div className="page-header d-flex align-items-center">
        <h1 className="f-title mb-0">Properties CMS</h1>
        <div className="input-group ms-auto page-header__input-search w-50">
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="form-control"
            type="text"
            placeholder="Search"
          />
          <span className="input-group-text">
            <svg
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.7204 9.47367H10.0429L9.80274 9.24113C10.6432 8.25932 11.1492 6.98468 11.1492 5.59808C11.1492 2.50622 8.65352 0 5.57461 0C2.49571 0 0 2.50622 0 5.59808C0 8.68994 2.49571 11.1962 5.57461 11.1962C6.9554 11.1962 8.2247 10.688 9.2024 9.844L9.43396 10.0852V10.7655L13.7221 15.0631L15 13.7799L10.7204 9.47367ZM5.57461 9.47367C3.43911 9.47367 1.71527 7.74257 1.71527 5.59808C1.71527 3.45358 3.43911 1.72249 5.57461 1.72249C7.71012 1.72249 9.43396 3.45358 9.43396 5.59808C9.43396 7.74257 7.71012 9.47367 5.57461 9.47367Z"
                fill="#414345"
              />
            </svg>
          </span>
        </div>
        <button
          className={`btn shadow-0 ps-xl-5 pe-0 m-0 ms-xl-5 ${!isSortAsc && 'sort-desc'}`}
          onClick={() => toggleSortAsc(!isSortAsc)}
        >
          Name
          <span className="btn-icon-append ms-3">
            <svg
              width="12"
              height="16"
              viewBox="0 0 12 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.53033 0.46967C6.23744 0.176777 5.76256 0.176777 5.46967 0.46967L0.696699 5.24264C0.403806 5.53553 0.403806 6.01041 0.696699 6.3033C0.989592 6.59619 1.46447 6.59619 1.75736 6.3033L6 2.06066L10.2426 6.3033C10.5355 6.59619 11.0104 6.59619 11.3033 6.3033C11.5962 6.01041 11.5962 5.53553 11.3033 5.24264L6.53033 0.46967ZM6.75 16L6.75 1H5.25L5.25 16H6.75Z"
                fill="#414345"
              />
            </svg>
          </span>
        </button>
      </div>

      <div className="page-body">
        <div className="table-responsive table-row-collapse wrapper-scroll-y">
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="border-0">&nbsp;</th>
                <th>Name</th>
                <th>Address</th>
                <th>Property Type</th>
                <th>Features</th>
                <th>FLOORPLAN</th>
                <th>Lot sq.ft.</th>
                <th>Home sq.ft.</th>
                <th className="editable">Price</th>
                <th className="editable">Availability</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody className="border-0">
              {properties.map((property, index) => (
                <PropertyRow
                  price={price}
                  setPrice={setPrice}
                  setAvailability={setAvailability}
                  setPropertyId={setPropertyId}
                  key={index}
                  property={property}
                  isEditable={editableProperty?.id === property?.id}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {editableProperty && (
        <div className="page-footer d-flex justify-content-end">
          <button onClick={() => onCancelEditProperty()} className="btn shadow-0 btn-cancel">
            CANCEL
          </button>
          <button disabled={price === ""} className="btn shadow-0 pe-0" onClick={handleUpdateProperty}>
            SAVE
          </button>
        </div>
      )}
    </div>
  );
};

export default BackendCMSProperties;
