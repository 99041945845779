import ApiClient from '../apiClient';
import { authFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(authFetcher);

const getGalleryDetail = (galleryId, data) =>
  client.get(endPoint.GALLERY_GET_DETAIL.replace(':galleryId', galleryId), data);

export default {
  getGalleryDetail,
};
