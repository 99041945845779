import config from '../config/index';

export const getMediaUrl = (path) => {
  return `${config.SITE_URL}${path}`;
};

export const convertCamelcaseToString = (text) => {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};
