import React, { useEffect, useState } from 'react';
import './index.scss';
import mediaApi from '../../apis/api/media';
import CloseBtn from '../close-btn';
import { PAGES } from '../../constants/options';
import { useDispatch, useSelector } from 'react-redux';
import { reqSetPanoName } from '../../reduxs/home/action';
const PanoViewer = (props) => {
  const { handleClosePanoViewer } = props;
  const [media, setMedia] = useState();
  const page = useSelector((state) => state.home.page);
  const isShowImmerse = useSelector((state) => state.home.isShowImmerse);
  const activePanotourId = useSelector((state) => state.home.activePanotourId);
  const isPresentation = useSelector((state) => state.home.isPresentation);
  const dispatch = useDispatch();

  useEffect(async () => {
    const res = await mediaApi.getMediaDetail(activePanotourId);
    setMedia(res.data);
  }, [activePanotourId]);

  let path;
  if (isPresentation) {
    path = media?.presentation_path ?? media?.path;
  } else {
    path = media?.path;
  }

  if (media != undefined) {
    dispatch(reqSetPanoName(media?.data.name));
  }

  return (
    <>
      <div className={`wrap-pano-viewer`}>
        <iframe width="100%" height="100%" src={path} frameBorder="0" />
      </div>
      {PAGES.IMMERSE_PAGE !== page && isShowImmerse && (
        <CloseBtn handleClick={handleClosePanoViewer} />
      )}
    </>
  );
};

export default PanoViewer;
