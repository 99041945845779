import React, { useEffect, useState } from 'react';
import CustomerRow from './customer-row';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { reqGetListCutomer, reqSetEditableCustomer, reqSetIsVisibleCustomerCreate } from '../../reduxs/customer/action';
import CustomerFavorites from './customer-favarites';
import CustomerCreate from './customer-create';
import customerApi from '../../apis/api/customer';
import CustomerEdit from './customer-edit';
import ConfirmDeleteModal from './confirm-delete-modal';

const BackendCMSCustomers = () => {
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.customer.customers);
  const editableCustomer = useSelector((state) => state.customer.editableCustomer);
  const deleableCustomer = useSelector((state) => state.customer.deleableCustomer);
  const isVisibleCustomerCreate = useSelector((state) => state.customer.isVisibleCustomerCreate);
  const isVisibleCustomerFavorite = useSelector((state) => state.customer.isVisibleCustomerFavorite);
  const [search, setSearch] = useState('');

  useEffect(() => {
    getListCustomers();
  }, []);

  useEffect(() => {
    getListCustomers();
  }, [search])

  const getListCustomers = async () => {
    dispatch(reqGetListCutomer({
      search,
    }));
  };

  const validattionSchema = yup.object().shape({
    firstname: yup.string().required(),
    surname: yup.string().required(),
    mobile: yup.string().notRequired(),
    note: yup.string().notRequired(),
  });

  const onUpdateCustomer = async (customer, data) => {
    try {
      await validattionSchema.validate(data);
      await customerApi.updateCustomer(customer.id, data);
      toast.info('Update customer successfully!');
      dispatch(reqSetEditableCustomer());
      dispatch(reqGetListCutomer());
    } catch (err) {
      toast.error(error.message);
    }
  }

  return (
    <>
      <div className="container">
        <div className="page-header d-flex align-items-center">
          <h1 className="f-title mb-0">Customers CMS</h1>
          <div className="input-group ms-auto page-header__input-search w-50">
            <input value={search} onChange={(e) => setSearch(e.target.value)} className="form-control" type="text" placeholder="Search" />
            <span className="input-group-text">
              <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.7204 9.47367H10.0429L9.80274 9.24113C10.6432 8.25932 11.1492 6.98468 11.1492 5.59808C11.1492 2.50622 8.65352 0 5.57461 0C2.49571 0 0 2.50622 0 5.59808C0 8.68994 2.49571 11.1962 5.57461 11.1962C6.9554 11.1962 8.2247 10.688 9.2024 9.844L9.43396 10.0852V10.7655L13.7221 15.0631L15 13.7799L10.7204 9.47367ZM5.57461 9.47367C3.43911 9.47367 1.71527 7.74257 1.71527 5.59808C1.71527 3.45358 3.43911 1.72249 5.57461 1.72249C7.71012 1.72249 9.43396 3.45358 9.43396 5.59808C9.43396 7.74257 7.71012 9.47367 5.57461 9.47367Z" fill="#414345"/>
              </svg>
            </span>
          </div>
        </div>
        <div className="page-body">
          <div className="table-responsive table-customer-cms wrapper-scroll-y">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Favorites</th>
                  <th>Start Session</th>
                  <th>Post Session URL</th>
                  <th>Active</th>
                  <th>IM Email</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody className="border-0">
                {(customers || []).map((customer, index) => (<CustomerRow key={index} customer={customer} isEditable={editableCustomer?.id === customer?.id} onUpdateCustomer={onUpdateCustomer} />))}
              </tbody>
            </table>

          </div>
        </div>
        <div className="page-footer d-flex align-items-center">
          <a className="text-decoration-none" onClick={() => dispatch(reqSetIsVisibleCustomerCreate(true))}>
            <span className="svg-icon me-2">
              <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.5 25.3125C10.3671 25.3125 7.36257 24.068 5.1473 21.8527C2.93203 19.6374 1.6875 16.6329 1.6875 13.5C1.6875 10.3671 2.93203 7.36257 5.1473 5.1473C7.36257 2.93203 10.3671 1.6875 13.5 1.6875C16.6329 1.6875 19.6374 2.93203 21.8527 5.1473C24.068 7.36257 25.3125 10.3671 25.3125 13.5C25.3125 16.6329 24.068 19.6374 21.8527 21.8527C19.6374 24.068 16.6329 25.3125 13.5 25.3125ZM13.5 27C17.0804 27 20.5142 25.5777 23.0459 23.0459C25.5777 20.5142 27 17.0804 27 13.5C27 9.91958 25.5777 6.4858 23.0459 3.95406C20.5142 1.42232 17.0804 0 13.5 0C9.91958 0 6.4858 1.42232 3.95406 3.95406C1.42232 6.4858 0 9.91958 0 13.5C0 17.0804 1.42232 20.5142 3.95406 23.0459C6.4858 25.5777 9.91958 27 13.5 27Z" fill="#414345"/>
                <path d="M13.5 6.75C13.7238 6.75 13.9384 6.8389 14.0966 6.99713C14.2549 7.15536 14.3438 7.36997 14.3438 7.59375V12.6562H19.4062C19.63 12.6562 19.8446 12.7451 20.0029 12.9034C20.1611 13.0616 20.25 13.2762 20.25 13.5C20.25 13.7238 20.1611 13.9384 20.0029 14.0966C19.8446 14.2549 19.63 14.3438 19.4062 14.3438H14.3438V19.4062C14.3438 19.63 14.2549 19.8446 14.0966 20.0029C13.9384 20.1611 13.7238 20.25 13.5 20.25C13.2762 20.25 13.0616 20.1611 12.9034 20.0029C12.7451 19.8446 12.6562 19.63 12.6562 19.4062V14.3438H7.59375C7.36997 14.3438 7.15536 14.2549 6.99713 14.0966C6.8389 13.9384 6.75 13.7238 6.75 13.5C6.75 13.2762 6.8389 13.0616 6.99713 12.9034C7.15536 12.7451 7.36997 12.6562 7.59375 12.6562H12.6562V7.59375C12.6562 7.36997 12.7451 7.15536 12.9034 6.99713C13.0616 6.8389 13.2762 6.75 13.5 6.75Z" fill="#414345"/>
              </svg>
            </span>
            ADD NEW CUSTOMER
          </a>
        </div>
      </div>

      { isVisibleCustomerFavorite && <CustomerFavorites /> }
      { isVisibleCustomerCreate && <CustomerCreate />}
      { editableCustomer && <CustomerEdit /> }
      { deleableCustomer && <ConfirmDeleteModal/> }
    </>
  );
};

export default BackendCMSCustomers;
