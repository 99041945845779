import ApiClient from '../apiClient';
import { authFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(authFetcher);

const getMediaList = (data) => client.get(endPoint.MEDIA_GET_LIST, data);
const getMediaDetail = (mediaId, data) =>
  client.get(endPoint.MEDIA_GET_DETAIL.replace(':mediaId', mediaId), data);

export default {
  getMediaList,
  getMediaDetail,
};
