import { AVAILABILITY_STATUS_ENUM } from '../constants/master-data';

export const groupBy = (array, key, condition = () => true) => {
  const result = [];
  (array || []).map((item) => {
    if (!result.includes(item[key]) && condition(item)) {
      result.push(item[key]);
    }
  });
  return result;
};

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export const formatMoney = (amount) => {
  if (!amount) return;

  return new Intl.NumberFormat('en-US').format(amount);
};

export const getStatus = (property) => {
  switch (property.availabilityStatus) {
    case AVAILABILITY_STATUS_ENUM.AVAILABLE_NOW:
      return 'Available';
    case AVAILABILITY_STATUS_ENUM.RELEASING_SOON:
      return 'Releasing soon';
    case AVAILABILITY_STATUS_ENUM.SOLD:
      return 'Sold';
    case AVAILABILITY_STATUS_ENUM.IN_CONTRACT:
      return 'In Contract';
  }
};
