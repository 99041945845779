import * as type from './type';

const initialState = {
  data: [],
  activeAmenity: '',
  activeAmenityArea: '',
  activeAmenityId: '',
  latestTime: 0
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case type.GET_AMENITIES_LIST:
      return {
        ...state,
        data: action.data.data,
      };
    case type.SET_ACTIVE_AMENITY_AREA:
      return {
        ...state,
        activeAmenityArea: action?.data?.data,
      };
    case type.SET_ACTIVE_AMENITY:
      return {
        ...state,
        activeAmenity: action?.data?.data,
      };
    case type.SET_ACTIVE_AMENITY_ID:
      return {
        ...state,
        activeAmenityId: action.data
      };
    case type.SET_AMENITY_ITEM_LATEST_TIME:
      return {
        ...state,
        latestTime: action.data
      }
    default:
      return state;
  }
};
