import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import './index.scss';
import modalApi from '../../apis/api/modal';
import { HOTSPOT_TYPES, PAGES } from '../../constants/options';
import { useDispatch, useSelector } from 'react-redux';
import { reqSetExploreModal, reqSetIsShowExploreModal } from '../../reduxs/explore-modal/action';
import { reqSetActiveAmenity } from '../../reduxs/amenities/action';
import { reqSetActiveGalleryId, reqSetActivePatourId, reqSetIsShowImmerse, reqSetPage } from '../../reduxs/home/action';
import { reqFilterUnitArea } from '../../reduxs/unit-explore/action';
import { ACTION_NAME, WEBSOCKET_CHANNEL } from '../../constants/options';
import socket, { emitUIActionEvent } from '../../helper/socket';
import { reqSetIsShowFilms } from '../../reduxs/media/action';

const Modal = (props) => {
  const {handleCloseModal} = props;
  const dispatch = useDispatch();
  const [nextId, setNext] = useState();
  const [prevId, setPrev] = useState();
  const modal = useSelector((state) => state.exploreModal.modal);
  const activeAmenityArea = useSelector((state) => state.amenities.activeAmenityArea);
  const activeAmenity = useSelector((state) => state.amenities.activeAmenity);
  const isShowExploreModal = useSelector((state) => state.exploreModal.isShowExploreModal);
  const authUser = useSelector((state) => state.user.data);
  const isPresentation = useSelector((state) => state.home.isPresentation);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({content}) => {
        if (content.action === ACTION_NAME.CLICK_CLOSE_MODAL) {
          onCloseModal();
        }
        if (content.action === ACTION_NAME.CLICK_MODAL_BUTTON) {
          onClickModalButton(content.data);
        }
        if (content.action === ACTION_NAME.CHANGE_MODAL_CONTENT) {
          if (modal?.id !== content.data.modalId) {
            dispatch(reqSetExploreModal(content.data.modalId));
          }
        }
      });
    }
  }, [ isPresentation ]);

  useEffect(() => {
    if (modal) {
      if (!isPresentation) {
        emitUIActionEvent(authUser, ACTION_NAME.CHANGE_MODAL_CONTENT, {
          modalId: modal.id,
        });
      }
      updateNextPrevItem();
    }
  }, [modal]);

  const updateNextPrevItem = async () => {
    if (!activeAmenityArea && modal?.type) {
      const result = await modalApi.getListModal({ 'type[equal]': modal.type });
      const ids = result.data.map((item) => item.id);
      const currentIndex = ids.findIndex((item) => item === modal.id);
      const next = currentIndex < ids.length - 1 ? ids[currentIndex + 1] : ids[0];
      const prev = currentIndex > 0 ? ids[currentIndex - 1] : ids[ids.length - 1];
      setNext(next);
      setPrev(prev);
    }
  };

  const goToPanoTour = (id) => {
    dispatch(reqSetActivePatourId(id));
    dispatch(reqSetIsShowImmerse(true));
    dispatch(reqSetIsShowExploreModal(false));
  };

  const onClickModalButton = (clickedEl) => {
    if (clickedEl.id == 'btn_pano_tour' || clickedEl.id == 'btn_pano_tour_2') {
      goToPanoTour(clickedEl.dataset.pano_tour_id);
      return;
    }

    if (clickedEl.id == 'btn_film_1' || clickedEl.id == 'btn_film_2') {
      dispatch(reqSetIsShowExploreModal(false));
      dispatch(reqSetActiveGalleryId('60cb329ee76f1f0016defcff'));
      dispatch(reqSetIsShowFilms(true));
      dispatch(reqSetPage(PAGES.GALLERY_PAGE));
      return;
    }

    if (clickedEl.id == 'btn_gallery_1' || clickedEl.id == 'btn_gallery_2') {
      dispatch(reqSetIsShowExploreModal(false));
      dispatch(reqSetActiveGalleryId('60cb329ee76f1f0016defcff'));
      dispatch(reqSetIsShowFilms(false));
      dispatch(reqSetPage(PAGES.GALLERY_PAGE));
      return;
    }

    if (clickedEl.id == 'btn_unit_explore' || clickedEl.id == 'btn_unit_explore_2') {
      dispatch(reqSetIsShowExploreModal(false));
      dispatch(reqSetPage(PAGES.UNIT_EXPLORER_PAGE));
      dispatch(reqFilterUnitArea(clickedEl.dataset.area_id));
      return;
    }

    if (clickedEl.id == 'btn_next_modal') {

      if (activeAmenity != undefined) {
        const amenities = activeAmenityArea.amenities;
        const currentIndex = amenities.findIndex((item) => item.id == activeAmenity.id);
        const next = currentIndex < amenities.length - 1 ? amenities[currentIndex + 1] : amenities[0];

        dispatch(reqSetExploreModal(next.modal));
        dispatch(reqSetActiveAmenity(next.id));
      } else {
        dispatch(reqSetExploreModal(nextId));
      }

      return;
    }

    if (clickedEl.id == 'btn_prev_modal') {
      if (activeAmenity != undefined) {
        const amenities = activeAmenityArea.amenities;
        const currentIndex = amenities.findIndex((item) => item.id == activeAmenity.id);
        const prev = currentIndex > 0 ? amenities[currentIndex - 1] : amenities[amenities.length - 1];

        dispatch(reqSetExploreModal(prev.modal));
        dispatch(reqSetActiveAmenity(prev.id));
      } else {
        dispatch(reqSetExploreModal(prevId));
      }
    }
  }

  const handleButtonInContent = (event) => {
    const clickedEl = {
      id: event.target.id,
      dataset: {...event.target.dataset},
    };

    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.CLICK_MODAL_BUTTON, clickedEl);
    }

    onClickModalButton(clickedEl);
  };

  const onCloseModal = () => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.CLICK_CLOSE_MODAL, {});
    }
    
    if (modal?.type == HOTSPOT_TYPES.MODAL) {
      handleCloseModal();
    }
    dispatch(reqSetIsShowExploreModal(false));
    dispatch(reqSetActiveAmenity());
  }

  /*
  const contentTest = `
  <div style="background-color: #414345; color: #ffffff; padding: 30px">
     <div class="px-3 py-4 d-flex align-item-center text-center">
        <div class="d-flex flex-column justify-content-center" style="width: 644px; height: 620px;">
          <h2 class=\"f-title my-3 h1\">
            VINEYARD HOMES
          </h2>
          <p class=\"w-75 m-auto\" style=\"font-size: 16px; font-weight: 300;\">
            A collection redefining modern ranch appeal with three to six<br>bedroom homes embracing a fluid indoor-outdoor experience.
          </p>
          <p class=\"mb-0\">&nbsp;</p>
          <p><strong>~5.900 to ~6.600 TOTAL SQ FT</strong></p>
          <p class=\"mb-0\">&nbsp;</p>
           <div class="d-flex flex-row justify-content-center" style="width: 644px; height: 80px; gap: 20px; margin-bottom: 100px;">
            <div class="d-flex flex-column justify-content-center" style="width: 200px; height: 80px;">
              <a class="w-100 d-block" href="#">
                <img id="btn_unit_explore_2" data-area_id=\"609c81d7c7ede9001635e0f0\" src="./uploads/images/icon-3dvista1.png" alt="icon" style="width: 88px;">
              </a>
              <a id="btn_unit_explore" data-area_id="609c81d7c7ede9001635e0f0" href="#" class="f-title h4 mt-5 text-white d-block" style="text-decoration: underline;">
                Availability
              </a>
            </div>
            <div class="d-flex flex-column justify-content-center" style="width: 200px; height: 80px;">
              <a class="w-100 d-block" href="#">
                <img id="btn_film_1" data-pano_tour_id="624ce55385db36b01ffa3960" src="./uploads/images/icon-3dvista2.png" alt="icon" style="width: 88px;">
              </a>
              <a id="btn_film_2" data-area_id="60b852fee3ac4500152c9a64" href="#" class="f-title h4 mt-5 text-white d-block" style="text-decoration: underline;">
                The Films
              </a>
            </div>
            <div class="d-flex flex-column justify-content-center" style="width: 200px; height: 80px;">
              <a class="w-100 d-block" href="#">
                <img id="btn_gallery_1" data-pano_tour_id="624ce55385db36b01ffa3960" src="./uploads/images/icon-3dvista3.png" alt="icon" style="width: 88px;">
              </a>
              <a id="btn_gallery_2" data-area_id="60b852fee3ac4500152c9a64" href="#" class="f-title h4 mt-5 text-white d-block" style="text-decoration: underline;">
                Gallery
              </a>
            </div>
           </div>
           <div class="d-flex flex-row justify-content-center" style="width: 644px; height: 80px; gap: 20px; margin-bottom: 205px;">
            <div class="d-flex flex-column justify-content-center" style="width: 200px; height: 80px;">
              <a class="w-100 d-block" href="#">
                <img id="btn_pano_tour" data-pano_tour_id="61275df03286f562126a9702" src="./uploads/images/icon-3dvista4.png" alt="icon" style="width: 70px;">
              </a>
              <a id="btn_pano_tour_2" data-pano_tour_id="61275df03286f562126a9702" href="#" class="f-title h4 mt-5 text-white d-block" style="width: 180px; text-decoration: underline;margin-bottom: 25px;">
                Sycamore Tour
              </a>
            </div>
            <div class="d-flex flex-column justify-content-center" style="width: 200px; height: 80px;">
              <a class="w-100 d-block" href="#">
                <img id="btn_pano_tour_2" data-pano_tour_id="61275df03286f562126a9702" src="./uploads/images/icon-3dvista4.png" alt="icon" style="width: 70px;">
              </a>
              <a id="btn_pano_tour" data-pano_tour_id="61275df03286f562126a9702" href="#" class="f-title h4 mt-5 text-white d-block" style="text-decoration: underline;margin-bottom: 25px;">
                Madrone Tour
              </a>
            </div>
            <div class="d-flex flex-column justify-content-center" style="width: 200px; height: 80px;">
              <a class="w-100 d-block" href="#">
                <img id="btn_pano_tour" data-pano_tour_id="61275df03286f562126a9702" src="./uploads/images/icon-3dvista4.png" alt="icon" style="width: 70px;">
              </a>
              <a id="btn_pano_tour_2" data-pano_tour_id="61275df03286f562126a9702" href="#" class="f-title h4 mt-5 text-white d-block" style="text-decoration: underline; margin-bottom: 25px;">
                Virtual Tour
              </a>
            </div>
           </div>
    </div>
  </div>
</div>`;*/
console.log("Version: 1.2.3");

  return (
    <div className={`wrap-modal ${isShowExploreModal ? 'show' : 'hide'} modal-${modal?.type}`}>
      <div onClick={(e) => handleButtonInContent(e)}>{parse(modal?.content || ' ')}</div>
      <div className="close-btn p-1" onClick={() => onCloseModal()}>
        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="8.57918" y1="8.73918" x2="25.8968" y2="26.0568" stroke="#282A2D" strokeWidth="2"/>
          <line x1="25.897" y1="8.5782" x2="8.57943" y2="25.8958" stroke="#282A2D" strokeWidth="2"/>
        </svg>
      </div>
    </div>
  );
};

export default Modal;
