import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { reqGetListCutomer, reqSetDeleableCustomer, reqSetEditableCustomer } from '../../reduxs/customer/action';
import customerApi from '../../apis/api/customer';
import { toast } from 'react-toastify';

const ConfirmDeleteModal = () => {
  const dispatch = useDispatch();
  const deleableCustomer = useSelector((state) => state.customer.deleableCustomer);

  const handleDeleteCustomer = async () => {
    try {
      await customerApi.deleteCustomer(deleableCustomer.id);
      toast.info('Delete customer successfully');
      dispatch(reqSetDeleableCustomer());
      dispatch(reqGetListCutomer());
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <>
      <div className={`wrap-modal wrap-modal-cms fade-item-enter-done ${deleableCustomer ? '' : 'hidden'}`}>
        <div>
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="f-title">Delete customer</h2>
            </div>
            <div className="modal-body">
              <h6>Are you want to delete this customer!</h6>
            </div>
            <div className="modal-footer d-flex">
              <button
                onClick={() => dispatch(reqSetDeleableCustomer())}
                className="btn shadow-0 bg-white btn-cancel ms-auto"
              >CANCEL</button>
              <button onClick={() => handleDeleteCustomer()} className="btn shadow-0 bg-white pe-0">DELETE</button>
            </div>
          </div>
        </div>
        <div className="close-btn p-5" onClick={() => dispatch(reqSetDeleableCustomer())}>
          <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="8.57918" y1="8.73918" x2="25.8968" y2="26.0568" stroke="#282A2D" strokeWidth="2" />
            <line x1="25.897" y1="8.5782" x2="8.57943" y2="25.8958" stroke="#282A2D" strokeWidth="2" />
          </svg>
        </div>
      </div>
      <div className={`wrap-modal-backdrop ${deleableCustomer ? '' : 'hidden'}`} />
    </>
  )
}

export default ConfirmDeleteModal;
