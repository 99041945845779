import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getMediaUrl } from '../../helper/media';
import { ACTION_NAME, PAGES, WEBSOCKET_CHANNEL } from '../../constants/options';
import galleryApi from '../../apis/api/gallery';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
library.add(faChevronLeft, faChevronRight);
import socket, { emitUIActionEvent } from '../../helper/socket';

const Gallery = () => {
  const [gallery, setGallery] = useState([]);
  const [media, setMedia] = useState([]);
  const galleryRef = useRef();
  const activeGalleryId = useSelector((state) => state.home.activeGalleryId);
  const page = useSelector((state) => state.home.page);
  const isPresentation = useSelector((state) => state.home.isPresentation);
  const authUser = useSelector((state) => state.user.data);
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    if (isPresentation && galleryRef) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CHANGE_SLIDE_GALLERY) {
          onGoToSlideIndex(content.data.index);
        }
      });
    }
  }, [ galleryRef ])

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    className: 'slides',
    arrows: false,
    fade: true,
    lazyLoad: false,
    afterChange: (index) => {
      if (!isPresentation) {
        emitUIActionEvent(authUser, ACTION_NAME.CHANGE_SLIDE_GALLERY, {
          index,
        });
      }
    }
  };

  useEffect(async () => {
    if (activeGalleryId) {
      const res = await galleryApi.getGalleryDetail(activeGalleryId);
      setGallery(res.data);
    }
  }, [activeGalleryId]);

  useEffect(async () => {
    setMedia(gallery?.media ? gallery.media : []);
  }, [gallery]);

  const onGoToSlideIndex = (index) => {
    galleryRef?.current?.slickGoTo(index);
  }

  return (
    <>
      <div className="wrap-gallery-image">
        <Slider ref={galleryRef} {...settings}>
          {media.map((item, key) => {
            return (
              <div key={key}>
                <div
                  className="gallery-item"
                  style={{ backgroundImage: `url(${getMediaUrl(item.path)})` }}
                />
              </div>
            );
          })}
        </Slider>
        {
          PAGES.GALLERY_PAGE === page &&
          <span className="btn-gallery prev-slide">
            <FontAwesomeIcon
              onClick={() => galleryRef.current?.slickPrev()}
              icon={["fas", "chevron-left"]}
              size="3x"
            />
          </span>
        }
        {
          PAGES.GALLERY_PAGE === page &&
          <span className="btn-gallery next-slide">
          <FontAwesomeIcon
            onClick={() => galleryRef.current?.slickNext()}
            icon={["fas", "chevron-right"]}
            size="3x"
          />
        </span>
        }
      </div>
    </>
  );
};

export default Gallery;
