import React, { useState } from 'react';
import './login.scss';
import TopNav from '../../components/top-nav';
import authApi from '../../apis/api/auth';
import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import jwtAuth from '../../apis/utils/jwtAuth';
import { validateEmail, validatePassword } from '../../helper/validation';
import { useSelector } from 'react-redux';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  const isPresentation = useSelector((state) => state.home.isPresentation);

  useEffect(() => {
    redirectIfAuthenticated();
  }, []);

  const handleLogin = async () => {
    const data = {
      email: email,
      password: password,
    };

    if (validateEmail(email)) {
      setErrorMessage('');
    } else {
      return setErrorMessage('The email must be a valid email address.');
    }

    if (validatePassword(password)) {
      setErrorMessage('');
    } else {
      return setErrorMessage(
        'The password must be minimum eight characters, at least one letter and one number.'
      );
    }

    const res = await authApi.signIn(data);

    if (res?.data) {
      jwtAuth.setAccessToken(res.data.token);
      await getUserProfile();
    } else {
      setErrorMessage('Email not found or password does not match');
    }
  };

  const redirectIfAuthenticated = () => {
    if (jwtAuth.checkToken()) {
      getUserProfile();
    }
  };

  const getUserProfile = async () => {
    try {
      const res = await authApi.getMe();

      if (res?.data) {
        if (isPresentation) {
          history.push('/presentation');
        } else {
          history.push('/holding');
        }
      }
    } catch (error) {
      return;
    }
  };
  
  return (
    <div className="wrap-login-page">
      <div className={`wrap-intro show`}>
        <div className="position-absolute top-50 start-50 translate-middle">
          <div className={`modal-form`}>
            <h3 className="modal-form__title mb-0">Welcome to the Stanly Ranch experience app</h3>
            <p className="modal-form__subtitle">Login with your App Administrator Account</p>
            <div className="modal-form__body">
              <div className="form-group">
                <input
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                  id="email"
                  placeholder="Email"
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-control"
                  id="password"
                  aria-describedby="passwordHelp"
                  placeholder="Password"
                />
                <p id="passwordHelp" className="text-danger mt-2 mb-0">
                  {errorMessage}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                {/* <div className="forgot-password">
                  <Link to="/forgot-password" className="btn-text">
                    Forgot password?
                  </Link>
                </div> */}
                <button
                  type="submit"
                  onClick={() => handleLogin()}
                  className="btn btn-link ms-auto pe-0 me-0 pb-0 mb-0"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
