export const SET_PAGE = 'SET_PAGE';
export const SET_IS_SHOW_BOTTOM_NAV = 'SET_IS_SHOW_BOTTOM_NAV';
export const SET_IS_SHOW_IMMERSE = 'SET_IS_SHOW_IMMERSE';
export const SET_IS_EXPAND_NAV = 'SET_IS_EXPAND_NAV';
export const SET_ACTIVE_PANTOUR_ID = 'SET_ACTIVE_PANTOUR_ID';
export const SET_IS_SHOW_EXPLORE_MODAL = 'SET_IS_SHOW_EXPLORE_MODAL';
export const SET_IS_TRANSPARENT = 'SET_IS_TRANSPARENT';
export const SET_PANO_NAME = 'SET_PANO_NAME';
export const SET_ACTIVE_GALLERY_ID = 'SET_ACTIVE_GALLERY_ID';
export const SET_IS_SHOW_START_SESSION_MODAL = 'SET_IS_SHOW_START_SESSION_MODAL';
export const SET_IS_SHOW_CUSTOMER_DETAIL_MODAL = 'SET_IS_SHOW_CUSTOMER_DETAIL_MODAL';
export const SET_IS_SHOW_CREATE_CUSTOMER_MODAL = 'SET_IS_SHOW_CREATE_CUSTOMER_MODAL';
export const SET_IS_PRESENTATION = 'SET_IS_PRESENTATION';
