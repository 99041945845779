import { io } from 'socket.io-client';
import config from '../config/index';
import { WEBSOCKET_CHANNEL } from '../constants/options';

const socket = io(config.SOCKET_URL, {
  autoConnect: false,
  rejectUnauthorized: false,
});

export const emitUIActionEvent = (authUser, actionName, data = {}) => {
  socket.emit(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, {
    content: {
      action: actionName,
      data: data,
    },
    to: authUser?.id || 'cp87pvd1ec',
    from: authUser?.id || 'cp87pvd1ec',
  })
}

export default socket;
