import React, { useRef } from 'react';
import './index.scss';
import Fancybox from './fancy-box';
import galleryApi from '../../apis/api/gallery';
import { useState } from 'react';
import { useEffect } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../config';
import { reqSetIsShowFilms } from '../../reduxs/media/action';
import { ACTION_NAME, WEBSOCKET_CHANNEL } from '../../constants/options';
import socket, { emitUIActionEvent } from '../../helper/socket';

const styles = [
  {
    cardWides: [],
    cardTalls: [],
    style1: [],
    style2: [1, 2],
    style3: [],
    style4: [],
    style5: [],
    style6: [0, 3],
    style7: [],
    style8: [],
  },
  {
    cardWides: [],
    cardTalls: [],
    style1: [],
    style2: [],
    style3: [1, 2, 3],
    style4: [],
    style5: [],
    style6: [],
    style7: [0],
    style8: [],
  },
  {
    cardWides: [],
    cardTalls: [],
    style1: [],
    style2: [],
    style3: [1, 3],
    style4: [],
    style5: [0, 2],
    style6: [],
    style7: [],
    style8: [],
  },
  {
    cardWides: [],
    cardTalls: [],
    style1: [],
    style2: [1],
    style3: [3],
    style4: [],
    style5: [0],
    style6: [2],
    style7: [],
    style8: [],
  },
  {
    cardWides: [],
    cardTalls: [],
    style1: [],
    style2: [0, 3],
    style3: [],
    style4: [],
    style5: [],
    style6: [1, 2],
    style7: [],
    style8: [],
  },
  {
    cardWides: [],
    cardTalls: [],
    style1: [],
    style2: [],
    style3: [],
    style4: [0, 1, 2, 3],
    style5: [],
    style6: [],
    style7: [],
    style8: [],
  },
];

const HomeGallery = () => {
  const [gallery, setGallery] = useState();
  const [media, setMedia] = useState([]);
  const activeGalleryId = useSelector((state) => state.home.activeGalleryId);
  const [isShowFancyBox, setIsShowFancyBox] = useState();
  const isShowFilms = useSelector((state) => state.media.isShowFilms);
  const isPresentation = useSelector((state) => state.home.isPresentation);
  const refImgs = React.useRef([]);
  const authUser = useSelector((state) => state.user.data);
  const refOpenFancybox = useRef(false);
  const pageRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CLICK_IMAGE_MEDIA) {
          onShowFancyBox(content.data.item);
        }
        if (content.action === ACTION_NAME.CLOSE_FANCYBOX) {
          handleCloseFancyBox();
        }
        if (content.action === ACTION_NAME.JUMP_IMAGE) {
          handleJumpTo(content.data.index);
        }
        if (content.action === ACTION_NAME.GALLERY_SCROLL) {
          if (pageRef.current && content.data) {
            pageRef.current.scrollTop = content.data.scrollTop * pageRef.current.scrollHeight;
          }
        }
      });
    }
  }, [isPresentation]);

  const handleCloseFancyBox = () => {
    const fancyBoxRef = document.getElementsByClassName('fancybox__container')[0]?.Fancybox;
    fancyBoxRef?.close();
    setIsShowFancyBox(false);
  };

  const handleJumpTo = (idx) => {
    const fancyBoxRef = document.getElementsByClassName('fancybox__container')[0]?.Fancybox;
    fancyBoxRef?.jumpTo(idx);
  };

  useEffect(() => {
    if (isShowFancyBox) {
      const fancyBoxRef = document.getElementsByClassName('fancybox__container')[0]?.Fancybox;
      fancyBoxRef.on('destroy', (e) => {
        if (!isPresentation) {
          refOpenFancybox.current = false;
          emitUIActionEvent(authUser, ACTION_NAME.CLOSE_FANCYBOX);
        }
        let element = document.getElementsByClassName('is-selected')[0];
        element?.classList.remove('fancybox-wide-img');
        element?.classList.remove('fancybox-tall-img');
        setIsShowFancyBox(false);
      });
    }
  }, [isShowFancyBox]);

  useEffect(async () => {
    if (activeGalleryId) {
      const res = await galleryApi.getGalleryDetail(activeGalleryId);
      setGallery(res.data);
    }
  }, [activeGalleryId]);

  useEffect(async () => {
    setMedia(gallery?.media ? gallery.media : []);
  }, [gallery]);

  const onNextImage = () => {
    const fancyBoxRef = document.getElementsByClassName('fancybox__container')[0]?.Fancybox;
    fancyBoxRef?.next();
  };

  const onPrevImage = () => {
    const fancyBoxRef = document.getElementsByClassName('fancybox__container')[0]?.Fancybox;
    fancyBoxRef?.prev();
  };

  const onCloseFancyBox = () => {
    const fancyBoxRef = document.getElementsByClassName('fancybox__container')[0]?.Fancybox;
    setIsShowFancyBox(false);
    fancyBoxRef?.close();
  };

  const onSelectGallery = () => {
    dispatch(reqSetIsShowFilms(false));
  };

  const getCardClass = (index, style) => {
    if (style.cardTalls.includes(index)) return 'card-tall';

    if (style.cardWides.includes(index)) return 'card-wide';

    if (style.style1.includes(index)) return 'card-style1';
    if (style.style2.includes(index)) return 'card-style2';
    if (style.style3.includes(index)) return 'card-style3';
    if (style.style4.includes(index)) return 'card-style4';
    if (style.style5.includes(index)) return 'card-style5';
    if (style.style6.includes(index)) return 'card-style6';
    if (style.style7.includes(index)) return 'card-style7';
    if (style.style8.includes(index)) return 'card-style8';

    return;
  };

  const renderStyleOne = (items, index) => {
    const styleIndex = index % 6;
    const style = styles[styleIndex];

    return (items || []).map((item, index) => {
      return (
        <img
          id={`im-${item?.id}`}
          ref={(ref) => !refImgs.current.includes(ref) && refImgs.current.push(ref)}
          key={index}
          onClick={() => onShowFancyBox(item)}
          className={`card border-0 ${getCardClass(index, style)}`}
          src={`${config.SITE_URL}${item.path}`}
          data-fancybox="gallery"
          data-src={`${config.SITE_URL}${item.path}`}
        />
      );
    });
  };

  const onScroll = (event) => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.GALLERY_SCROLL, {
        scrollTop: event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  };

  const onShowFancyBox = (item) => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.CLICK_IMAGE_MEDIA, {
        item,
      });
    }
    const el = document.getElementById(`im-${item?.id}`);
    if (el) {
      el.click();
      handleAddClass(el, document.getElementsByClassName('is-selected')[0]);

      setIsShowFancyBox(`im-${item?.id}`);
    }
  };

  const handleAddClass = (el, elParent) => {
    const element = elParent;
    if (element === undefined) {
      const timeout = setTimeout(() => {
        handleAddClass(el, document.getElementsByClassName('is-selected')[0]);
        clearTimeout(timeout);
      }, 300);
      return;
    }
    if (el.naturalWidth > el.naturalHeight) {
      element?.classList.remove('fancybox-tall-img');
      element?.classList.add('fancybox-wide-img');
    } else {
      element?.classList.remove('fancybox-wide-img');
      element?.classList.add('fancybox-tall-img');
    }
  };

  return (
    <>
      <div className="wrap-home-gallery" ref={pageRef} onScroll={onScroll}>
        <div className="home-gallery-header container">
          <div className="media-type-header">
            <div className="media-type-buttons">
              <button
                className={`${!isShowFilms ? 'media-type-button-active' : 'media-type-button'}`}
                onClick={onSelectGallery}
              >
                GALLERY
              </button>
              {/* <button className={`${!isShowFilms ? 'media-type-button' : 'media-type-button-active' }`} onClick={onSelectMedia}> */}
              {/*   FILMS */}
              {/* </button> */}
            </div>
          </div>
          {!isShowFilms ? (
            <div className="wrap-image-list">
              <Fancybox
                setIsShowFancyBox={setIsShowFancyBox}
                options={{
                  closeButton: false,
                  infinite: true,
                  mainClass: 'pure-fancy-box',
                  showNavArrows: false,
                  on: {
                    'Carousel.change': (fancybox) => {
                      const el = fancybox.getSlide();
                      if (!isPresentation) {
                        emitUIActionEvent(authUser, ACTION_NAME.JUMP_IMAGE, {
                          index: el.index,
                        });
                      }
                      const timeout = setTimeout(() => {
                        handleAddClass(el['$thumb'], el['$el']);
                        clearTimeout(timeout);
                      }, 50);
                    },
                  },
                }}
              >
                <div className="image-mosaic">
                  {_.chunk(gallery?.media || [], 4).map((items, index) => {
                    return renderStyleOne(items, index);
                  })}
                </div>
              </Fancybox>
            </div>
          ) : (
            <div className="wrap-image-list">
              <Fancybox
                setIsShowFancyBox={setIsShowFancyBox}
                options={{ infinite: true, mainClass: 'wrap-fancy-box', showNavArrows: false }}
              >
                <h2 className="headertitle __title">Vineyard homes Films</h2>
                <div className="image-mosaic">
                  <img
                    key={0}
                    onClick={() => setIsShowFancyBox(true)}
                    className={`card border-0 style4`}
                    src={`${config.SITE_URL}/uploads/images/gallery-villas/gallery-villas-01.jpg`}
                    data-fancybox="gallery"
                    data-src={`${config.SITE_URL}/uploads/images/gallery-villas/gallery-villas-01.jpg`}
                  />
                  <img
                    key={1}
                    onClick={() => setIsShowFancyBox(true)}
                    className={`card border-0 style4`}
                    src={`${config.SITE_URL}/uploads/images/gallery-villas/gallery-villas-02.jpg`}
                    data-fancybox="gallery"
                    data-src={`${config.SITE_URL}/uploads/images/gallery-villas/gallery-villas-02.jpg`}
                  />
                </div>
                <h2 className="headertitle __title">The Villas Films</h2>
                <div className="image-mosaic">
                  <img
                    key={0}
                    onClick={() => setIsShowFancyBox(true)}
                    className={`card border-0 style4`}
                    src={`${config.SITE_URL}/uploads/images/gallery-vinyard-homes/gallery-vinyard-homes-01.jpg`}
                    data-fancybox="gallery"
                    data-src={`${config.SITE_URL}/uploads/images/gallery-vinyard-homes/gallery-vinyard-homes-01.jpg`}
                  />
                  <img
                    key={1}
                    onClick={() => setIsShowFancyBox(true)}
                    className={`card border-0 style4`}
                    src={`${config.SITE_URL}/uploads/images/gallery-vinyard-homes/gallery-vinyard-homes-02.jpg`}
                    data-fancybox="gallery"
                    data-src={`${config.SITE_URL}/uploads/images/gallery-vinyard-homes/gallery-vinyard-homes-02.jpg`}
                  />
                </div>
                <h2 className="headertitle __title">Other Films</h2>
                <div className="image-mosaic">
                  <img
                    key={0}
                    onClick={() => setIsShowFancyBox(true)}
                    className={`card border-0 style4`}
                    src={`${config.SITE_URL}/uploads/images/gallery-general/gallery-general-01.jpg`}
                    data-fancybox="gallery"
                    data-src={`${config.SITE_URL}/uploads/images/gallery-general/gallery-general-01.jpg`}
                  />
                  <img
                    key={1}
                    onClick={() => setIsShowFancyBox(true)}
                    className={`card border-0 style4`}
                    src={`${config.SITE_URL}/uploads/images/gallery-general/gallery-general-02.jpg`}
                    data-fancybox="gallery"
                    data-src={`${config.SITE_URL}/uploads/images/gallery-general/gallery-general-02.jpg`}
                  />
                </div>
              </Fancybox>
            </div>
          )}
        </div>
      </div>
      {!!isShowFancyBox && (
        <>
          <div className="wrap-btn-fancy">
            <div className="btn-prev cursor-pointer" onClick={onPrevImage}>
              <img className="icon-arrow" src="/icons/left-arrow.svg" alt="" />
            </div>
            <div className="btn-next cursor-pointer" onClick={onNextImage}>
              <img className="icon-arrow" src="/icons/right-arrow.svg" alt="" />
            </div>
          </div>
          <div className="btn-close-fancy cursor-pointer" onClick={onCloseFancyBox}>
            <img className="icon-close" src="/icons/close.svg" alt="" />
          </div>
        </>
      )}
    </>
  );
};

export default HomeGallery;
