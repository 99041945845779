import * as Types from './type';

export const actSetIsShowFilms = (data) => ({
  type: Types.IS_SHOW_FILMS,
  data,
});

export const reqSetIsShowFilms = (data) => (dispatch) => {
  return dispatch(actSetIsShowFilms(data));
}
