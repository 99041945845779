import Config from '../../config';

export const apiMethod = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

const getToken = () => localStorage.getItem('access_token');

const callApi = async (endpoint, method = apiMethod.GET, data = {}) => {
  const config = {
    method: method,
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  };

  const fetchResult = await fetch(Config.API_URL + endpoint, config);
  const payload = await fetchResult.json();

  if (fetchResult.status === 200) {
    return payload;
  }
  throw payload;
};

export default {
  callApi,
}
