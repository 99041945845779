/* eslint-disable no-unused-vars */
import React from 'react';
import { useState, useEffect } from 'react';
import TopNav from '../../components/top-nav';
import './forgot-password.scss';
import authApi from '../../apis/api/auth';
import { Link } from 'react-router-dom';
import { validateEmail } from '../../helper/validation';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    validateEmail(email) ? setMessage('') : setMessage('The email must be a valid email address.');
  }, [email]);

  const handleForgotPassword = async () => {
    const res = await authApi.forgotPassword({
      email: email,
    });
    setMessage('We have emailed your password reset link!');
  };

  return (
    <div className="wrap-forgot-password-page">
      <TopNav isShow={false} handleClickCube={() => { }} />
      <div className={`wrap-intro show`}>
        <video
          autoPlay
          muted
          loop
          preload="none"
          id="intro-video"
          style={{ backgroundImage: `url("./uploads/images/poster.jpg")` }}
        >
          <source src="./uploads/images/introduce.mp4" type="video/mp4" />
        </video>
        <div className="position-absolute top-50 start-50 translate-middle">
          <div className={`modal-form`}>
            <h3 className="modal-form__title">Forgot Pasword</h3>
            <div className="modal-form__body">
              <div className="form-group">
                <input
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                  id="email"
                  placeholder="Email"
                />
                <p className="text-danger mt-n2 mb-4">{message}</p>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="forgot-password">
                  <Link to="/sign-in" className="btn-text">
                    Sign In
                  </Link>
                </div>
                <button
                  type="submit"
                  onClick={() => handleForgotPassword()}
                  className="btn-login btn btn-lg m-0"
                  disabled={!!message}
                >
                  Forgot Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
