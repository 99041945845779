import React, { useState } from 'react';
import { selectQuestionTypes } from './dist/config';
import Select from 'react-select';
import supportApi from '../../apis/api/support';
import { toast } from 'react-toastify';

const styles = {
  option: (provided, state) => ({
    ...provided,
    background: '#e5e4e4',
    color: '#414345',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '10px',
    boxShadow: 'none',
    marginTop: 0,
    background: '#e5e4e4',
  }),
};

const SupportTicket = (props) => {
  const { isOpenModal, setIsOpenModal, user, setSuccessModal } = props;
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [questionType, setQuestionType] = useState('');

  const handleSendSupportTicket = async () => {
    const data = {
      request: {
        requester: {
          name: user.name,
        },
        subject: subject,
        comment: {
          body: questionType + description,
        },
      },
    };
    try {
      await supportApi.sendSupportTicket(data);
      toast.info('Created customer successfully!');
      setSuccessModal(true);
      setIsOpenModal(false);
    } catch (error) {
      toast.error(error.error + error.description);
    }
  };

  return (
    <>
      <div
        className={`wrap-modal wrap-modal-support-tickets fade-item-enter-done ${
          isOpenModal ? '' : 'hidden'
        }`}
      >
        <div>
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="f-title">Support Tickets</h2>
              <div onClick={() => setIsOpenModal(false)}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 35 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="8.57918"
                    y1="8.73918"
                    x2="25.8968"
                    y2="26.0568"
                    stroke="#282A2D"
                    strokeWidth="2"
                  />
                  <line
                    x1="25.897"
                    y1="8.5782"
                    x2="8.57943"
                    y2="25.8958"
                    stroke="#282A2D"
                    strokeWidth="2"
                  />
                </svg>
              </div>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col">
                  <span>Question Type</span>
                  <div className="mt-1 mb-4">
                    <Select
                      placeholder="Question Type"
                      className="form-select form-select-sm"
                      onChange={(e) => setQuestionType(e.value)}
                      options={selectQuestionTypes}
                      styles={styles}
                    />
                  </div>
                </div>
                <div className="col">
                  <span>Subject</span>
                  <div className="mt-1 mb-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Subject"
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {/* <span>Priority</span>
                  <div className="mt-1 mb-4">
                    <select className="form-select form-select-sm">
                      <option selected>Priority</option>
                    </select>
                  </div> */}
                </div>
                <div className="col">
                  <span>Description</span>
                  <div className="mt-1 mb-4">
                    <textarea
                      className="form-control"
                      placeholder="Description"
                      rows="5"
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-end">
              <a href="#" className="text-underline" onClick={handleSendSupportTicket}>
                Send
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={`wrap-modal-backdrop ${isOpenModal ? '' : 'hidden'}`} />
    </>
  );
};
export default SupportTicket;
