import ApiClient from '../apiClient';
import { authFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(authFetcher);

const getAreasList = (data) => client.get(endPoint.AREA_GET_LIST, data);

const getAreaDetail = (areaId, data) =>
  client.get(endPoint.AREA_GET_DETAIL.replace(':areaId', areaId), data);

export default {
  getAreasList,
  getAreaDetail,
};
