import React, { useState, useEffect } from 'react';
import './index.scss';
import Gallery from './Gallery';
import excutiveSummaryApi from '../../apis/api/excutive-summary';

const ExcutiveSummary = () => {
  const [executiveSummary, setExecutiveSummary] = useState();

  useEffect(async () => {
    const result = await excutiveSummaryApi.getExcutiveSummary('60efe38c91cec00021504d45');
    setExecutiveSummary(result.data);
  }, []);

  return (
    <>
      <div className="wrap-executive-summary">
        <div className="container-fluid d-flex">
          <div className="row col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="col-lg-4 col-md-4 col-sm-5">
              <div className="wrap-city-info">
                <h2 className="title">City</h2>
                <p className="description">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit. A nobis impedit hic
                  debitis eligendi atque maiores magni ea, accusamus dolorem laborum quae harum
                  architecto voluptatem beatae est porro modi repellat!
                </p>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-7">
              <div className="wrap-site-info">
                <div className="site-history">
                  <h2 className="title">Site history</h2>
                  <p className="description">{executiveSummary?.description}</p>
                </div>
                <div className="wrap-executive-gallery">
                  <Gallery images={executiveSummary?.images} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExcutiveSummary;
