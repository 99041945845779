import React from 'react';
import ImageBlock from './ImageBlock';

const Divider = (props) => {
  return (
    <div className="my-5">
      <ImageBlock imgSrc={ props.imgName } />
    </div>
  );
};

export default Divider;
