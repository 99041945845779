import PATH from './path';
import Index from '../pages/Index';
import TestScene from '../components/3d-scene/TestScene';
import LoginPage from '../pages/auth/login';
import NotFoundPage from '../pages/404';
import ForgotPassword from '../pages/auth/fotgot-password';
import ResetPassword from '../pages/auth/reset-password';
import CustomerSession from '../pages/customer-session';
import Presentation from '../pages/presentation';
import HoldingPage from '../pages/holding';
import CMS from '../pages/cms';
import Panorama from '../pages/360-pano';
import PanoramaPresentation from '../pages/360-pano/presentation';
import PanoramaVillasPage from '../pages/360-pano/panovillas';
import PanoramaVillasPresentationPage from '../pages/360-pano/panovillaspresentation';
import PanoramaVineyardPage from '../pages/360-pano/panovineyard';
import PanoramaVineyardPresentationPage from '../pages/360-pano/panovineyardpresentation';
import WaterScene from '../components/3d-scene/WaterScene';

export const routeOptions = [
  {
    path: PATH.ROOT,
    name: 'Home',
    component: Index,
    roles: [
      'customer',
      'admin',
      'agent'
    ],
    exact: true,
  },
  {
    path: PATH.LOGIN_SCREEN,
    name: 'Sign in',
    component: LoginPage,
    roles: [],
  },
  {
    path: PATH.HOLDING_SCREEN,
    name: 'Holding Page',
    component: HoldingPage,
    roles: [
      'admin',
      'agent',
    ],
  },
  {
    path: PATH.FORGOT_PASSWORD,
    name: 'Forgot password',
    component: ForgotPassword,
    roles: [],
  },
  {
    path: PATH.RESET_PASSWORD,
    name: 'Reset password',
    component: ResetPassword,
    roles: [],
  },
  {
    path: PATH.TEST_PAGE,
    name: 'Test Page',
    component: TestScene,
    roles: [],
  },
  {
    path: PATH.TEST_WATER,
    name: 'Test Water',
    component: WaterScene,
    roles: [],
  },
  {
    path: PATH.PRESENTATION_PAGE,
    name: 'Presentation Page',
    component: Presentation,
    roles: [ 'admin' ],
  },
  {
    path: PATH.NOTFOUND_PAGE,
    name: 'Not found',
    component: NotFoundPage,
    roles: [],
  },
  {
    path: PATH.POST_ENGAGEMENT_PAGE,
    name: 'post-engagement',
    component: CustomerSession,
    roles: [],
  },
  {
    path: PATH.CMS_PAGE,
    name: 'cms',
    component: CMS,
    roles: ['admin'],
  },
  {
    path: PATH.PANORAMA_PAGE,
    name: 'panorama',
    component: Panorama,
    roles: ['admin'],
  },
  {
    path: PATH.PANORAMA_PRESENTATION,
    name: 'panorama presentation',
    component: PanoramaPresentation,
    roles: ['admin'],
  },
  {
    path: PATH.PANORAMA_VILLAS,
    name: 'panorama villas',
    component: PanoramaVillasPage,
    roles: ['admin'],
  },
  {
    path: PATH.PANORAMA_VILLAS_PRESENTATION,
    name: 'panorama villas presentation',
    component: PanoramaVillasPresentationPage,
    roles: ['admin'],
  },
  {
    path: PATH.PANORAMA_VINEYARD,
    name: 'panorama vineyard',
    component: PanoramaVineyardPage,
    roles: ['admin'],
  },
  {
    path: PATH.PANORAMA_VINEYARD_PRESENTATION,
    name: 'panorama vineyard presentation',
    component: PanoramaVineyardPresentationPage,
    roles: ['admin'],
  }
];

export default routeOptions;
