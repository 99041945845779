import React from 'react';

const ImageBlock = (props) => {
  const { imgSrc } = props;

  return (
    <a className='light-gallery-item' href={imgSrc}>
      <img className="img-fluid" data-src={imgSrc} src={imgSrc} alt=""  />
    </a>
  );
};

export default ImageBlock;
