import ApiClient from '../apiClient';
import { defaultFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(defaultFetcher);

const getAgentList = (data) => client.get(endPoint.AGENT_LIST, data);
const getAgentDetail = (id, data) => client.get(endPoint.AGENT_DETAIL.replace(':id', id), data);

export default {
  getAgentList,
  getAgentDetail,
};
