import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import {reqSetEditableProperty} from '../../reduxs/property/action';
import { selectStyles, selectOptions } from './dist/config';
import { formatMoney, getStatus } from '../../helper/unit';

const PropertyRow = (props) => {
  const { property, isEditable, setPrice, setAvailability, setPropertyId, price } = props;
  const dispatch = useDispatch();
  const [isShowFeatures, toggleShowFeatures] = useState(false);

  useEffect(() => {
    if (isEditable) {
      setPrice(property.price);
      setAvailability(property.availabilityStatus);
      setPropertyId(property.id);
    }
  }, [isEditable]);

  const showEditForm = () => {
    dispatch(reqSetEditableProperty(property.id));
  };

  return (
    <tr>
      <td className="bg-0">
        <span
          className={`icon-collapse ${isShowFeatures && 'show'}`}
          role="button"
          onClick={() => toggleShowFeatures(!isShowFeatures)}
        />
      </td>
      <td className="ps-2">{property?.name}</td>
      <td className="ps-2">{property?.address}</td>
      <td>{property?.area.name}</td>
      <td className="w-125px">
        {isShowFeatures ? (
          <>
            Bathrooms: {property?.features?.bathrooms} <br />
            Bedrooms: {property?.features?.bedrooms} <br />
            Family Room: {property?.features?.familyRoom} <br />
            Floor: {property?.features?.floor} <br />
            Parking Stalls: {property?.features?.parkingStallCount} <br />
            Study: {property?.features?.study} <br />
          </>
        ) : (
          '...'
        )}
      </td>
      <td>{property?.floor_plan?.name}</td>
      <td>{property?.totalLot}</td>
      <td>{property?.totalHome}</td>
      <td className="w-125px">
        {isEditable ? (
          <input
            name="price"
            type="text"
            className="w-100px my-n2"
            value={price || ""}
            onChange={(e) => setPrice(e.target.value)}
          />
        ) : (
          formatMoney(property?.price)
        )}
      </td>
      <td className="w-125px">
        {isEditable ? (
          <Select
            name="availabilityStatus"
            styles={selectStyles}
            options={selectOptions}
            onChange={(e) => setAvailability(e.value)}
          />
        ) : (
          getStatus(property)
        )}
      </td>
      <td>
        <button
          onClick={() => showEditForm()}
          className={`btn btn-more rounded-pill shadow-0 ${isEditable && 'd-none'}`}
        >
          ...
        </button>
      </td>
    </tr>
  );
};

export default PropertyRow;
