import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import { ACTION_NAME, WEBSOCKET_CHANNEL } from '../../constants/options';
import socket, { emitUIActionEvent } from '../../helper/socket';

const IntroSlide = (props) => {
  const { resetState } = props;
  const authUser = useSelector((state) => state.user.data);
  const isPresentation = useSelector((state) => state.home.isPresentation);
  const introSlideRef = useRef();

  useEffect(() => {
    if (isPresentation && introSlideRef) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CHANGE_INTRO_SLIDE) {
          onGoToSlideIndex(content.data.index);
        }
      });
    }
  }, [ introSlideRef, isPresentation ]);

  const settings = {
    dots: true,
    autoPlay: true,
    infinite: false,
    arrow: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    afterChange: (index) => {
      if (!isPresentation) {
        emitUIActionEvent(authUser, ACTION_NAME.CHANGE_INTRO_SLIDE, {
          index,
        });
      }
    }
  };

  const onGoToSlideIndex = (index) => {
    introSlideRef?.current?.slickGoTo(index);
  }

  return (
    <>
      <Slider {...settings} ref={introSlideRef}>
        <div className="slide">
          <div className="wrap-bg center-center">
            <img
              src="./uploads/images/loading-1.jpg"
              alt="loading"
              style={{ height: '100vh' }}
            />
          </div>
        </div>
        <div className="slide">
          <div
            className="wrap-bg"
            style={{
              backgroundImage: 'url(./uploads/images/bg1.jpg)',
            }}
          >
            <img
              src="./uploads/images/foreground1.png"
              alt="loading"
            />
          </div>
        </div>
        <div className="slide">
          <div
            className="wrap-bg"
            style={{
              backgroundImage: 'url(./uploads/images/bg2.jpg)',
              color: '#fff',
            }}
          >
            <img
              src="./uploads/images/foreground2.png"
              alt="loading"
            />
          </div>
        </div>

        <div className="slide">
          <div
            className="wrap-bg"
            style={{
              backgroundImage: 'url(./uploads/images/bg3.jpg)',
            }}
          >
            <img
              src="./uploads/images/foreground3.png"
              alt="loading"
            />
          </div>
        </div>
      </Slider>
    </>
  );
};

export default IntroSlide;
