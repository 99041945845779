import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Canvas, useThree, extend, useFrame, useLoader } from 'react-three-fiber';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import * as THREE from 'three';
import { CustomControls } from './CustomControls';
import { OrbitCustomControls } from './OrbitCustomControls';
import FPSStats from 'react-fps-stats';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils';
import testObjectApi from '../../apis/api/test-object';
import { Html, useProgress, MeshReflectorMaterial, useGLTF, Environment } from '@react-three/drei';
import { Water } from 'three-stdlib';
import { Sky } from './Sky';
import { GUI } from 'three/examples/jsm/libs/dat.gui.module';

extend({ CustomControls });
extend({ OrbitCustomControls });
extend({ Water })
extend({ Sky })
extend({ MeshReflectorMaterial })

const WaterScene = React.memo((props) => {
  const light = useRef();
  const [fbxObjects, setFbxObjects] = useState([]);
  const [glfxObjects, setGlfxObjects] = useState([]);
  const [glbObjects, setGlbObjects] = useState([]);
  const controls = useRef();
  const sky = useRef();
  const sun = new THREE.Vector3(0, 0.0, 0);

  // MARK: GUI
  const effectController = {
    turbidity: 14,
    rayleigh: 3,
    mieCoefficient: 0.004,
    mieDirectionalG: 0.9,
    elevation: 0,
    azimuth: 0,
    exposure: 1.0
  };

  function guiChanged() {

    if (sky == undefined || sky.current == undefined) {
      return;
    }

    const uniforms = sky.current.material.uniforms;
    uniforms[ 'turbidity' ].value = effectController.turbidity;
    uniforms[ 'rayleigh' ].value = effectController.rayleigh;
    uniforms[ 'mieCoefficient' ].value = effectController.mieCoefficient;
    uniforms[ 'mieDirectionalG' ].value = effectController.mieDirectionalG;

    const phi = THREE.MathUtils.degToRad( 90 - effectController.elevation );
    const theta = THREE.MathUtils.degToRad( effectController.azimuth );

    sun.setFromSphericalCoords( 1, phi, theta );

    uniforms[ 'sunPosition' ].value.copy( sun );

    sky.current.exposure = effectController.exposure;
  }

  useEffect(() => {
    const gui = new GUI();

    gui.add( effectController, 'turbidity', 0.0, 20.0, 0.1 ).onChange( guiChanged );
    gui.add( effectController, 'rayleigh', 0.0, 4, 0.001 ).onChange( guiChanged );
    gui.add( effectController, 'mieCoefficient', 0.0, 0.1, 0.001 ).onChange( guiChanged );
    gui.add( effectController, 'mieDirectionalG', 0.0, 1, 0.001 ).onChange( guiChanged );
    gui.add( effectController, 'elevation', 0, 90, 0.1 ).onChange( guiChanged );
    gui.add( effectController, 'azimuth', - 180, 180, 0.1 ).onChange( guiChanged );
    gui.add( effectController, 'exposure', 0, 1, 0.0001 ).onChange( guiChanged );

    guiChanged();
  }, []);

  useEffect(async () => {
    let result = await testObjectApi.getAssetsList();
    var a = [];
    var b = [];
    var c = [];
    for (let i = 0; i < result.data.length; i++) {
      let data = result.data[i];
      if (data['3d_filename'].endsWith('fbx')) {
        a.push(data);
      } else if (data['3d_filename'].endsWith('gltf')) {
        b.push(data);
      } else if (data['3d_filename'].endsWith('glb')) {
        c.push(data);
      }
    }
    setFbxObjects(a);
    setGlfxObjects(b);
    setGlbObjects(c);
  }, []);

  function TestCoordinates() {
    var numbers = [];
    for (var i = 10; i < 1000; i += 10) {
      numbers.push(i);
    }
    return (
      <group>
        {numbers.map((item, key) => {
          return (
            <group key={key}>
              <mesh scale={[1, 1, 1]} position={[item, 0, 0]}>
                <boxBufferGeometry args={[1, 1, 1]} />
                <meshStandardMaterial color={'black'} />
              </mesh>
              <mesh scale={[1, 1, 1]} position={[0, item, 0]}>
                <boxBufferGeometry args={[1, 1, 1]} />
                <meshStandardMaterial color={'black'} />
              </mesh>
              <mesh scale={[1, 1, 1]} position={[0, 0, item]}>
                <boxBufferGeometry args={[1, 1, 1]} />
                <meshStandardMaterial color={'black'} />
              </mesh>
            </group>
          );
        })}
      </group>
    );
  }

  function Loader() {
    const { progress } = useProgress();
    return (
      <Html center style={{ color: '#fff' }}>
        {progress.toFixed(3)}%
      </Html>
    );
  }

  function FbxModel() {
    var url_string = window.location.href;
    var url = new URL(url_string);

    var name = url.searchParams.get('name');
    if (name == null) {
      name = 'test';
    }

    let optimize_draw_call = url.searchParams.get('draw_call');
    if (optimize_draw_call == null) {
      optimize_draw_call = true;
    }

    let use_texture = url.searchParams.get('use_texture');
    if (use_texture == null) {
      use_texture = true;
    }

    let color = url.searchParams.get('color');
    if (color == null) {
      color = '999999';
    }

    const [...fbxModels] = useLoader(
      FBXLoader,
      fbxObjects.map((item) => `${process.env.REACT_APP_API_URL}/binyan/${item['3d_filename']}`)
    );

    //if (light.current != null) {
    ///  light.current.shadow.needsUpdate = true;
    //}


    return (
      <group>
        {fbxModels.map((item, index) => {
          const object3d = item.clone(true);
          const object = fbxObjects[index];

          const castShadow = object.cast_shadow;
          const receiveShadow = object.receive_shadow;

          let geometries = [];
          let material = new THREE.MeshStandardMaterial();

          material.roughness = 0.95;
          material.metalness = 0.6;

          object3d.traverse(function (child) {
            child.castShadow = castShadow;
            child.receiveShadow = receiveShadow;
            if (child instanceof THREE.Mesh) {
              var geometry = child.geometry.clone();
              let matrix = new THREE.Matrix4();
              let position = new THREE.Vector3();
              let scale = new THREE.Vector3();
              let quaternion = new THREE.Quaternion();

              child.matrix.decompose(position, quaternion, scale);

              if (scale.y < 0.0) {
                scale.y = -scale.y;
              }
              if (scale.x < 0.0) {
                scale.x = -scale.x;
              }
              if (scale.z < 0.0) {
                scale.z = -scale.z;
              }

              matrix.compose(position, quaternion, scale);
              geometry.applyMatrix4(matrix);

              geometries.push(geometry);

              if (use_texture) {
                if (child.material instanceof THREE.Material) {
                  material = child.material.clone();
                } else if (child.material instanceof Array) {
                  if (child.material.length > 0) {
                    material = child.material[child.material.length - 1].clone();
                  }
                }
              }
            }
          });
          const newColor = new THREE.Color(`#${color}`);
          if (!use_texture) {
            material.color = newColor;
          }
          let scene;

          if (optimize_draw_call == true) {
            let mergedGeometry = BufferGeometryUtils.mergeBufferGeometries(geometries, false);
            scene = new THREE.Mesh(mergedGeometry, material);
          } else {
            scene = object3d;
          }
          scene.castShadow = castShadow;
          scene.receiveShadow = receiveShadow;

          if (receiveShadow) {
            //scene.updateMatrixWorld();
            //light.current.target = scene;
          }
          return (
            <primitive
              key={index}
              object={scene}
              dispose={null}
              position={[0.0, 0.0, 0.0]}
              scale={[1, 1, 1]}
              rotation={[0, 0, 0]}
            />
          );
        })}
      </group>
    );
  }

  function GltfModel() {
    const [...gltfModels] = useLoader(
      GLTFLoader,
      glfxObjects.map((item) => `https://binyan-api.testbox.com.au/binyan/${item['3d_filename']}`)
    );
    return (
      <group>
        {gltfModels.map((item, index) => {
          const object3d = item;
          return (
            <primitive
              key={index}
              object={object3d.scene}
              dispose={null}
              position={[0.0, 0.0, 0.0]}
              scale={[1, 1, 1]}
              rotation={[0, 0, 0]}
            />
          );
        })}
      </group>
    );
  }

  function GlbModel() {
    const models = glbObjects;
    const [...glbModels] = useLoader(
      GLTFLoader,
      models.map((item) => `https://binyan-api.testbox.com.au/binyan/${item['3d_filename']}`)
    );
    return (
      <group>
        {glbModels.map((item, index) => {
          const object3d = item;
          return (
            <primitive
              key={index}
              object={object3d.scene}
              dispose={null}
              position={[0.0, 0.0, 0.0]}
              scale={[1, 1, 1]}
              rotation={[0, 0, 0]}
            />
          );
        })}
      </group>
    );
  }

  const SkyModel = React.memo(() => {
    const {
      gl,
    } = useThree();
    return <sky ref={sky} scale={[450000, 450000, 450000]} />;
  });
  SkyModel.displayName = 'SkyModel';

  function WaterModel() {
    const {
      gl,
    } = useThree();

    const ref = useRef();

    const waterNormals = useLoader(THREE.TextureLoader, '/waternormals.jpg')
    waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping;

    const jsonData = useLoader(THREE.FileLoader, '/uploads/test_water.json');
    const testWaterJson = JSON.parse(jsonData);
    const geometry = new THREE.BufferGeometry();

    for (let key in testWaterJson.data.attributes) {
      const array = new Float32Array(testWaterJson.data.attributes[key].array.length);
      array.set(testWaterJson.data.attributes[key].array);
      geometry.setAttribute(
        key,
        new THREE.BufferAttribute(array, testWaterJson.data.attributes[key].itemSize, false)
      );
    }
    const array = new Uint32Array(testWaterJson.data.index.array.length);
    array.set(testWaterJson.data.index.array);
    geometry.index = new THREE.BufferAttribute(array, 1, false);

    const config = useMemo(
      () => ({
        textureWidth: 512,
        textureHeight: 512,
        waterNormals,
        sunDirection: new THREE.Vector3(),
        sunColor: 0xffffff,
        waterColor: 0x001e0f,
        distortionScale: 3.7,
        fog: false,
        format: gl.encoding
      }),
      [waterNormals]
    )

    useFrame((state, delta) => {
      if (ref.current) {
        ref.current.material.uniforms.time.value += delta;
      }
      if (sky.current) {
        state.gl.toneMappingExposure = sky.current.exposure;
      }
    });

    return (
      <group>
        {true && <water ref={ref} args={[geometry, config]} rotation-x={-Math.PI / 2} />}
      </group>

    );
  }

  function RefractionModel() {
    // Line186159.glb
    const glbModel = useLoader(GLTFLoader,
      `https://binyan-api.testbox.com.au/binyan/Line186159.glb`);
    return (
      <mesh position={[0, -1.5, 0]} rotation={[-Math.PI / 2, 0, 0]}>
          <planeGeometry args={[50, 50]} />
          <meshReflectorMaterial
            blur={[400, 100]}
            resolution={1024}
            mixBlur={1}
            mixStrength={15}
            depthScale={1}
            minDepthThreshold={0.85}
            color="#151515"
            metalness={0.6}
            roughness={1}
          />
        </mesh>
    );
  }

  function DamagedHelmetModel2() {
    const glbModel = useLoader(GLTFLoader,
      'uploads/scene.gltf');

    console.log('Luan scene ', glbModel);
    return (
      <primitive
        object={glbModel.scene}
        dispose={null}
        position={[0.0, 0.0, 100.0]}
        scale={[10, 10, 10]}
        rotation={[0, -Math.PI, 0]}
      />
    );
  }

  function DamagedHelmetModel3() {
    const glbModel = useLoader(GLTFLoader,
      'uploads/DamagedHelmet.glb');

    console.log('Luan scene ', glbModel);
    return (
      <primitive
        object={glbModel.scene}
        dispose={null}
        position={[0.0, 10.0, 0.0]}
        scale={[10, 10, 10]}
        rotation={[0, -Math.PI, 0]}
      />
    );
  }

  function DamagedHelmetModel() {
    //const glbModel = useLoader(GLTFLoader,
    // /  'draco.glb');
    const { nodes } = useGLTF('/draco.glb');

    console.log('Luan scene ', nodes);
    return (
      <group position={[0.0, 0.0, -100.0]} rotation={[Math.PI / 2, 0, 3]}>
        <mesh geometry={nodes["Untitled018"].geometry} castShadow>
          <meshPhysicalMaterial
            transmission={0.9}
            roughness={0}
            thickness={20}
            envMapIntensity={0.6}
            color="#3f2510"
            clearcoat={0.5}
            clearcoatRoughness={1}
          />
        </mesh>
      </group>
    );
  }

  const CameraControls = React.memo(() => {
    const {
      camera,
      gl: { domElement },
    } = useThree();

    camera.near = 0.01;
    camera.far = 10000;
    camera.position.copy(new THREE.Vector3(80, 80, 0));
    camera.lookAt(new THREE.Vector3(0,0,0));
    camera.updateProjectionMatrix();

    useFrame(() => {
      if (controls != null && controls.current != null) {
        controls.current.update();
      }
    });

    return (
      <orbitCustomControls
        ref={controls}
        args={[camera, domElement]}
        disabledUpdate={false}
        neverUpdate={false}
        autoRotate={false}
        enableDamping={true}
        maxDistance={6640}
        minDistance={2}
        minZoom={100}
        maxZoom={2000}
       />
    );
  });
  CameraControls.displayName = 'CameraControls';

  return (
    <>
      <Canvas gl={{
          outputEncoding: THREE.sRGBEncoding ,
          shadowMap: {
            autoUpdate : false,
            type : THREE.PCFSoftShadowMap,
          },
          logarithmicDepthBuffer : true,
          outputEncoding : THREE.sRGBEncoding,
        }}
        shadowMap pixelRatio={window.devicePixelRatio}>
        <CameraControls />
        {true && <ambientLight intensity={0.2} color={0x2e2e2a} />}
        {true && <hemisphereLight
          intensity={0.4}
          skyColor={0xb1e1ff}
          groundColor={0x2e2e2a}
          position={[0, -9, 0]}
        />}
        {true && <directionalLight
          ref={light}
          intensity={1.2}
          castShadow
          color={0xffffff}
          position={[-3000, 1200, 500]}
          shadow-mapSize-height={2048}
          shadow-mapSize-width={2048}
          shadow-camera-near={100}
          shadow-camera-far={4000}
          shadow-camera-left={-2000}
          shadow-camera-right={2000}
          shadow-camera-top={2000}
          shadow-camera-bottom={-2000}
          shadow-autoUpdate={false}
          shadow-bias={0.1}
        />}
        <pointLight position={[100, 100, 100]} />
        <pointLight position={[-100, -100, -100]} />
        {true && <directionalLight intensity={0.4} color={0xffffff} position={[1500, 600, -250]} />}
        {true && <directionalLight intensity={0.4} color={0xffffff} position={[-250, 600, 1500]} />}
        <React.Suspense fallback={<Loader />}>
          {true && <FbxModel />}
          {true && <GltfModel />}
          {true && <GlbModel />}
          {false && <WaterModel />}
          {true && <SkyModel />}
          {false && <RefractionModel />}
          {true && <DamagedHelmetModel2 />}
          {true && <DamagedHelmetModel />}
          {true && <DamagedHelmetModel3 />}
          <Environment preset="apartment" />
        </React.Suspense>
        <axesHelper args={[1000]} />
        <TestCoordinates />
      </Canvas>
      <FPSStats />
    </>
  );
});
WaterScene.displayName = 'WaterScene';

export default WaterScene;
