import React from 'react';
import ImageBlock from './ImageBlock';

const ImageBlockFloorplan = (props) => {
  return (
    <div className="col w-37">
      <ImageBlock imgSrc={props.imgSrc} />
    </div>
  );
};

export default ImageBlockFloorplan;
