export const config = {
  center: {
    lat: 38.2572437,
    lng: -122.3542641,
  },
  zoom: 12,
};

export const options = {
  styles: [
    {
      "featureType": "all",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#c3b1d5"
        }
      ]
    },
    {
      "featureType": "all",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "gamma": "0.89"
        },
        {
          "lightness": "-75"
        },
        {
          "saturation": "-29"
        },
        {
          "weight": "1.69"
        },
        {
          "color": "#c6bcd0"
        }
      ]
    },
    {
      "featureType": "all",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "saturation": -31
        },
        {
          "lightness": -33
        },
        {
          "weight": 2
        },
        {
          "gamma": 0.8
        },
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "all",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "labels.text",
      "stylers": [
        {
          "visibility": "on"
        },
        {
          "saturation": "45"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "lightness": "-72"
        },
        {
          "saturation": "-23"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "landscape",
      "elementType": "geometry",
      "stylers": [
        {
          "lightness": "-18"
        },
        {
          "saturation": "-33"
        }
      ]
    },
    {
      "featureType": "landscape",
      "elementType": "labels.text",
      "stylers": [
        {
          "saturation": "54"
        }
      ]
    },
    {
      "featureType": "landscape.man_made",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "visibility": "on"
        }
      ]
    },
    {
      "featureType": "landscape.natural",
      "elementType": "labels.text",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "landscape.natural.landcover",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "visibility": "on"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "saturation": "-28"
        },
        {
          "lightness": "-28"
        },
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "lightness": "-10"
        },
        {
          "saturation": "-32"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "saturation": "-9"
        },
        {
          "lightness": "48"
        },
        {
          "visibility": "on"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.school",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "lightness": "47"
        },
        {
          "saturation": "7"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "saturation": 25
        },
        {
          "lightness": 25
        },
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "saturation": "37"
        },
        {
          "lightness": "-60"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "visibility": "on"
        },
        {
          "saturation": "-14"
        },
        {
          "lightness": "-15"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "all",
      "stylers": [
        {
          "lightness": -20
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "saturation": "-26"
        },
        {
          "lightness": "-29"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text",
      "stylers": [
        {
          "lightness": "68"
        },
        {
          "saturation": "-25"
        }
      ]
    }
  ],
};

export const specialLocations = [
  {
    "id": "612e367abe5ae5cef4168aa9",
    "coordinate": {"lat": 38.2436599,"long": -122.3007812},
    "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque blanditiis dolor illo error necessitatibus culpa. Temporibus maiores quibusdam ducimus natus consequuntur nam, itaque modi autem laborum placeat quia, a porro.",
    "group": "STANLY RANCH",
    "modal": {
      "id":"61394f098eef2b660e73c02c",
      "content":"<div style=\"padding: 30px; background-color: #fff\">\n  <div style=\"\" class=\"px-3 py-4 d-flex align-item-center text-center\">\n  <div>\n  <p style=\"letter-spacing: 0.2rem;\">Stanly Ranch</p>\n  <h2 class=\"f-title mt-4\">\n  Stanly Ranch Auberge Collections Napa\n </h2>\n  <p class=\"mb-0 mt-4\">200 Stanly Cross Rd,<br>Napa, CA 94559</p>\n  </div>\n  </div>\n </div>",
      "type":"pois"
    },
    "name": "Stanly Ranch Auberge Collections Napa",
    "icon": "uploads/images/icons/stanly_ranch_icon.svg",
  },
  {
    "id":"612e367abe5ae5cef4168ac5",
    "coordinate":{"lat":38.29295225985651,"long":-122.45765962415877},
    "description":"Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque blanditiis dolor illo error necessitatibus culpa. Temporibus maiores quibusdam ducimus natus consequuntur nam, itaque modi autem laborum placeat quia, a porro.",
    "group":"DOWNTOWN",
    "modal_": {
      "id":"613954ad8eef2b660e73c056",
      "content":"<div style=\"padding: 30px; background-color: #fff\"> <div style=\"\" class=\"px-3 py-4 d-flex align-item-center text-center\"> <div> <p style=\"letter-spacing: 0.2rem;\">Downtown</p> <h2 class=\"f-title mt-4\"> Downtown Sonoma </h2> </div> </div> </div>",
      "type":"pois"
    },
    "name":"Downtown Sonoma",
    "icon":"uploads/images/icons/icon-downtown-sonoma.svg"
  },
  {
    "id":"612e367abe5ae5cef4168dc1",
    "coordinate":{"lat":38.2978836795144,"long":-122.28796385899975},
    "description":"Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque blanditiis dolor illo error necessitatibus culpa. Temporibus maiores quibusdam ducimus natus consequuntur nam, itaque modi autem laborum placeat quia, a porro.",
    "group":"DOWNTOWN",
    "modal_": {
      "id":"613954ad8eef2b660e73c056",
      "content":"<div style=\"padding: 30px; background-color: #fff\"> <div style=\"\" class=\"px-3 py-4 d-flex align-item-center text-center\"> <div> <p style=\"letter-spacing: 0.2rem;\">Downtown</p> <h2 class=\"f-title mt-4\"> Downtown Napa </h2> </div> </div> </div>",
      "type":"pois"
    },
    "name":"Downtown Napa",
    "icon":"uploads/images/icons/icon-downtown-napa.svg"
  },
  {
    "id":"612e367abe5ae5cef4168abc",
    "coordinate":{"lat":38.2172561,"long":-122.2730219},
    "description":"Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque blanditiis dolor illo error necessitatibus culpa. Temporibus maiores quibusdam ducimus natus consequuntur nam, itaque modi autem laborum placeat quia, a porro.",
    "group":"TRANSPORTATION",
    "modal": {
      "id": "6139547b8eef2b660e73c04d",
      "content":"<div style=\"padding: 30px; background-color: #fff\"> <div style=\"\" class=\"px-3 py-4 d-flex align-item-center text-center\"> <div> <p style=\"letter-spacing: 0.2rem;\">Transportation</p> <h2 class=\"f-title mt-4\"> Napa County Airport </h2> <p class=\"mb-0 mt-4\"> 2030 Airport Rd,<br> Napa, CA 94558 </p> </div> </div> </div>",
      "type":"pois"},
    "name":"Napa County Airport",
    "icon":"uploads/images/icons/airport_icon.svg"}
]
