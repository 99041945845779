/* eslint-disable no-undef */
export const META_DATA = {
  PAGINATION: {
    PAGE_SIZE_NONE: 10000,
    PAGE_SIZE: 1000,
  },
};

export const LIFX_ADMIN = [
  '61df965600c7e7bc723fb270',
  '606d6f926af09f50f367ee25',
  '606d6f926af09f50f367ee26'
]

export default {
  SITE_URL: process.env.REACT_APP_URL,
  API_URL: process.env.REACT_APP_API_URL,
  SOCKET_URL: process.env.REACT_APP_SOCKET_URL,
  LIFX_URL: process.env.REACT_APP_LIFX_URL,
};
