import React from 'react';

const ConfirmMessage = (props) => {
  const { successModal, setSuccessModal } = props;
  return (
    <>
      <div
        className={`wrap-modal wrap-modal-confirm-message fade-item-enter-done ${
          successModal ? '' : 'hidden'
        }`}
      >
        <div>
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="f-title">Support Tickets</h2>
              <div onClick={() => setSuccessModal(false)}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 35 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="8.57918"
                    y1="8.73918"
                    x2="25.8968"
                    y2="26.0568"
                    stroke="#282A2D"
                    strokeWidth="2"
                  />
                  <line
                    x1="25.897"
                    y1="8.5782"
                    x2="8.57943"
                    y2="25.8958"
                    stroke="#282A2D"
                    strokeWidth="2"
                  />
                </svg>
              </div>
            </div>
            <div className="modal-body">
              <p>
                Your ticket has been sent to the BINYAN experience app support team. Please allow 24
                hours for our team to get back to you.
                <br />
                <br /> Our response will be sent to the following email address: &emsp;
                <b>own@stanlyranchnapa.com</b>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={`wrap-modal-backdrop ${successModal ? '' : 'hidden'}`} />
    </>
  );
};
export default ConfirmMessage;
