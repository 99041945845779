import ApiClient from '../apiClient';
import { defaultFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(defaultFetcher);

const getSessionList = (data) => client.get(endPoint.SESSION_LIST, data);
const getSessionDetail = (id, data) => client.get(endPoint.SESSION_DETAIL.replace(':id', id), data);
const createSession = (data) => client.post(endPoint.SESSION_CREATE, data);
const updateSession = (id, data) => client.put(endPoint.SESSION_UPDATE.replace(':id', id), data);
const deleteSession = (id, data) => client.delete(endPoint.SESSION_DELETE.replace(':id', id), data);
const sendSessionInvitation = (data) => client.post(endPoint.SESSION_INVITATION, data);

export default {
  getSessionList,
  getSessionDetail,
  createSession,
  updateSession,
  deleteSession,
  sendSessionInvitation,
};
