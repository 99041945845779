import * as type from './type';

const initialState = {
  data: '',
  metadata: {},
  obj: {},
  agents: [],
  customers: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case type.GET_USER_PROFILE:
      return {
        ...state,
        data: action.data.data,
        metadata: action.data.metadata,
      };
    case type.GET_AGENT_LIST:
      return {
        ...state,
        agents: action.data.data,
      };
    case type.GET_CUSTOMER_LIST:
      return {
        ...state,
        customers: action.data.data,
      };
    default:
      return state;
  }
};
