import React, { useEffect } from 'react';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { reqSetActiveAmenity, actSetAmenityItemLatestTime } from '../../reduxs/amenities/action';
import { reqSetIsShowExploreModal, reqSetExploreModal } from '../../reduxs/explore-modal/action';
import socket, { emitUIActionEvent } from '../../helper/socket';
import { WEBSOCKET_CHANNEL, ACTION_NAME } from '../../constants/options';

const Amenities = () => {
  const activeAmenityArea = useSelector((state) => state.amenities.activeAmenityArea);
  const activeAmenity = useSelector((state) => state.amenities.activeAmenity);
  const isPresentation = useSelector((state) => state.home.isPresentation);
  const authUser = useSelector((state) => state.user.data);
  const dispatch = useDispatch();

  const latestTimeAmenityItem = useSelector((state) => state.amenities.latestTime);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CLICK_AMENITY_ITEM) {
          if (content.data?.time > latestTimeAmenityItem) {
            handleAmenityItemClick(content?.data?.item);
            dispatch(actSetAmenityItemLatestTime(content.time));
          } else {
            console.log("Cancel A");
          }
        }
      });
    }
  }, []);

  const handleAmenityItemClick = async (item) => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.CLICK_AMENITY_ITEM, {
        item,
        time: Date.now()
      })
    }
    await dispatch(reqSetActiveAmenity(item?.id));
    await dispatch(reqSetExploreModal(item?.modal));
    await dispatch(reqSetIsShowExploreModal(true));
  }

  return (
    <div className={`wrap-amenities card side-card left-card `}>
      <h2 className="card-title">{activeAmenityArea?.name}</h2>
      <div className="card-body">
        <div className="wrap-list-group">
          <ul className="list-group">
            {(activeAmenityArea?.amenities || []).map((item, key) => {
              return (
                <li
                  className={`list-group-item ${activeAmenity?.id == item.id ? 'active' : ''}`}
                  onClick={() => handleAmenityItemClick(item)}
                  key={key}
                >
                  {key + 1}&nbsp;&nbsp;{item.name}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Amenities;
