export default {
  // guest
  ROOT: '/',
  LOGIN_SCREEN: '/sign-in',
  HOLDING_SCREEN: '/holding',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  REQUEST_FORM: '/request-form',
  ASSETS_PAGE: '/performance-test',
  TEST_PAGE: '/test',
  TEST_WATER: '/test-water',
  NOTFOUND_PAGE: '/404',
  POST_ENGAGEMENT_PAGE: '/post-engagement/:customerId',
  PUBLIC_PAGE: '/public/:customerId',
  CMS_PAGE: '/cms',
  PRESENTATION_PAGE: '/presentation',
  PANORAMA_PAGE: '/panorama',
  PANORAMA_PRESENTATION: '/panorama-presentation',
  PANORAMA_VILLAS: '/pano-villas',
  PANORAMA_VILLAS_PRESENTATION: '/pano-villas-presentation',
  PANORAMA_VINEYARD: '/pano-vineyard',
  PANORAMA_VINEYARD_PRESENTATION: '/pano-vineyard-presentation',
};
