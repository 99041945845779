import * as Types from './type';
import authApi from '../../apis/api/auth';
import agentApi from '../../apis/api/agent';
import customerApi from '../../apis/api/customer';

const actGetUserProfile = (data) => ({
  type: Types.GET_USER_PROFILE,
  data,
});

const actGetAgentList = (data) => ({
  type: Types.GET_AGENT_LIST,
  data,
});

const actGetCustomerList = (data) => ({
  type: Types.GET_CUSTOMER_LIST,
  data,
});

export const reqGetUserProfile = () => (dispatch) => {
  return authApi
    .getMe()
    .then((data) => {
      dispatch(actGetUserProfile(data));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const reqGetListAgents = () => (dispatch) => {
  return agentApi
    .getAgentList()
    .then((data) => {
      dispatch(actGetAgentList(data));
    }).catch((error) => {
      console.log(err);
    });
}

export const reqGetListCustomers = () => (dispatch) => {
  return customerApi
    .getCustomerList()
    .then((data) => {
      dispatch(actGetCustomerList(data));
    }).catch((error) => {
      console.log(err);
    });
}
