import ApiClient from '../apiClient';
import { authFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(authFetcher);

const getAssetsList = (data) => client.get(endPoint.ASSSETS_GET_LIST, data);
const getAssetDetail = (assetId, data) =>
  client.get(endPoint.ASSSETS_GET_DETAIL.replace(':assetId', assetId), data);

export default {
  getAssetsList,
  getAssetDetail,
};
