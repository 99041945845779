import ApiClient from '../apiClient';
import { authFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(authFetcher);

const getTeamDetail = (teamId, data) =>
  client.get(endPoint.GET_TEAM_DETAIL.replace(':teamId', teamId), data);

export default {
  getTeamDetail,
};
