import * as Types from './type';
import authApi from '../../apis/api/auth';

const actSetPage = (data) => ({
  type: Types.SET_PAGE,
  data,
});

const actSetIsShowBottomNav = (data) => ({
  type: Types.SET_IS_SHOW_BOTTOM_NAV,
  data,
});

const actSetIsShowImmerse = (data) => ({
  type: Types.SET_IS_SHOW_IMMERSE,
  data,
});

const actSetIsExpandNav = (data) => ({
  type: Types.SET_IS_EXPAND_NAV,
  data,
});

const actSetActivePatourId = (data) => ({
  type: Types.SET_ACTIVE_PANTOUR_ID,
  data,
});

const actSetIsShowExploreModal = (data) => ({
  type: Types.SET_IS_SHOW_EXPLORE_MODAL,
  data,
});

const actSetIsTransparent = (data) => ({
  type: Types.SET_IS_TRANSPARENT,
  data,
});

const actSetPanoName = (data) => ({
  type: Types.SET_PANO_NAME,
  data,
})

const actSetActiveGalleryId = (data) => ({
  type: Types.SET_ACTIVE_GALLERY_ID,
  data,
});

const actSetIsShowStartSessionModal = (data) => ({
  type: Types.SET_IS_SHOW_START_SESSION_MODAL,
  data,
});

const actSetIsShowCustomerDetailModal = (data) => ({
  type: Types.SET_IS_SHOW_CUSTOMER_DETAIL_MODAL,
  data,
});

const actSetIsShowCreateCustomerModal = (data) => ({
  type: Types.SET_IS_SHOW_CREATE_CUSTOMER_MODAL,
  data,
});

const actSetIsPresentation = (data) => ({
  type: Types.SET_IS_PRESENTATION,
  data,
});

export const reqSetPage = (data) => (dispatch) => {
  return dispatch(actSetPage(data));
};

export const reqSetIsShowBottomNav = (data) => (dispatch) => {
  return dispatch(actSetIsShowBottomNav(data));
};

export const reqSetIsShowImmerse = (data) => (dispatch) => {
  return dispatch(actSetIsShowImmerse(data));
};

export const reqSetIsExpandNav = (data) => (dispatch) => {
  return dispatch(actSetIsExpandNav(data));
};

export const reqSetActivePatourId = (data) => (dispatch) => {
  return dispatch(actSetActivePatourId(data));
};

export const reqSetIsShowExploreModal = (data) => (dispatch) => {
  return dispatch(actSetIsShowExploreModal(data));
};

export const reqSetIsTransparent = (data) => (dispatch) => {
  return dispatch(actSetIsTransparent(data));
};

export const reqSetPanoName = (data) => (dispatch) => {
  return dispatch(actSetPanoName(data));
};

export const reqSetActiveGalleryId = (data) => (dispatch) => {
  return dispatch(actSetActiveGalleryId(data));
};

export const reqSetIsShowStartSessionModal = (data) => (dispatch) => {
  return dispatch(actSetIsShowStartSessionModal(data));
};

export const reqSetIsShowCustomerDetailModal = (data) => (dispatch) => {
  return dispatch(actSetIsShowCustomerDetailModal(data));
};

export const reqSetIsShowCreateCustomerModal = (data) => (dispatch) => {
  return dispatch(actSetIsShowCreateCustomerModal(data));
};

export const reqSetIsPresentation = (data) => (dispatch) => {
  return dispatch(actSetIsPresentation(data));
};


