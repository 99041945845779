export const GET_UNIT_LIST = 'GET_UNIT_LIST';
export const GET_UNIT_ITEM = 'GET_UNIT_ITEM';
export const PUT_UNIT_UPDATE = 'PUT_UNIT_UPDATE';
export const POST_UNIT_CREATE = 'POST_UNIT_CREATE';
export const DELETE_UNIT_CREATE = 'DELETE_UNIT_CREATE';

export const UNIT_LOADING = 'UNIT_LOADING';
export const UNIT_IS_CREATE = 'UNIT_IS_CREATE';
export const UNIT_IS_UPDATE = 'UNIT_IS_UPDATE';
export const UNIT_IS_DELETE = 'UNIT_IS_DELETE';
