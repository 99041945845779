import React, { useEffect } from 'react';
import './index.scss';
// import MemberProfile from "./MemberProfile";
import teamApi from '../../apis/api/team';

const Team = () => {
  // const [team, setTeam] = useState();

  useEffect(async () => {
    let result = await teamApi.getTeamDetail('60e414ac2ef22300155b8d07');
    // setTeam(result.data);
    result;
  }, []);

  return (
    <>
      <div className="wrap-team">
        <div className="container">
          <div className="img-block">
            <div className="wrap-img w-50">
              <img src="./uploads/images/team/Auberge.png" alt="logo" />
            </div>

            <div className="wrap-img w-50">
              <img src="./uploads/images/team/Nichols.png" alt="logo" />
            </div>
          </div>
          <div className="img-block">
            <h2 className="team-title">DESIGN PARTNERS</h2>
            <div className="wrap-img w-33 flex-column">
              <img src="./uploads/images/team/Overland.png" alt="logo" />
              <span className="clearfix text-muted small">Architecture</span>
            </div>
            <div className="wrap-img w-33 flex-column">
              <img src="./uploads/images/team/Brightview.png" alt="logo" />
              <span className="clearfix text-muted small">Landscape Design &amp; Amenity Design</span>
            </div>
            <div className="wrap-img w-33 flex-column">
              <img src="./uploads/images/team/Agriburbia.png" alt="logo" />
              <span className="clearfix text-muted small">Agricultural Programming</span>
            </div>
            <div className="wrap-img w-33 flex-column">
              <img src="./uploads/images/team/JZMK.png" alt="logo" />
              <span className="clearfix text-muted small">Architecture</span>
            </div>
            <div className="wrap-img w-33 flex-column">
              <img src="./uploads/images/team/Forest.png" alt="logo" />
              <span className="clearfix text-muted small">Architecture</span>
            </div>
            <div className="wrap-img w-33 flex-column">
              <img src="./uploads/images/team/EBTA.png" alt="logo" />
              <span className="clearfix text-muted small">Architecture</span>
            </div>
            <div className="wrap-img w-25 flex-column">
              <img src="./uploads/images/team/CCID.png" alt="logo" />
              <span className="clearfix text-muted small">Interior Design</span>
            </div>
            <div className="wrap-img w-25 flex-column">
              <img src="./uploads/images/team/ELA.png" alt="logo" />
              <span className="clearfix text-muted small">Interior Design</span>
            </div>
            <div className="wrap-img w-25 flex-column">
              <img src="./uploads/images/team/AVRO.png" alt="logo" />
              <span className="clearfix text-muted small">Interior Design</span>
            </div>
            <div className="wrap-img w-25 flex-column">
              <img src="./uploads/images/team/Gordon.png" alt="logo" />
              <span className="clearfix text-muted small">Artistry</span>
            </div>
          </div>
          <div className="img-block mt-4">
            <h2 className="team-title">SALES & MARKETING</h2>
            <div className="wrap-img w-100">
              <img src="./uploads/images/team/Compass.png" alt="logo" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
