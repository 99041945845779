import ApiClient from '../apiClient';
import { defaultFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(defaultFetcher);

const savePreference = (data) => client.post(endPoint.PREFERENCE_UPDATE, data);
const getCustomerPreference = (data) => client.get(endPoint.PREFERENCE_CUSTOMER, data);

export default {
  savePreference,
  getCustomerPreference,
};
