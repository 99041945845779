import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';

const NotFoundPage = () => {
  return (
    <div className="wrap-404-page">
      <div className="wrap-desciption">
        <h1>404</h1>
        <h4>Page not found</h4>
        <Link className="btn-text" to="/">
          Go to home page
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
