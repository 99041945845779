import * as Types from './type';
import modalApi from '../../apis/api/modal';
import store from '../store';

const actSetExploreModal = (data) => ({
  type: Types.SET_EXPLORE_MODAL,
  data,
});

export const actSetIsShowExploreModal = (data) => ({
  type: Types.SET_IS_SHOW_EXPLORE_MODAL,
  data,
});

export const actSetExploreModalId = (data) => ({
  type: Types.SET_EXPLORE_MODAL_ID,
  data,
})

export const reqSetExploreModal = (id) => (dispatch) => {
  dispatch(actSetExploreModalId(id));
  if (!id) return dispatch(actSetExploreModal(''));

  return modalApi
    .getModalDetail(id)
    .then((data) => {
      const state = store.getState();
      if (state.exploreModal.modalId == id) {
        dispatch(actSetExploreModal(data));
      }
    })
};

export const reqSetIsShowExploreModal = (data) => async (dispatch) => {
  if (!data) await dispatch(actSetExploreModal(''));
  return dispatch(actSetIsShowExploreModal(data));
}
