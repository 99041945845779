import React, { useEffect } from 'react';
import './style.scss';
import { PAGES, WEBSOCKET_CHANNEL } from '../../constants/options';
import { useState } from 'react';
import LoadingIcon from '../loading-icon';
import SlideLoading from 'react-slick';
import { useDispatch } from 'react-redux';
import { reqSetPage } from '../../reduxs/home/action';
import Cube from '../cube';
import socket, { emitUIActionEvent } from '../../helper/socket';
import { ACTION_NAME } from '../../constants/options';
import { useSelector } from 'react-redux';
import IntroSlide from './intro-slide';
import BottomNavigation from '../bottom-navigation';

const Loading = (props) => {
  const { isLoading, setIsIntroduction, resetState } = props;
  const [isShowSlide, setShowSlide] = useState(false);
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.user.data);
  const isPresentation = useSelector((state) => state.home.isPresentation);
  const isShowBottomNav = useSelector((state) => state.home.isShowBottomNav);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CLICK_START_DISCOVER) {
          startDiscover();
        }
        if (content.action === ACTION_NAME.SHOW_INTRO_SLIDE) {
          handleShowIntroSlide();
        }
      });
    }
  }, [isPresentation]);

  const startDiscover = () => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.CLICK_START_DISCOVER);
    }
    dispatch(reqSetPage(PAGES.ANIMATE_PAGE));
    setIsIntroduction(true);
  };

  const handleShowIntroSlide = () => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.SHOW_INTRO_SLIDE);
    }
    setShowSlide(true);
  };

  return (
    <div className="wrap-loading-page">
      <div id="loading-screen">
        <div id="loading-cube-wrapper">
          <Cube draggable={false} disabled={isLoading} onClick={startDiscover} />
        </div>

        <div className={`wrap-intro ${isShowSlide ? 'hide' : 'show'}`}>
          <video
            autoPlay
            muted
            loop
            preload="none"
            id="intro-video"
            style={{ backgroundImage: `url("./uploads/images/poster.jpg")` }}
          >
            <source src="./uploads/images/introduce.mp4" type="video/mp4" />
          </video>
          <div className="position-absolute top-50 start-50 translate-middle intro-content pt-3">
            <div className="wrap-text b-fade-text">
              <figure className="mx-auto" style={{ width: '92px', height: '92px' }}>
                <img src="./images/logo-loader.png" alt="" className="img-fluid" />
              </figure>
              <p />
              <figure className="mx-auto" style={{ width: '492px', height: '163px' }}>
                <img src="./images/StanlyRanch_logo.png" alt="" className="img-fluid" />
              </figure>
            </div>
            <div className="loader-wrapper">
              {isLoading ? (
                <LoadingIcon />
              ) : (
                <div className="wrap-button">
                  <div className="arrow-text mb-2">
                    <svg
                      onClick={() => handleShowIntroSlide()}
                      width="70"
                      height="70"
                      viewBox="0 0 70 70"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="35" cy="35" r="34" stroke="white" strokeWidth="2" />
                      <line
                        className="arrow-head"
                        y1="-1"
                        x2="9.8995"
                        y2="-1"
                        transform="matrix(0.642788 -0.766044 -0.766044 -0.642788 50.3184 40.2917)"
                        stroke="white"
                        strokeWidth="2"
                      />
                      <line
                        className="arrow-head"
                        x1="51.0844"
                        y1="27.0655"
                        x2="57.4477"
                        y2="34.6489"
                        stroke="white"
                        strokeWidth="2"
                      />
                      <line
                        className="arrow-body"
                        x1="13"
                        y1="34"
                        x2="57"
                        y2="34"
                        stroke="white"
                        strokeWidth="2"
                      />
                      <line
                        className="v-line"
                        x1="1"
                        y1="34"
                        x2="1"
                        y2="38"
                        stroke="white"
                        strokeWidth="2"
                      />
                      <line
                        className="h-line"
                        x1="1"
                        y1="100"
                        x2="70"
                        y2="100"
                        stroke="white"
                        strokeWidth="2"
                      />
                    </svg>
                  </div>
                  <span className="mt-3 d-inline-block">DISCOVER</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={`wrap-slide-loading ${isShowSlide ? 'show' : 'hide'}`}>
          <IntroSlide resetState={resetState} />
        </div>
      </div>
      {isShowSlide && isShowBottomNav && <BottomNavigation resetState={resetState} />}
    </div>
  );
};

export default Loading;
