import * as type from './type';

const initialState = {
  customers: [],
  editableCustomer: undefined,
  selectedCustomer: undefined,
  isVisibleCustomerFavorite: false,
  isVisibleCustomerCreate: false,
  deleableCustomer: undefined,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case type.GET_LIST_CUSTOMER:
      return {
        ...state,
        customers: action.data.data,
      };
    case type.SET_EDITABLE_CUSTOMER:
      return {
        ...state,
        editableCustomer: action.data.data,
      };
    case type.SET_SELECTED_CUSTOMER:
      return {
        ...state,
        selectedCustomer: action.data.data,
      };
    case type.SET_IS_VISIBLE_CUSTOMER_FAVORITE:
      return {
        ...state,
        isVisibleCustomerFavorite: action.data,
      };
    case type.SET_IS_VISIBLE_CUSTOMER_CREATE:
      return {
        ...state,
        isVisibleCustomerCreate: action.data,
      };
    case type.SET_DELEABLE_CUSTOMER:
      return {
        ...state,
        deleableCustomer: action.data,
      };
    default:
      return state;
  }
};
