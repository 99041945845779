import * as Types from './type';
import customerApi from '../../apis/api/customer';

const actGetListCustomer = (data) => ({
  type: Types.GET_LIST_CUSTOMER,
  data,
});

const actSetEditableCustomer = (data) => ({
  type: Types.SET_EDITABLE_CUSTOMER,
  data,
});

const actSetSelectedCustomer = (data) => ({
  type: Types.SET_SELECTED_CUSTOMER,
  data,
});

const actSetIsVisibleCustomerFavorite = (data) => ({
  type: Types.SET_IS_VISIBLE_CUSTOMER_FAVORITE,
  data,
});

const actSetIsVisibleCustomerCreate = (data) => ({
  type: Types.SET_IS_VISIBLE_CUSTOMER_CREATE,
  data,
});

const actSetDeleableCustomer = (data) => ({
  type: Types.SET_DELEABLE_CUSTOMER,
  data,
});

export const reqGetListCutomer = (query) => (dispatch) => {
  return customerApi
    .getCustomerList(query)
    .then((data) => {
      dispatch(actGetListCustomer(data));
    })
    .catch(() => {
    });
};

export const reqSetEditableCustomer = (id) => (dispatch) => {
  if (!id) return dispatch(actSetEditableCustomer(''));

  return customerApi
    .getCustomerDetail(id)
    .then((data) => {
      dispatch(actSetEditableCustomer(data));
    })
    .catch(() => {
    });
};

export const reqSetSelectedCustomer = (id) => (dispatch) => {
  if (!id) return dispatch(actSetSelectedCustomer(''));

  return customerApi
    .getCustomerDetail(id)
    .then((data) => {
      dispatch(actSetSelectedCustomer(data));
    })
    .catch(() => {
    });
};

export const reqSetIsVisibleCustomerFavorite = (data) => (dispatch) => {
  return dispatch(actSetIsVisibleCustomerFavorite(data));
};

export const reqSetIsVisibleCustomerCreate = (data) => (dispatch) => {
  return dispatch(actSetIsVisibleCustomerCreate(data));
};

export const reqSetDeleableCustomer = (data) => (dispatch) => {
  return dispatch(actSetDeleableCustomer(data));
};
