import React from 'react';
import SideNav from '../sidenav';
import './index.scss';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import * as unitExploreAct from '../../reduxs/unit-explore/action';
import { reqSetActiveGalleryId } from '../../reduxs/home/action';
import socket, { emitUIActionEvent } from '../../helper/socket';
import { useEffect } from 'react';
import { ACTION_NAME, WEBSOCKET_CHANNEL } from '../../constants/options';

const UnitDetail = () => {
  const dispatch = useDispatch();
  const isShowFloorplan = useSelector((state) => state.unitExplore.isShowFloorplan);
  const isShowGallery = useSelector((state) => state.unitExplore.isShowGallery);
  const unit = useSelector((state) => state.unitExplore.selectedUnit);
  const isPresentation = useSelector((state) => state.home.isPresentation);
  const authUser = useSelector((state) => state.user.data);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.SHOW_UNIT_FLOORPLAN) {
          handleShowFloorplan();
        }

        if (content.action === ACTION_NAME.CLOSE_UNIT_FLOORPLAN) {
          handleCloseFloorplan();
        }

        if (content.action === ACTION_NAME.SHOW_UNIT_GALLERY) {
          handleShowGallery();
        }

        if (content.action === ACTION_NAME.CLOSE_UNIT_GALLERY) {
          handleCloseGallery();
        }
      });
    }
  }, [])

  const handleShowFloorplan = () => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.SHOW_UNIT_FLOORPLAN, {});
    }
    dispatch(unitExploreAct.reqIsShowGallery(false));
    dispatch(unitExploreAct.reqIsShowFloorplan(true));
  };

  const handleCloseFloorplan = () => {
    
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.CLOSE_UNIT_FLOORPLAN, {});
    }
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    dispatch(unitExploreAct.reqSetActiveFloorplan(0));
  }

  const handleShowGallery = () => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.SHOW_UNIT_GALLERY, {});
    }
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    dispatch(reqSetActiveGalleryId(unit?.gallery?.id));
    dispatch(unitExploreAct.reqIsShowGallery(!isShowGallery));
  };

  const handleCloseGallery = () => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.CLOSE_UNIT_GALLERY, {});
    }
    dispatch(unitExploreAct.reqIsShowGallery(false));
  };

  const renderBtnFloorplan = () => {
    if (isShowFloorplan) {
      return (
        <li onClick={() => !isPresentation && handleCloseFloorplan()} className="list-group-item">Close Floorplan</li>
      )
    }

    return (
      <li onClick={() => !isPresentation && handleShowFloorplan()} className="list-group-item">View Floorplan</li>
    )
  }

  const renderBtnGallery = () => {
    // return (
    //   <div/>
    // );
    if (isShowGallery) {
      return (
        <li onClick={() => !isPresentation && handleCloseGallery()} className="list-group-item py-2">Close Gallery</li>
      )
    }

    return (
      <li onClick={() => !isPresentation && handleShowGallery()} className="list-group-item py-2">View Gallery</li>
    )
  }

  const formatMoney = (price) => {
    if (!price) return 0;

    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumSignificantDigits: Math.trunc(Math.abs(price)).toFixed().length,
    });

    return formatter.format(price);
  }

  return (
    <SideNav position="right" isDark={true} id="sidenavDetail" heading={unit?.floor_plan?.name}>
      <ul className="list-group">
        <li className="list-group-item d-flex justify-content-between px-2">
          <span className="me-auto">Bedrooms</span>
          <b>{unit?.features?.bedrooms}</b>
        </li>
        <li className="list-group-item d-flex justify-content-between px-2">
          <span className="me-auto">Bathrooms</span>
          <b>{unit?.features?.bathrooms}</b>
        </li>
        <li className="list-group-item d-flex justify-content-between px-2">
          <span className="me-auto">Interior sqft</span>
          <b>{unit?.totalHome}</b>
        </li>
        <li className="list-group-item d-flex justify-content-between px-2">
          <span className="me-auto">Outdoor sqft</span>
          <b>{unit?.outdoorArea}</b>
        </li>
        <li className="list-group-item d-flex justify-content-between px-2 mb-4">
          <span className="me-auto">Total Livable sqft</span>
          <b>{unit?.totalLot}</b>
        </li>
      </ul>

      <div className="wrap-btn-detail mt-auto">
        <ul className="list-group mb-0">
          {renderBtnFloorplan()}
          {renderBtnGallery()}
        </ul>
      </div>
    </SideNav>
  );
};

export default UnitDetail;

/*
<li className="list-group-item px-2">
          <span className="me-auto">Price</span><br/>
          <b>{formatMoney(unit?.price)}</b>
        </li>
*/
