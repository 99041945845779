import React from "react";
import ImageBlock from './ImageBlock';
import ImageBlockFloorplan from './ImageBlockFloorplan';
import UnitDescription from "./UnitDescription";
import LightBoxGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

const FirstUnitDetailStyle = (props) => {
  const { unit } = props;
  const floorPlan = unit?.floor_plan;
  const gallery = unit?.gallery;

  const getGalleryImage = (index = 0) => {
    return gallery?.media[index]?.path;
  }

  const getFloorplanImage = (index = 0) => {
    return floorPlan?.media[index]?.path;
  }

  return (
    <section className="py-5">
      <div className="container">
        <LightBoxGallery
          onInit={() => {}}
          speed={500}
          plugins={[lgThumbnail, lgZoom]}
          addClass={'light-gallery-item'}
          selector={'.light-gallery-item'}
        >
          <div className="row py-2 align-items-end">
            <div className="col">
              <ImageBlock imgSrc={getGalleryImage()} />
            </div>
            <ImageBlockFloorplan imgSrc={getFloorplanImage()} />
            <div className="col mb-lg-5">
              <UnitDescription unit={unit} />
            </div>
          </div>
          <div className="row py-2">
            <div className="col-md-4">
              <ImageBlock imgSrc={getGalleryImage(1)} />
            </div>
            <div className="col-md-4">
              <ImageBlock imgSrc={getFloorplanImage(1)} />
            </div>
          </div>
        </LightBoxGallery>
      </div>
    </section>
  )
}

export default FirstUnitDetailStyle;
