import * as type from './type';

const initialState = {
  properties: [],
  editableProperty: undefined,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case type.GET_LIST_PROPERTY:
      return {
        ...state,
        properties: action.data.data,
      };
    case type.SET_EDITABLE_PROPERTY:
      return {
        ...state,
        editableProperty: action.data.data,
      };
    default:
      return state;
  }
};
