import ApiClient from '../apiClient';
import { defaultFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';
import apiCaller, { apiMethod } from '../utils/apiCaller';

const client = new ApiClient(defaultFetcher);

const getCustomerList = (data) => client.get(endPoint.CUSTOMER_LIST, data);
const getCustomerDetail = (id, data) =>
  client.get(endPoint.CUSTOMER_DETAIL.replace(':id', id), data);
const createCustomer = (data) => apiCaller.callApi(endPoint.CUSTOMER_CREATE, apiMethod.POST, data);
const updateCustomer = (id, data) => client.put(endPoint.CUSTOMER_UPDATE.replace(':id', id), data);
const deleteCustomer = (id, data) =>
  client.delete(endPoint.CUSTOMER_DELETE.replace(':id', id), data);

export default {
  getCustomerList,
  getCustomerDetail,
  createCustomer,
  updateCustomer,
  deleteCustomer,
};
