import React, { useState } from 'react';
import './index.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import unitApi from '../../apis/api/unit';
import amenityApi from '../../apis/api/amenities';
import poiApi from '../../apis/api/poi';
import { ACTION_NAME, PAGES, WEBSOCKET_CHANNEL } from '../../constants/options';
import { useDispatch } from 'react-redux';
import {
  reqSetIsShowModal,
  reqSetPoiModal,
  reqSetActivePoi,
  reqSetDefaultActiveKey,
} from '../../reduxs/locations/action';
import { reqSetPage } from '../../reduxs/home/action';
import { useSelector } from 'react-redux';
import socket, { emitUIActionEvent } from '../../helper/socket';
library.add(faSearch);

const Search = (props) => {
  const { handleUnitClick, handleAmenityItemClick } = props;
  const [amenities, setAmenities] = useState([]);
  const [units, setUnits] = useState([]);
  const [keyword, setKeyword] = useState();
  const [pois, setPois] = useState();
  const authUser = useSelector((state) => state.user.data);
  const isPresentation = useSelector((state) => state.home.isPresentation);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CLICK_SEARCH_UNIT) {
          onClickUnit(content.data.item);
        }
        if (content.action === ACTION_NAME.CLICK_SEARCH_AMENITY) {
          onClickAmenity(content.data.item);
        }
        if (content.action === ACTION_NAME.CLICK_SEARCH_POI) {
          onClickPoi(content.data.item);
        }
      });
    }
  }, [isPresentation]);

  useEffect(async () => {
    const data = {
      'name[regex]': keyword,
    };
    const unitRes = await unitApi.getUnitList(data);
    setUnits(unitRes.data);

    const amenityRes = await amenityApi.getAmenitiesList(data);
    setAmenities(amenityRes.data);

    const poiRes = await poiApi.getPoisList(data);
    setPois(poiRes.data);
  }, [keyword]);

  const onClickPoi = (poi) => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.CLICK_SEARCH_POI, {
        item: poi,
      });
    }
    dispatch(reqSetPage(PAGES.LOCATION_PAGE));
    dispatch(reqSetPoiModal(poi?.modal?.content));
    dispatch(reqSetActivePoi(poi.id));
    dispatch(reqSetIsShowModal(true));
    dispatch(reqSetDefaultActiveKey(poi.group));
  };

  const scroll = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 250);
  };

  const onClickUnit = (item) => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.CLICK_SEARCH_UNIT, {
        item,
      });
    }
    handleUnitClick(item);
    dispatch(reqSetPage(PAGES.UNIT_EXPLORER_PAGE));
  };

  const onClickAmenity = (item) => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.CLICK_SEARCH_AMENITY, {
        item,
      });
    }
    handleAmenityItemClick(item.id);
    dispatch(reqSetPage(PAGES.AMENITY_PAGE));
  };

  const onChangeKeyWord = (e) => {
    setKeyword(e.target.value);
    if (e.target.value == '' || !e.target.value) {
      document.getElementById('searchInput').blur();
    }
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1);
  };

  return (
    <>
      <div className={`wrap-search-component ${isPresentation ? 'invisible' : ''}`}>
        {keyword && (
          <div className="search-result side-card left-card">
            <h5 className="card-title">Search result</h5>
            <div className="card-body">
              <ul className="list-group">
                {!units?.length && !amenities.length && !pois.length && (
                  <li className="list-group-item">No result</li>
                )}
                {(units || []).map((item, index) => (
                  <li key={index} className="list-group-item" onClick={() => onClickUnit(item)}>
                    <span className="wrap-item d-flex">
                      <span className="item-name">{item.name}</span>
                      <span className="item-type">Unit</span>
                    </span>
                  </li>
                ))}
                {(amenities || []).map((item, index) => (
                  <li key={index} className="list-group-item" onClick={() => onClickAmenity(item)}>
                    <span className="wrap-item d-flex">
                      <span className="item-name">{item.name}</span>
                      <span className="item-type">Amenity</span>
                    </span>
                  </li>
                ))}
                {(pois || []).map((item, index) => (
                  <li key={index} className="list-group-item" onClick={() => onClickPoi(item)}>
                    <span className="wrap-item d-flex">
                      <span className="item-name">{item.name}</span>
                      <span className="item-type">POI</span>
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        <div className="search-input">
          <div onClick={scroll} className="input-group">
            <input
              id="searchInput"
              onChange={(e) => onChangeKeyWord(e)}
              type="text"
              className="form-control"
              placeholder="Search..."
              // onFocus={() => {
              //   setTimeout(() => {
              //     window.scrollTo(0, 0);
              //   }, 20);
              // }}
            />
            <FontAwesomeIcon className="search-icon" icon={['fas', 'search']} size="2x" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Search;
