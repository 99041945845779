import React, { useEffect, useRef } from 'react';
import SideNav from '../sidenav';
import { useSelector } from 'react-redux';

const UnitList = (props) => {
  const { handleUnitClick } = props;
  const refs = useRef({});
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const units = useSelector((state) => state.unitExplore.units);

  useEffect(() => {
    if (selectedUnit && refs.current[selectedUnit.id]) {
      refs.current[selectedUnit.id].scrollIntoView();
    }
  }, [selectedUnit]);

  useEffect(() => {
    if (selectedUnit && refs.current[selectedUnit.id]) {
      refs.current[selectedUnit.id].scrollIntoView();
    }
  }, [units]);

  return (
    <SideNav heading="lots" position="left" id="lots" isDark={true}>
      <ul className="list-group">
        {units.map((item, key) => {
          return (
            <li
              className={`list-group-item ${selectedUnit?.id == item.id ? 'active' : ''}`}
              key={key}
              onClick={() => handleUnitClick(item)}
              ref={(ref) => (refs.current[item.id] = ref)}
            >
              {item.name}
            </li>
          );
        })}
      </ul>
    </SideNav>
  );
};

export default UnitList;
