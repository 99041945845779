class LifxAuth {
  appendToken(opts = {}) {
    const optHeaders = opts.headers || {};
    return {
      ...opts,
      headers: {
        ...optHeaders,
        Authorization: `Bearer cd8aa9ed75b5e5f3bf1f6043f9b9eec7420429064f842c57a24d24289fd33cc0`,
      },
    };
  }

  getFetchFn() {
    return (url, options) => fetch(url, this.appendToken(options));
  }
}

export default new LifxAuth();
