import * as type from './type';

const initialState = {
  units: [],
  unitAreas: [],
  selectedUnit: '',
  filterUnitArea: '',
  filterUnitStatus: '',
  filterUnitFloorplanName: '',
  filterUnitFloorplanCode: '',
  filterUnitEnclave: [],
  filterUnitPrice: '',
  filterUnitSquareFootage: '',
  filterUnitBedroom: [],
  filterUnitSize: '',
  filterUnitParkingStall: [],
  isShowFloorplan: false,
  isShowGallery: false,
  isShowUnitDetail: false,
  unitQuery: {},
  isShowFilter: true,
  activeFloorplan: 0,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case type.GET_UNIT_LIST:
      return {
        ...state,
        units: action.data.data,
      };
    case type.GET_UNIT_AREA_LIST:
      return {
        ...state,
        unitAreas: action.data.data,
      };
    case type.FILTER_UNIT_AREA:
      return {
        ...state,
        filterUnitArea: action.data,
      };
    case type.FILTER_UNIT_STATUS:
      return {
        ...state,
        filterUnitStatus: action.data,
      };
    case type.FILTER_UNIT_FLOORPLAN_NAME:
      return {
        ...state,
        filterUnitFloorplanName: action.data,
      };
    case type.FILTER_UNIT_FLOORPLAN_CODE:
      return {
        ...state,
        filterUnitFloorplanCode: action.data,
      };
    case type.FILTER_UNIT_ENCLAVE:
      return {
        ...state,
        filterUnitEnclave: action.data,
      };
    case type.FILTER_UNIT_PRICE:
      return {
        ...state,
        filterUnitPrice: action.data,
      };
    case type.FILTER_UNIT_SQUARE_FOOTAGE:
      return {
        ...state,
        filterUnitSquareFootage: action.data,
      };
    case type.FILTER_UNIT_PARKING_STALL:
      return {
        ...state,
        filterUnitParkingStall: action.data,
      };
    case type.FILTER_UNIT_BEDROOM:
      return {
        ...state,
        filterUnitBedroom: action.data,
      };
    case type.FILTER_UNIT_SIZE:
      return {
        ...state,
        filterUnitSize: action.data,
      };
    case type.SET_IS_SHOW_FLOORPLAN:
      return {
        ...state,
        isShowFloorplan: action.data,
      };
    case type.SET_IS_SHOW_GALLERY:
      return {
        ...state,
        isShowGallery: action.data,
      };
    case type.SET_IS_SHOW_UNIT_DETAIL:
      return {
        ...state,
        isShowUnitDetail: action.data,
      };
    case type.SET_SELECTED_UNIT:
      return {
        ...state,
        selectedUnit: action?.data?.data,
      };
    case type.SET_UNIT_QUERY:
      return {
        ...state,
        unitQuery: action.data,
      };
    case type.SET_IS_SHOW_FILTER:
      return {
        ...state,
        isShowFilter: action.data,
      };
    case type.SET_ACTIVE_FLOORPLAN:
      return {
        ...state,
        activeFloorplan: action.data,
      };
    default:
      return state;
  }
};
