import * as Types from './type';
import unitApi from '../../apis/api/unit';

const actGetListProperty = (data) => ({
  type: Types.GET_LIST_PROPERTY,
  data,
});

const actSetEditableProperty = (data) => ({
  type: Types.SET_EDITABLE_PROPERTY,
  data,
});

export const reqGetListProperty = (query) => (dispatch) => {
  return unitApi
    .getUnitList(query)
    .then((data) => {
      dispatch(actGetListProperty(data));
    })
    .catch(() => {
    });
};

export const reqSetEditableProperty = (id) => (dispatch) => {
  if (!id) return dispatch(actSetEditableProperty(''));

  return unitApi
    .getUnitDetail(id)
    .then((data) => {
      dispatch(actSetEditableProperty(data));
    })
    .catch(() => {
    });
};
