import * as type from './type';
import { PAGES } from '../../constants/options';

const initialState = {
  page: PAGES.ONBOARD_PAGE,
  isShowBottomNav: true,
  isShowImmerse: false,
  isExpandNav: false,
  activePanotourId: '',
  isShowExploreModal: false,
  isTransparent: false,
  activeGalleryId: '',
  isShowStartSessionModal: false,
  isShowCustomerDetailModal: false,
  isShowCreateCustomerModal: false,
  isPresentation: false,
  activePanoName: ''
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case type.SET_PAGE:
      return {
        ...state,
        page: action.data,
      };
    case type.SET_PANO_NAME:
      return {
        ...state,
        activePanoName: action.data
      };
    case type.SET_IS_SHOW_BOTTOM_NAV:
      return {
        ...state,
        isShowBottomNav: action.data,
      };
    case type.SET_IS_SHOW_IMMERSE:
      return {
        ...state,
        isShowImmerse: action.data,
      };
    case type.SET_IS_EXPAND_NAV:
      return {
        ...state,
        isExpandNav: action.data,
      };
    case type.SET_ACTIVE_PANTOUR_ID:
      return {
        ...state,
        activePanotourId: action.data,
      };
    case type.SET_IS_SHOW_EXPLORE_MODAL:
      return {
        ...state,
        isShowExploreModal: action.data,
      };
    case type.SET_IS_TRANSPARENT:
      return {
        ...state,
        isTransparent: action.data,
      };
    case type.SET_ACTIVE_GALLERY_ID:
      return {
        ...state,
        activeGalleryId: action.data,
      }
    case type.SET_IS_SHOW_START_SESSION_MODAL:
      return {
        ...state,
        isShowStartSessionModal: action.data,
      }
    case type.SET_IS_SHOW_CUSTOMER_DETAIL_MODAL:
      return {
        ...state,
        isShowCustomerDetailModal: action.data,
      }
    case type.SET_IS_SHOW_CREATE_CUSTOMER_MODAL:
      return {
        ...state,
        isShowCreateCustomerModal: action.data,
      }
    case type.SET_IS_PRESENTATION:
      return {
        ...state,
        isPresentation: action.data,
      }
    default:
      return state;
  }
};
