import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import BottomNav from '../../components/bottom-navigation';
import CreateCustomerModal from '../../components/sessions/create-customer-modal';
import StartSessionModal from '../../components/sessions/start-session-modal';
import CustomerDetailModal from '../../components/sessions/customer-detail-modal';
import './index.scss';
import { reqGetUserProfile } from '../../reduxs/user/action';
import Cube from '../../components/cube';

const HoldingPage = (props) => {
  const { roles, authMiddleware } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const isShowStartSessionModal = useSelector((state) => state.home.isShowStartSessionModal);
  const isShowCustomerDetailModal = useSelector((state) => state.home.isShowCustomerDetailModal);
  const isShowCreateCustomerModal = useSelector((state) => state.home.isShowCreateCustomerModal);

  useEffect(() => {
    dispatch(reqGetUserProfile());
    authMiddleware(roles, history);
  }, []);

  return (
    <div className="wrap-holding-page h-100" style={{backgroundImage: 'url("/images/bg.jpg")', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
      <div className={`wrap-intro show`}>
        <div className="position-absolute top-50 start-50 translate-middle intro-content pt-3">
          <div className="wrap-text b-fade-text text-center">
            <figure className="mx-auto" style={{ width:"92px", height:"92px"}}>
              <img src="./images/logo-loader.png" alt="" className="img-fluid" />
            </figure>
            <p/>
            <figure className="mx-auto" style={{ width: "492px", height:"163px"}}>
              <img src="./images/StanlyRanch_logo.png" alt="" className="img-fluid" />
            </figure>
          </div>
        </div>
      </div>
      <BottomNav isHideEye={true} />
      {
        isShowStartSessionModal && <StartSessionModal />
      }
      {
        isShowCustomerDetailModal && <CustomerDetailModal />
      }
      {
        isShowCreateCustomerModal && <CreateCustomerModal />
      }
    </div>
  );
};

export default HoldingPage;
