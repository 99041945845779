import ApiClient from '../apiClient';
import { authFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(authFetcher);

const getPoisList = (data) => client.get(endPoint.POI_GET_LIST, data);
const getPoiDetail = (poiId, data) =>
  client.get(endPoint.POI_GET_DETAIL.replace(':poiId', poiId), data);

export default {
  getPoisList,
  getPoiDetail,
};
