import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  AVAILABILITY_STATUS_OPTION,
  PARKING_STALLS,
  ACTION_NAME,
  FLOORPLAN_NAME_OPTION,
  FLOORPLAN_CODE_OPTION,
  ENCLAVE_OPTION,
  PRICE_OPTION,
  SQUARE_FOOTAGE_OPTION,
  WEBSOCKET_CHANNEL,
} from '../../constants/options';
import SideNav from '../sidenav';
import * as unitExploreAct from '../../reduxs/unit-explore/action';
import socket, { emitUIActionEvent } from '../../helper/socket';
import { Accordion, AccordionContext, useAccordionToggle } from 'react-bootstrap';

const UnitFilter = (props) => {
  const {
    handleAreaClick,
  } = props;
  const dispatch = useDispatch();
  const unitAreas = useSelector((state) => state.unitExplore.unitAreas);
  const filterUnitArea = useSelector((state) => state.unitExplore.filterUnitArea);
  const filterUnitStatus = useSelector((state) => state.unitExplore.filterUnitStatus);
  const filterUnitFloorplanName = useSelector((state) => state.unitExplore.filterUnitFloorplanName);
  const filterUnitFloorplanCode = useSelector((state) => state.unitExplore.filterUnitFloorplanCode);
  const filterUnitPrice = useSelector((state) => state.unitExplore.filterUnitPrice);
  const filterUnitParkingStall = useSelector((state) => state.unitExplore.filterUnitParkingStall);
  const filterUnitSize = useSelector((state) => state.unitExplore.filterUnitSize);
  const filterUnitEnclave = useSelector((state) => state.unitExplore.filterUnitEnclave);

  const isPresentation = useSelector((state) => state.home.isPresentation);
  const authUser = useSelector((state) => state.user.data);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({content}) => {
        if (content.action === ACTION_NAME.CLICK_UNIT_FILTER) {
          handleApplyFilter(content.data);
        } else if (content.action == ACTION_NAME.CLEAR_ALL_FILTERS) {
          onResetAllFilter();
        }
      })
    }

  }, [isPresentation]);

  const handleApplyFilter = (data) => {
    const filterName = data.name;
    const item = data.item;

    switch (filterName) {
      case 'AVAILABILITY_STATUS_OPTION':
        onChangeStatus(item, data.filterUnitStatus);
        break;
      case 'AREA_OPTION':
        onChangeArea(item, data.filterUnitArea);
        break;
      case 'SIZE_OPTION':
        onChangeSize(item, data.filterUnitSize);
        break;
      case 'PARKING_STALL_OPTION':
        onChangeParkingStall(item, data.filterUnitParkingStall);
        break;
      case 'PRICE_OPTION':
        onChangePrice(item, data.filterUnitPrice);
        break;
      case 'FLOORPLAN_NAME_OPTION':
        onChangeFloorplanName(item, data.filterUnitFloorplanName);
        break;
      case 'FLOORPLAN_CODE_OPTION':
        onChangeFloorplanCode(item, data.filterUnitFloorplanCode);
        break;
      case 'ENCLAVE_OPTION':
        onChangeFloorplanCode(item, data.filterUnitEnclave);
        break;
      default:
        break;
    }
  }

  const onChangeStatus = (item, filterUnitStatus) => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.CLICK_UNIT_FILTER, {
        item: item,
        filterUnitStatus,
        name: 'AVAILABILITY_STATUS_OPTION',
      });
    }
    dispatch(unitExploreAct.reqSetSelectedUnit(''));
    const status = filterUnitStatus == item.value ? '' : item.value;
    dispatch(unitExploreAct.reqFilterUnitStatus(status));
  };

  const onChangeBedroom = (item) => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.CLICK_UNIT_FILTER, {
        item: item,
        name: 'BEDROOM_OPTION',
      });
    }
    dispatch(unitExploreAct.reqSetSelectedUnit(''));
    const bedroom = filterUnitBedroom.includes(item.value)
    ? filterUnitBedroom.filter((i) => i !== item.value)
    : [...filterUnitBedroom, item.value];
    dispatch(unitExploreAct.reqFilterUnitBedroom(bedroom));
  };

  const onChangeArea = (item, filterUnitArea) => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.CLICK_UNIT_FILTER, {
        item: item,
        filterUnitArea,
        name: 'AREA_OPTION',
      });
    }
    dispatch(unitExploreAct.reqSetSelectedUnit(''));
    handleAreaClick(item);
    const area = filterUnitArea == item.id ? '' : item.id;
    dispatch(unitExploreAct.reqFilterUnitArea(area));
  };

  const onChangeSize = (item, filterUnitSize) => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.CLICK_UNIT_FILTER, {
        item: item,
        filterUnitSize,
        name: 'SIZE_OPTION',
      });
    }
    dispatch(unitExploreAct.reqSetSelectedUnit(''));
    const size = filterUnitSize == item.id ? '' : item.id;
    dispatch(unitExploreAct.reqFilterUnitSize(size));
  };

  const onChangeParkingStall = (item, filterUnitParkingStall) => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.CLICK_UNIT_FILTER, {
        item: item,
        filterUnitParkingStall,
        name: 'PARKING_STALL_OPTION',
      });
    }
    dispatch(unitExploreAct.reqSetSelectedUnit(''));
    const parkingStall = filterUnitParkingStall.includes(item.value)
      ? filterUnitParkingStall.filter((i) => i !== item.value)
      : [...filterUnitParkingStall, item.value];
    dispatch(unitExploreAct.reqFilterUnitParkingStall(parkingStall));
  };

  const onChangePrice = (item, filterUnitPrice) => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.CLICK_UNIT_FILTER, {
        item: item,
        filterUnitPrice,
        name: 'PRICE_OPTION',
      });
    }
    dispatch(unitExploreAct.reqSetSelectedUnit(''));
    const priceId = filterUnitPrice == item.id ? '' : item.id;
    dispatch(unitExploreAct.reqFilterUnitPrice(priceId));
  }

  const onChangeFloorplanName = (item, filterUnitFloorplanName) => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.CLICK_UNIT_FILTER, {
        item: item,
        filterUnitFloorplanName,
        name: 'FLOORPLAN_NAME_OPTION',
      });
    }
    dispatch(unitExploreAct.reqSetSelectedUnit(''));
    const floorplanName = filterUnitFloorplanName == item.value ? '' : item.value;
    dispatch(unitExploreAct.reqFilterUnitFloorplanName(floorplanName));
  }

  const onChangeFloorplanCode = (item, filterUnitFloorplanCode) => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.CLICK_UNIT_FILTER, {
        item: item,
        filterUnitFloorplanCode,
        name: 'FLOORPLAN_CODE_OPTION',
      });
    }
    dispatch(unitExploreAct.reqSetSelectedUnit(''));
    const floorplanCode = filterUnitFloorplanCode == item.value ? '' : item.value;
    dispatch(unitExploreAct.reqFilterUnitFloorplanCode(floorplanCode));
  }

  const onChangeEnclave = (item, filterUnitEnclave) => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.CLICK_UNIT_FILTER, {
        item: item,
        filterUnitEnclave,
        name: 'ENCLAVE_OPTION',
      });
    }
    dispatch(unitExploreAct.reqSetSelectedUnit(''));
    const enclave = filterUnitEnclave.includes(item.value)
      ? filterUnitEnclave.filter((i) => i !== item.value)
      : [...filterUnitEnclave, item.value];
    dispatch(unitExploreAct.reqFilterUnitEnclave(enclave));
  };

  const onResetAllFilter = () => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.CLEAR_ALL_FILTERS, {});
    }
    dispatch(unitExploreAct.reqFilterUnitArea());
    dispatch(unitExploreAct.reqFilterUnitEnclave([]));
    dispatch(unitExploreAct.reqFilterUnitFloorplanCode());
    dispatch(unitExploreAct.reqFilterUnitFloorplanName());
    dispatch(unitExploreAct.reqFilterUnitParkingStall([]));
    dispatch(unitExploreAct.reqFilterUnitSquareFootage());
    dispatch(unitExploreAct.reqFilterUnitStatus());
    dispatch(unitExploreAct.reqFilterUnitSize());
    dispatch(unitExploreAct.reqFilterUnitPrice());

  }

  function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <button
        type="button"
        className={`list-group-item list-group-heading ${ isCurrentEventKey && 'open' }`}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  return (
    <SideNav
      position="left"
      heading="Filter"
      id="filter"
      isDark={true}
    >
      {/* filter house type */}
      <ul className="list-group mb-4">
        {unitAreas.map((item, index) => {
          return (
            <li
              key={index}
              onClick={() => !isPresentation && onChangeArea(item, filterUnitArea)}
              className={`list-group-item ${item.id == filterUnitArea ? 'active' : ''}`}
            >
              {item.name}
            </li>
          );
        })}
      </ul>

      <Accordion defaultActiveKey="accordion-filter" className="accordion-filter">
        <div key={1}>
          <ContextAwareToggle eventKey={1}>STATUS</ContextAwareToggle>
          <Accordion.Collapse eventKey={1}>
            <ul className={`list-group flex-wrap`}>
              {AVAILABILITY_STATUS_OPTION.map((item, key) =>
                <li onClick={() => !isPresentation && onChangeStatus(item, filterUnitStatus)} className={`list-group-item ${(item.value === filterUnitStatus) && 'active'}`} key={`filter-item-${key}`}>{ item.title }</li>
              )}
            </ul>
          </Accordion.Collapse>
        </div>
        <div key={2}>
          <ContextAwareToggle eventKey={2}>FLOORPLAN NAME</ContextAwareToggle>
          <Accordion.Collapse eventKey={2}>
            <ul className={`list-group flex-wrap`}>
              {FLOORPLAN_NAME_OPTION.map((item, key) =>
                <li onClick={() => !isPresentation && onChangeFloorplanName(item, filterUnitFloorplanName)} className={`list-group-item ${(item.value === filterUnitFloorplanName) && 'active'}`} key={`filter-item-${key}`}>{ item.title }</li>
              )}
            </ul>
          </Accordion.Collapse>
        </div>
        <div key={6}>
          <ContextAwareToggle eventKey={6}>SQUARE FOOTAGE</ContextAwareToggle>
          <Accordion.Collapse eventKey={6}>
            <ul className={`list-group flex-wrap`}>
              {SQUARE_FOOTAGE_OPTION.map((item, key) =>
                <li onClick={() => !isPresentation && onChangeSize(item, filterUnitSize)} className={`list-group-item ${(item.id === filterUnitSize) && 'active'}`} key={`filter-item-${key}`}>{ item.title }</li>
              )}
            </ul>
          </Accordion.Collapse>
        </div>
      </Accordion>

      <small onClick={() => !isPresentation && onResetAllFilter()} className="list-group-item text-underline opacity-100" role="button">CLEAR ALL FILTERS</small>
    </SideNav>
  );
};

export default UnitFilter;

/*
<div key={3}>
          <ContextAwareToggle eventKey={3}>FLOORPLAN CODE</ContextAwareToggle>
          <Accordion.Collapse eventKey={3}>
            <ul className={`list-group`}>
              {FLOORPLAN_CODE_OPTION.map((item, key) =>
                <li onClick={() => !isPresentation && onChangeFloorplanCode(item, filterUnitFloorplanCode)} className={`list-group-item ${(item.value === filterUnitFloorplanCode) && 'active'}`} key={`filter-item-${key}`}>{ item.title }</li>
              )}
            </ul>
          </Accordion.Collapse>
        </div>
        <div key={4}>
          <ContextAwareToggle eventKey={4}>ENCLAVE</ContextAwareToggle>
          <Accordion.Collapse eventKey={4}>
            <ul className={`list-group list-group-horizontal flex-wrap`}>
              {ENCLAVE_OPTION.map((item, key) =>
                <li onClick={() => !isPresentation && onChangeEnclave(item, filterUnitEnclave)} className={`list-group-item ${(filterUnitEnclave.includes(item.value)) && 'active'}`} key={`filter-item-${key}`}>{ item.title }</li>
              )}
            </ul>
          </Accordion.Collapse>
        </div>
        <div key={5}>
          <ContextAwareToggle eventKey={5}>PRICE</ContextAwareToggle>
          <Accordion.Collapse eventKey={5}>
            <ul className={`list-group flex-wrap`}>
              {PRICE_OPTION.map((item, key) =>
                <li onClick={() => !isPresentation && onChangePrice(item, filterUnitPrice)} className={`list-group-item ${(item.id === filterUnitPrice) && 'active'}`} key={`filter-item-${key}`}>{ item.title }</li>
              )}
            </ul>
          </Accordion.Collapse>
        </div>

        <div key={7}>
          <ContextAwareToggle eventKey={7}>PARKING</ContextAwareToggle>
          <Accordion.Collapse eventKey={7}>
            <ul className={`list-group flex-wrap`}>
              {PARKING_STALLS.map((item, key) =>
                <li onClick={() => !isPresentation && onChangeParkingStall(item, filterUnitParkingStall)} className={`list-group-item ${(filterUnitParkingStall.includes(item.value)) && 'active'}`} key={`filter-item-${key}`}>{ item.title }</li>
              )}
            </ul>
          </Accordion.Collapse>
        </div>

*/
