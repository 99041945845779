import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { reqGetListCutomer, reqSetIsVisibleCustomerCreate } from '../../reduxs/customer/action';
import customerApi from '../../apis/api/customer';
import { toast } from 'react-toastify';

const CustomerCreate = () => {
  const dispatch = useDispatch();
  const isVisible = useSelector((state) => state.customer.isVisibleCustomerCreate);
  const [firstname, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [note, setNote] = useState('');
  const [error, setError] = useState();

  const validattionSchema = yup.object().shape({
    firstname: yup.string().required(),
    surname: yup.string().required(),
    email: yup.string().email().required(),
    mobile: yup.string().notRequired(),
    note: yup.string().notRequired(),
  });

  const handleCreateCustomer = async () => {
    try {
      const data = !!mobile ? {
        firstname: firstname,
        surname: surname,
        email: email,
        mobile: mobile,
        note: note,
      } : {
        firstname: firstname,
        surname: surname,
        email: email,
        note: note,
      };

      await validattionSchema.validate(data);      
      await customerApi.createCustomer(data);

      toast.info('Created customer successfully!');
      dispatch(reqSetIsVisibleCustomerCreate(false));
      dispatch(reqGetListCutomer());
    } catch (err) {
      setError(err);
    }
  };

  return (
    <>
      <div className={`wrap-modal wrap-modal-cms fade-item-enter-done ${isVisible ? '' : 'hidden'}`}>
        <div>
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="f-title">Add new customer</h2>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col">
                  <div className="form-group mb-4">
                    <label htmlFor="ipFirstName" className="form-label">First Name</label>
                    <input value={firstname} onChange={(e) => setFirstName(e.target.value)} id="ipFirstName" type="text" className="form-control" required/>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group mb-4">
                    <label htmlFor="ipSurname" className="form-label">Surname</label>
                    <input value={surname} onChange={(e) => setSurname(e.target.value)} id="ipSurname" type="text" className="form-control" required/>
                  </div>
                </div>
              </div>
              <div className="form-group mb-4">
                <label htmlFor="ipEmail" className="form-label">
                  Email
                  <span className="text-danger ms-1">*</span>
                </label>
                <input value={email} onChange={(e) => setEmail(e.target.value)} id="ipEmail" type="email" className="form-control" required />
              </div>
              <div className="form-group mb-4">
                <label htmlFor="ipPhone" className="form-label">Phone</label>
                <input value={mobile} onChange={(e) => setMobile(e.target.value)} id="ipPhone" type="text" className="form-control" />
              </div>
              <div className="form-group mb-4">
                <textarea value={note} onChange={(e) => setNote(e.target.value)} className="form-control" placeholder="Note" rows="5" />
              </div>
              <small id="user-type-help" className="form-text text-danger">
                {error?.message}
              </small>
            </div>
            <div className="modal-footer d-flex">
              <button
                onClick={() => dispatch(reqSetIsVisibleCustomerCreate(false))}
                className="btn shadow-0 bg-white btn-cancel ms-auto"
              >CANCEL</button>
              <button onClick={() => handleCreateCustomer()} className="btn shadow-0 bg-white pe-0">SAVE</button>
            </div>
          </div>
        </div>
        <div className="close-btn p-5" onClick={() => dispatch(reqSetIsVisibleCustomerCreate(false))}>
          <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="8.57918" y1="8.73918" x2="25.8968" y2="26.0568" stroke="#282A2D" strokeWidth="2" />
            <line x1="25.897" y1="8.5782" x2="8.57943" y2="25.8958" stroke="#282A2D" strokeWidth="2" />
          </svg>
        </div>
      </div>
      <div className={`wrap-modal-backdrop ${isVisible ? '' : 'hidden'}`} />
    </>
  )
}

export default CustomerCreate;
