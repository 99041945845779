import LifxClient from '../lifxClient';
import { lifxAuthFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';
import _ from 'lodash';

const client = new LifxClient(lifxAuthFetcher);

const setState = (data) => {
  const params = {
    brightness: data.brightness,
    power: 'on',
    color: {
      hue: 348.2352941176471,
      saturation: 1,
      kelvin: null,
    },
    fast: true,
  };

  const zones = data.zones[0].join('|');
  const selector = `id:${data.uuid}|${zones}`;

  return client.put(endPoint.LIFX_SET_STATE.replace(':selector', selector), params);
};

const setStates = async (lightGroups) => {
  const defaultConfig = {
    power: 'on',
    color: {
      "hue": 0,
      "saturation": 0,
      "brightness": 1,
      "kelvin": 3500
    },
    selector: '',
  }

  Object.keys(lightGroups).forEach(async (key) => {
    const zoneGroups = _.chunk(lightGroups[key], 10);

    for (let index = 0; index < zoneGroups.length; index++) {
      defaultConfig.selector = `id:${key}|${zoneGroups[index].join('|')}`;

      client.put(endPoint.LIFX_SET_STATES, {
        states: [
          { ...defaultConfig },
        ],
        fast: true,
      });
    }
  })

  return true;
}

const setMultiStates = async (lightGroups) => {
  return client.put(endPoint.LIFX_SET_STATES, {
    states: getLightSelector(lightGroups),
    fast: true,
  })
}

const turnOffAll = async () => {
  const params = {
    brightness: 0,
    power: 'off',
    fast: true,
  };

  return await client.put(endPoint.LIFX_SET_STATE.replace(':selector', 'all'), params);
};

const getLightStates = async () => {
  return client.get(endPoint.LIFX_GET_STATES);
}

const getLightSelector = (lightGroups) => {
  const defaultConfig = {
    power: 'on',
    color: {
      "hue": 0,
      "saturation": 0,
      "brightness": 1,
      "kelvin": 3500
    },
    selector: '',
  }
  const selectors = [];

  for (const key in lightGroups) {
    const zones = lightGroups[key].join('|');
    defaultConfig.selector = `id:${key}|${zones}`

    selectors.push({ ...defaultConfig });
  }

  return selectors;
}

export default {
  setState,
  setStates,
  turnOffAll,
  setMultiStates,
  getLightStates,
};
