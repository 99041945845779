import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { reqGetListCutomer } from '../../reduxs/customer/action';
import { reqSetIsShowCreateCustomerModal, reqSetIsShowCustomerDetailModal, reqSetIsShowStartSessionModal } from '../../reduxs/home/action';
import './start-session.scss';

const StartSessionModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isShowStartSessionModal = useSelector((state) => state.home.isShowStartSessionModal);
  const customers = useSelector((state) => state.customer.customers);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [search, setSearch] = useState('');

  useEffect(() => {
    getListCustomers();
  }, []);

  useEffect(() => {
    getListCustomers();
  }, [search])

  const getListCustomers = () => {
    dispatch(reqGetListCutomer({
      search,
    }));
  }

  const onCloseModal = () => {
    dispatch(reqSetIsShowStartSessionModal(false));
  }

  const onSelectCustomer = (customer) => {
    setSelectedCustomer(customer);

  }

  const onConfirmNoUser = () => {
    dispatch(reqSetIsShowStartSessionModal(false));
    location.href = `/?customer=anonymous`;
    dispatch(reqSetIsShowCustomerDetailModal(false));
  }

  const onConfirmSelect = () => {
    if (!selectedCustomer) return toast.error('Please select customer!');

    dispatch(reqSetIsShowStartSessionModal(false));
    history.push({
      search: `?customer=${selectedCustomer.id}`
    })
    dispatch(reqSetIsShowCustomerDetailModal(true));
  }

  const onOpenCreateCustomerModal = () => {
    dispatch(reqSetIsShowStartSessionModal(false));
    dispatch(reqSetIsShowCreateCustomerModal(true));
  }

  return (
        <div className={`wrap-modal wrap-modal-session ${isShowStartSessionModal ? 'show' : 'hide'}`}>
          <div className="h-100">
            <div className={`modal-form h-100`}>
              <div className="pe-5">
                <h3 className="modal-form__title text-start">SET UP A GUIDED SESSION USING THE STANLY RANCH EXPERIENCE APP</h3>
                <p className="modal-form__subtitle">Select an existing user for today’s session</p>
              </div>
              <div className="modal-form__body">
                <input
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  type="text"
                  className="form-control mb-5"
                  placeholder="Search...."
                />
                <ul className="list-group list-group-flush">
                  {
                    (customers || []).map((customer) => {
                      return (
                        <li
                          onClick={() => onSelectCustomer(customer)}
                          key={customer.id}
                          className={`list-group-item border-0 text-start ${selectedCustomer?.id === customer.id ? 'active' : ''}`}>
                          {customer?.firstname} {customer?.surname}
                        </li>
                      )
                    })
                  }
                </ul>
                <div className="d-flex align-items-center justify-content-between">
                  <button
                    onClick={() => onOpenCreateCustomerModal()}
                    className="btn btn-lg btn-link ps-0"
                    >
                    CREATE NEW USER
                  </button>
                  <button
                    onClick={() => onConfirmNoUser()}
                    className="btn btn-lg btn-link pe-0"
                    >
                    SKIP THIS STEP
                  </button>
                  <button
                    onClick={() => onConfirmSelect()}
                    className="btn btn-lg btn-link pe-0"
                    >
                    SELECT THIS USER
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="close-btn p-4" onClick={() => onCloseModal()}>
            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="8.57918" y1="8.73918" x2="25.8968" y2="26.0568" stroke="#282A2D" strokeWidth="2"/>
              <line x1="25.897" y1="8.5782" x2="8.57943" y2="25.8958" stroke="#282A2D" strokeWidth="2"/>
            </svg>
          </div>
        </div>
  )
}

export default StartSessionModal;
