import React from "react";

const UnitDescription = (props) => {
  const { unit } = props;

  const getExtenalLink = () => {
    if (unit?.area?.name?.toLowerCase() === 'vineyard homes') {
      return 'https://stanlyranchnapa.com/residences/#vineyard'
    }

    return 'https://stanlyranchnapa.com/residences/#villas';
  }

  return (
    <>
      <div className="f-title h3 mb-0">{unit?.address}</div>
      <div className="f-title h5 mb-4">{ unit?.floor_plan?.name}</div>
      <div>
        <p className="mb-0">{unit?.features?.bedrooms} Bedrooms</p>
        <p className="mb-0">{unit?.features?.bathrooms} Bathrooms</p>
        {unit?.features?.parkingStall && <p className="mb-0">{unit?.features?.parkingStall} Parking Stalls</p>}
        <p className="mb-0">Total Lot : {unit?.totalLot} (sqft)</p>
        <p className="mb-3">Total Home : {unit?.totalHome} (sqft)</p>
        <a className="text-underline text-uppercase" style={{ color: '#000' }} href={getExtenalLink()} target="__blank">VIEW {unit?.area.name}</a>
      </div>
    </>
  )
}

export default UnitDescription;
