class JWTAuth {
  setAccessToken = (token) => localStorage.setItem('access_token', token);

  getToken = () => localStorage.getItem('access_token');

  checkToken = () => {
    if (localStorage.getItem('access_token')) {
      return true;
    }
    return false;
  };

  removeToken = () => {
    localStorage.removeItem('access_token');
  };

  appendToken(opts = {}) {
    const optHeaders = opts.headers || {};
    return {
      ...opts,
      headers: {
        ...optHeaders,
        is_web: 1,
        Authorization: `Bearer ${this.getToken()}`,
      },
    };
  }

  getFetchFn() {
    return (url, options) => fetch(url, this.appendToken(options));
  }
}

export default new JWTAuth();
