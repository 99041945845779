import masterData from '../../../constants/master-data';

export const selectOptions = [
  {
    value: masterData.AVAILABILITY_STATUS_ENUM.AVAILABLE_NOW,
    label: 'Available',
  },
  {
    value: masterData.AVAILABILITY_STATUS_ENUM.IN_CONTRACT,
    label: 'In Contract',
  },
  {
    value: masterData.AVAILABILITY_STATUS_ENUM.SOLD,
    label: 'Sold',
  },
  {
    value: masterData.AVAILABILITY_STATUS_ENUM.RELEASING_SOON,
    label: 'Releasing soon',
  },
];

export const selectStyles = {
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? '#D2D2C6' : '#fff',
    color: '#414345',
    padding: '10px 13px',
  }),
  control: () => ({
    borderRadius: 0,
    padding: 5,
    background: '#fff',
    display: 'flex',
  }),
  container: () => ({
    marginTop: -8,
    marginBottom: -8,
    position: 'relative',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorContainer: () => ({
    padding: '0',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 0,
    boxShadow: 'none',
    marginTop: 0,
  }),
};

export const faqQuestion = {
  saleGalleryHardware: [
    {
      question: 'Who do I contact about Internet issues ?',
      answer:
        'Please contact Bear Technology for on site hardware support issues on (925) 634-0787 or gludwig@beartechnology.net',
    },
    {
      question: 'Who do I contact if the Soundbar is not behaving as expected ?',
      answer:
        'Please contact Bear Technology for on site hardware support issues on (925) 634-0787 or gludwig@beartechnology.net',
    },
    {
      question: 'What should I do if a Remote Control is broken or missing ?',
      answer:
        'Please contact Bear Technology for on site hardware support issues on (925) 634-0787 or gludwig@beartechnology.net',
    },
    {
      question: 'Who do I contact about elecrical or wiring issues ?',
      answer:
        'Please contact Bear Technology for on site hardware support issues on (925) 634-0787 or gludwig@beartechnology.net',
    },
    {
      question: 'Who do I contact about Server room hardware issues ?',
      answer:
        'Please contact Bear Technology for on site hardware support issues on (925) 634-0787 or gludwig@beartechnology.net',
    },
    {
      question: 'Who do I contact if a device is reporting software update issues ?',
      answer:
        'Please contact Bear Technology for on site hardware support issues on (925) 634-0787 or gludwig@beartechnology.net',
    },
  ],
  physicalModel: [
    {
      question: "What should I do if the Physical Model lights don't power up ?",
      answer:
        'Please contact Bear Technology for on site hardware support issues on (925) 634-0787 or gludwig@beartechnology.net',
    },
    {
      question: 'What should I do if the Physical Model lights are unresponsive ?',
      answer:
        'Please contact Model Works for Physical Model issues on (310) 322-3550 or amir@modelworksinc.com',
    },
    {
      question: 'Who do I contact about Physical Model damage or issues ?',
      answer:
        'Please contact Model Works for Physical Model issues on (310) 322-3550 or amir@modelworksinc.com',
    },
    {
      question: 'Who do I contact if the Physical Model lights are not behaving as expected ?',
      answer: 'Please contact Binyan using the Support Ticket tab on this page',
    },
    {
      question: 'Who do I contact about damage or issues with the model plinth ?',
      answer:
        'Please contact One Hat One Hand for plinth issues on (415) 822-2020 or info@onehatonehand.com',
    },
  ],
  tvDisplay: [
    {
      question: "What should I do if the TV doesn't power up ?",
      answer:
        'Please contact Bear Technology for on site hardware support issues on (925) 634-0787 or gludwig@beartechnology.net',
    },
    {
      question: 'Who do I contact if the TV is not behaving as expected ?',
      answer:
        'Please contact Bear Technology for on site hardware support issues on (925) 634-0787 or gludwig@beartechnology.net',
    },
    {
      question: 'What should I do if the app is not connecting to the tv/monitor ?',
      answer: {
        suggest: 'Ensure you are logged in with the same user account on both devices',
        option1: '1. Log out of any existing App connections on the Mac Mini in the server room',
        option2: '2. Go to www.abcdefghijklmnop.com/tv and log in as salesgallery@stanly.com',
        option3: '3. Set the Mac Mini browser to full screen',
        option4: '4. Log out of any existing App connections on the iPad',
        option5:
          '5. Go to www.abcdefghijklmnop.com and log in as salesgallery@stanly.com The iPad and TV should now be linked and the iPad will control the TV screen content',
      },
    },
    {
      question: "What should I do if I don't have the correct screen resolution ?",
      answer:
        'Please contact Bear Technology for on site hardware support issues on (925) 634-0787 or gludwig@beartechnology.net',
    },
  ],
  ipad: [
    {
      question: 'Who do I contact about Internet issues ?',
      answer:
        'Please contact Bear Technology for on site hardware support issues on (925) 634-0787 or gludwig@beartechnology.net',
    },
    {
      question: "What should I do if an iPad doesn't power up ?",
      answer:
        'Try restarting the device. Please contact Bear Technology for all other site hardware support issues on (925) 634-0787 or gludwig@beartechnology.net',
    },
    {
      question: 'What should I do if an iPad is not behaving as expected ?',
      answer:
        'Try restarting the device and clearing your browser cache. Please contact Bear Technology for all other site hardware support issues on (925) 634-0787 or gludwig@beartechnology.net',
    },
    {
      question: 'What should I do if an iPad is broken or missing ?',
      answer:
        'Please contact Bear Technology for on site hardware support issues on (925) 634-0787 or gludwig@beartechnology.net',
    },
    {
      question: 'Who do I contact if an iPad is reporting software update issues ?',
      answer:
        'Please contact Bear Technology for on site hardware support issues on (925) 634-0787 or gludwig@beartechnology.net',
    },
  ],
  application: [
    {
      question: 'What should I do if the app is unresponsive ?',
      answer:
        'Try restarting the device and clearing your browser cache. If this does not work please contact Binyan using the Support Ticket tab on this page',
    },
    {
      question: 'What should I do if I encounter a browser error ?',
      answer:
        'Try restarting the device and clearing your browser cache. If this does not work please contact Binyan using the Support Ticket tab on this page',
    },
    {
      question: 'What should I do if I cannot access my account ?',
      answer:
        'Try restarting the device and clearing your browser cache. If this does not work please contact Binyan using the Support Ticket tab on this page',
    },
    {
      question: 'What should I do if I cannot create a new session ?',
      answer:
        'Try restarting the device and clearing your browser cache. If this does not work please contact Binyan using the Support Ticket tab on this page',
    },
    {
      question: 'What should I do if content is not loading ?',
      answer:
        'Try restarting the device and clearing your browser cache. If this does not work please contact Binyan using the Support Ticket tab on this page',
    },
    {
      question: 'How do I report a potential bug in the app ?',
      answer:
        'Try restarting the device and clearing your browser cache. If this does not work please contact Binyan using the Support Ticket tab on this page',
    },
    {
      question: 'What should I do if I encounter a browser error ?',
      answer:
        'Try restarting the device and clearing your browser cache. If this does not work please contact Binyan using the Support Ticket tab on this page',
    },
    {
      question: 'What should I do if the app is not connecting to the physical model lights ?',
      answer:
        'Please contact Model Works for Physical Model issues on (310) 322-3550 or amir@modelworksinc.com',
    },
    {
      question: "What should I do if I don't have the correct screen resolution ?",
      answer:
        'Please contact Bear Technology for on site hardware support issues on (925) 634-0787 or gludwig@beartechnology.net',
    },
    {
      question: 'Who do I contact about wanting to make feature changes ?',
      answer: 'Please direct all suggestions for changes to the Experience App to Compass',
    },
    {
      question: 'Who I contact about missing information ?',
      answer: 'Please direct all suggestions for changes to the Experience App to Compass',
    },
  ],
};
export const selectQuestionTypes = [
  {
    value: '',
    label: 'Other',
  },
  {
    value: 'About Hardware & TV Screen? \n',
    label: 'Hardware & TV Screen',
  },
  {
    value: 'About Physical Model ? \n',
    label: 'Physical Model',
  },
  {
    value: 'About Apple Mac & iPads? \n',
    label: 'Apple Mac & iPads',
  },
  {
    value: 'About Internet Connectivity ? \n',
    label: 'Internet Connectivity',
  },
  {
    value: 'About App 3D Content, Floorplans & Images ? \n',
    label: 'App 3D Content, Floorplans & Images',
  },
  {
    value: 'About Login & Passwords ? \n',
    label: 'Login & Passwords',
  },
  {
    value: 'About New feature Request ? \n',
    label: 'New feature Request',
  },
];
