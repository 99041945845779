import ApiClient from '../apiClient';
import { authFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(authFetcher);

const getExcutiveSummary = (excutiveSummaryId, data) =>
  client.get(
    endPoint.GET_EXCUTIVE_SUMMARY_DETAIL.replace(':excutiveSummaryId', excutiveSummaryId),
    data
  );

export default {
  getExcutiveSummary,
};
