import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { reqSetIsVisibleCustomerFavorite } from '../../reduxs/customer/action';
import { formatMoney, getStatus } from '../../helper/unit';
import preferenceApi from '../../apis/api/preference';
import modalApi from '../../apis/api/modal';
import { toast } from 'react-toastify';

const PropertyFavoriteItem = (props) => {
  const { property, preference, getCustomerPreference } = props;
  const [isShowFeatures, toggleShowFeatures] = useState(false);

  const onDeleteFavoriteUnit = async (property) => {
    let unitIds = preference.units.map(item => item.id);
    unitIds = unitIds.filter(item => item !== property.id);
    try {
      await preferenceApi.savePreference({
        customer: preference.customer.id,
        units: unitIds,
      })
      toast.success('Deleted favorite unit successfully!');
      getCustomerPreference();
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <tr>
      <td className="bg-0">
        <span className={`icon-collapse ${isShowFeatures && "show"}`} role="button" onClick={() => toggleShowFeatures(!isShowFeatures)} />
      </td>
      <td className="ps-2">{property?.address}</td>
      <td>{property?.area.name}</td>
      <td className="w-125px">
        {
          isShowFeatures ? (
            <>
              Bathrooms: {property?.features?.bathrooms} <br />
              Bedrooms: {property?.features?.bedrooms} <br />
              Family Room: {property?.features?.familyRoom} <br />
              Floor: {property?.features?.floor} <br />
              Parking Stalls: {property?.features?.parkingStallCount} <br />
              Study: {property?.features?.study} <br />
            </>
          ) : "..."
        }
      </td>
      <td>{property?.floor_plan?.name}</td>
      <td>{property?.totalLot}</td>
      <td>{property?.totalHome}</td>
      <td className="w-125px">
        {formatMoney(property?.price)}
      </td>
      <td className="w-125px">
        {getStatus(property)}
      </td>
      <td className="w-80px">
        <a href="#" onClick={() => onDeleteFavoriteUnit(property)} className="text-underline">DELETE</a>
      </td>
    </tr>
  )
}

const AmenityFavoriteItem = (props) => {
  const { amenity, preference, getCustomerPreference } = props;
  const [modal, setModal] = useState();

  useEffect(() => {
    amenity && getModalInfo(amenity.modal);
  }, [amenity]);

  const onDeleteFavoriteAmenity = async (amenity) => {
    let amenityIds = preference.amenities.map(item => item.id);
    amenityIds = amenityIds.filter(item => item !== amenity.id);

    try {
      await preferenceApi.savePreference({
        customer: preference.customer.id,
        amenities: amenityIds,
      })
      toast.success('Deleted favorite amenity successfully!');
      getCustomerPreference();
    } catch (error) {
      toast.error(error.message);
    }
  }

  const getModalInfo = async (id) => {
    try {
      const res = await modalApi.getModalDetail(id);
      setModal(res.data);
    } catch (error) {
    }
  }

  return (
    <div className="col-md-4 mb-2">
      <div className="card shadow-0">
        <div className="card-body px-0">
          <figure className="card-img">
            <img src={modal?.image_path} alt="" />
          </figure>
          <h5 className="card-title f-normal">{amenity?.name} <i onClick={() => onDeleteFavoriteAmenity(amenity)} className="fas fa-trash cursor-pointer" /></h5>
        </div>
      </div>
    </div>
  )
}

const CustomerFavorites = () => {
  const dispatch = useDispatch();
  const isVisible = useSelector((state) => state.customer.isVisibleCustomerFavorite);
  const customer = useSelector((state) => state.customer.selectedCustomer);
  const [ preference, setPreference ] = useState();

  useEffect(() => {
    if (customer) {
      getCustomerPreference();
    }
  }, [customer])

  const getCustomerPreference = async () => {
    try {
      const res = await preferenceApi.getCustomerPreference({
        customer: customer?.id,
      });
      setPreference(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className={`wrap-modal wrap-modal-cms wrap-modal-cms-favorite fade-item-enter-done ${isVisible ? '' : 'hidden'}`}>
        <div>
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="f-title">{customer?.firstname}’s Favorites</h2>
            </div>
            <div className="modal-body">
              <section>
                <h3 className="f-title mb-3">Properties</h3>
                <div className="mb-4">
                  <div className="table-responsive table-row-collapse wrapper-scroll-y">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th className="border-0" />
                          <th>Name</th>
                          <th>Property Type</th>
                          <th>Features</th>
                          <th>FLOORPLAN</th>
                          <th>Lot sq.ft.</th>
                          <th>Home sq.ft.</th>
                          <th>Price</th>
                          <th>Availability</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody className="border-0">
                        {
                          (preference?.units || []).map((unit, index) => {
                            return (
                              <PropertyFavoriteItem getCustomerPreference={getCustomerPreference} key={index} preference={preference} property={unit} />
                            )
                          })
                        }
                      </tbody>
                    </table>

                  </div>
                </div>
              </section>
              <section>
                <div className="d-flex align-items-center">
                  <h3 className="f-title">Amenities</h3>
                  <div className="ms-auto toolbar">
                    <button className="btn btn-white shadow-0 px-3">
                      <span className="svg-icon">
                        <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.125 2.25C1.74632 2.25 2.25 1.74632 2.25 1.125C2.25 0.50368 1.74632 0 1.125 0C0.50368 0 0 0.50368 0 1.125C0 1.74632 0.50368 2.25 1.125 2.25Z" fill="black" />
                          <path d="M1.125 7.875C1.74632 7.875 2.25 7.37132 2.25 6.75C2.25 6.12868 1.74632 5.625 1.125 5.625C0.50368 5.625 0 6.12868 0 6.75C0 7.37132 0.50368 7.875 1.125 7.875Z" fill="black" />
                          <path d="M1.125 13.5C1.74632 13.5 2.25 12.9963 2.25 12.375C2.25 11.7537 1.74632 11.25 1.125 11.25C0.50368 11.25 0 11.7537 0 12.375C0 12.9963 0.50368 13.5 1.125 13.5Z" fill="black" />
                          <path d="M19.1925 5.625H5.5575C4.97346 5.625 4.5 6.09846 4.5 6.6825V6.8175C4.5 7.40154 4.97346 7.875 5.5575 7.875H19.1925C19.7765 7.875 20.25 7.40154 20.25 6.8175V6.6825C20.25 6.09846 19.7765 5.625 19.1925 5.625Z" fill="black" />
                          <path d="M19.1925 11.25H5.5575C4.97346 11.25 4.5 11.7235 4.5 12.3075V12.4425C4.5 13.0265 4.97346 13.5 5.5575 13.5H19.1925C19.7765 13.5 20.25 13.0265 20.25 12.4425V12.3075C20.25 11.7235 19.7765 11.25 19.1925 11.25Z" fill="black" />
                          <path d="M19.1925 0H5.5575C4.97346 0 4.5 0.473459 4.5 1.0575V1.1925C4.5 1.77654 4.97346 2.25 5.5575 2.25H19.1925C19.7765 2.25 20.25 1.77654 20.25 1.1925V1.0575C20.25 0.473459 19.7765 0 19.1925 0Z" fill="black" />
                        </svg>
                      </span>
                    </button>
                    <button className="btn btn-white shadow-0 px-3 active">
                      <span className="svg-icon">
                        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.5 14.05V9.7C9.5 9.58065 9.54741 9.46619 9.6318 9.3818C9.71619 9.29741 9.83065 9.25 9.95 9.25H14.3C14.4193 9.25 14.5338 9.29741 14.6182 9.3818C14.7026 9.46619 14.75 9.58065 14.75 9.7V14.05C14.75 14.1693 14.7026 14.2838 14.6182 14.3682C14.5338 14.4526 14.4193 14.5 14.3 14.5H9.95C9.83065 14.5 9.71619 14.4526 9.6318 14.3682C9.54741 14.2838 9.5 14.1693 9.5 14.05V14.05Z" stroke="black" strokeWidth="0.7" />
                          <path d="M1.25 14.05V9.7C1.25 9.58065 1.29741 9.46619 1.3818 9.3818C1.46619 9.29741 1.58065 9.25 1.7 9.25H6.05C6.16935 9.25 6.28381 9.29741 6.3682 9.3818C6.45259 9.46619 6.5 9.58065 6.5 9.7V14.05C6.5 14.1693 6.45259 14.2838 6.3682 14.3682C6.28381 14.4526 6.16935 14.5 6.05 14.5H1.7C1.58065 14.5 1.46619 14.4526 1.3818 14.3682C1.29741 14.2838 1.25 14.1693 1.25 14.05V14.05Z" stroke="black" strokeWidth="0.7" />
                          <path d="M9.5 5.8V1.45C9.5 1.33065 9.54741 1.21619 9.6318 1.1318C9.71619 1.04741 9.83065 1 9.95 1H14.3C14.4193 1 14.5338 1.04741 14.6182 1.1318C14.7026 1.21619 14.75 1.33065 14.75 1.45V5.8C14.75 5.91935 14.7026 6.03381 14.6182 6.1182C14.5338 6.20259 14.4193 6.25 14.3 6.25H9.95C9.83065 6.25 9.71619 6.20259 9.6318 6.1182C9.54741 6.03381 9.5 5.91935 9.5 5.8V5.8Z" stroke="black" strokeWidth="0.7" />
                          <path d="M1.25 5.8V1.45C1.25 1.33065 1.29741 1.21619 1.3818 1.1318C1.46619 1.04741 1.58065 1 1.7 1H6.05C6.16935 1 6.28381 1.04741 6.3682 1.1318C6.45259 1.21619 6.5 1.33065 6.5 1.45V5.8C6.5 5.91935 6.45259 6.03381 6.3682 6.1182C6.28381 6.20259 6.16935 6.25 6.05 6.25H1.7C1.58065 6.25 1.46619 6.20259 1.3818 6.1182C1.29741 6.03381 1.25 5.91935 1.25 5.8V5.8Z" stroke="black" strokeWidth="0.7" />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
                <div className="row amenity-wrapper wrapper-scroll-y">
                  {
                    (preference?.amenities || []).map((amenity) => {
                      return (
                        <AmenityFavoriteItem getCustomerPreference={getCustomerPreference} key={amenity.id} preference={preference} amenity={amenity} />
                      )
                    })
                  }
                </div>
              </section>
            </div>
          </div>
        </div>
        <div className="close-btn p-5" onClick={() => dispatch(reqSetIsVisibleCustomerFavorite(false))}>
          <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="8.57918" y1="8.73918" x2="25.8968" y2="26.0568" stroke="#282A2D" strokeWidth="2" />
            <line x1="25.897" y1="8.5782" x2="8.57943" y2="25.8958" stroke="#282A2D" strokeWidth="2" />
          </svg>
        </div>
      </div>
      <div className={`wrap-modal-backdrop ${isVisible ? '' : 'hidden'}`} />
    </>
  );
}

export default CustomerFavorites;
