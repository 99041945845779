import React from 'react';
import { useSelector } from 'react-redux';
import socket from '../../helper/socket';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { reqGetUserProfile } from '../../reduxs/user/action';
import Panorama from './panorama';
import { useHistory } from 'react-router-dom';
import './index.scss';


const PanoramaVillasPage = (props) => {
  const { roles, authMiddleware } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const authUser = useSelector((state) => state.user.data);

  const isPresentation = useSelector((state) => state.home.isPresentation);

  useEffect(() => {
    dispatch(reqGetUserProfile());
    authMiddleware(roles, history);
  }, []);

  useEffect(() => {
    if (authUser && authUser.id) {
      socket.auth = {
        userId: authUser.id,
      };
      socket.connect();

      return () => {
        socket.disconnect();
      };
    }
  }, [authUser]);

  const renderPanorama = () => {
    if (authUser == null || authUser == undefined || authUser.id == undefined) {
      return <div />;
    }

    const names = [
      'Great Room - Plan A',
      'Great Room - Plan C',
      'Primary Bedroom',
      'Primary Bathroom',
      'Entry Courtyard - Plan A',
    ];

    const images = [
      '/uploads/panorama/Villa_Living_Room.jpg',
      '/uploads/panorama/Villa_Kitchen.jpg',
      '/uploads/panorama/Villa_Master_Bedroom.jpg',
      '/uploads/panorama/Villa_Master_Bathroom.jpg',
      '/uploads/panorama/Villa_Courtyard.jpg',
      ];

    const firstImage = '/uploads/panorama/Villa_Living_Room.jpg';

    return <Panorama name={"The Villas"} isPresentation={isPresentation} names={names} images={images} firstImage={firstImage} />;
  };

  return (
    <div>
      {renderPanorama()}
    </div>
  );
};

export default PanoramaVillasPage;
