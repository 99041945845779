import * as type from './type';

const initialState = {
  modal: '',
  isShowExploreModal: false,
  modalId: '',
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case type.SET_EXPLORE_MODAL:
      return {
        ...state,
        modal: action.data.data,
      };
    case type.SET_IS_SHOW_EXPLORE_MODAL:
      return {
        ...state,
        isShowExploreModal: action.data,
      };
    case type.SET_EXPLORE_MODAL_ID:
      return {
        ...state,
        modalId: action.data,
      };
    default:
      return state;
  }
};
