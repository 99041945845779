import * as Types from './type';
import amenitiesApi from '../../apis/api/amenities';
import areaApi from '../../apis/api/area';
import amenities from './reducer';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import store from '../store';

const actGetAmenitiesList = (data) => ({
  type: Types.GET_AMENITIES_LIST,
  data,
});

export const actSetActiveAmenityArea = (data) => ({
  type: Types.SET_ACTIVE_AMENITY_AREA,
  data,
});

export const actSetActiveAmenity = (data) => ({
  type: Types.SET_ACTIVE_AMENITY,
  data,
})

export const actSetActiveAmenityId = (data) => ({
  type: Types.SET_ACTIVE_AMENITY_ID,
  data,
})

export const actSetAmenityItemLatestTime = (data) => ({
  type: Types.SET_AMENITY_ITEM_LATEST_TIME,
  data,
});

export const reqGetAmenitiesList = () => (dispatch) => {
  return amenitiesApi
    .getAmenitiesList()
    .then((data) => {
      dispatch(actGetAmenitiesList(data));
    })
};

export const reqSetActiveAmenityArea = (id) => (dispatch) => {
  if (!id) return dispatch(actSetActiveAmenityArea(''));

  return areaApi
    .getAreaDetail(id)
    .then((data) => {
      dispatch(actSetActiveAmenityArea(data));
    });
}

export const reqSetActiveAmenity = (id) => (dispatch) => {
  dispatch(actSetActiveAmenityId(id));
  if (!id) {
    return dispatch(actSetActiveAmenity(''));
  }

  return amenitiesApi
    .getAmenityDetail(id)
    .then((data) => {
      const state = store.getState();
      if (state.amenities.activeAmenityId == id) {
        dispatch(actSetActiveAmenity(data));
      }
    });
}
