import React, { useState, useEffect, useRef } from "react";
import "./index.scss";

const Cube = (props) => {
  const cubeScene = useRef(null);
  const [active, setActive] = useState(false);
  const [anim, setAnim] = useState(null);

  let startY = 0;
  let pos = 0;
  let maxY = 50;
  let dragging = false;

  useEffect(() =>{
    props.draggable && setAnim(1);
  }, [])

  const dragMouseDown = (e) => {
    if (!props.draggable) return;
    e = e || window.event;
    e.preventDefault();
    setActive(true);
    dragging = true;
    pos = e.clientY;
    props.onDragStart && props.onDragStart();

    if (e.type === "touchstart") {
      if (e.targetTouches.length == 1) {
        startY = e.targetTouches[0].pageY;
      } else {
        closeDragElement();
      }
      document.ontouchend = closeDragElement;
      cubeScene.current.ontouchmove = elementDrag;
    }

    if (e.type === "mousedown") {
      startY = e.clientY;
      document.onmouseup = closeDragElement;
      cubeScene.current.onmousemove = elementDrag;
    }
  };

  const elementDrag = (e) => {
    if (!props.draggable) return;

    e = e || window.event;
    e.preventDefault();
    props.onDragStart();
    if (!dragging) {
      closeDragElement();
    }

    if (e.type === "touchmove") {
      if (e.targetTouches.length == 1) {
        let newPos = e.targetTouches[0].pageY - startY;
        if (newPos < 0) {
          newPos = 0;
        } else if (newPos > maxY) {
          newPos = maxY;
        }
        cubeScene.current.style.top = newPos + "px";
      }
    }

    if (e.type === "mousemove") {
      let newPos = e.clientY - startY;
      if (newPos < 0) {
        newPos = 0;
      } else if (newPos > maxY) {
        newPos = maxY;
      }
      cubeScene.current.style.top = newPos + "px";
    }
  };

  const closeDragElement = () => {
    if (!props.draggable) return;

    if (anim === "null" && anim !== "forward") {
      setAnim("forward");
    }
    if (dragging && props.active) {
      setAnim("forward");
    } else if (dragging && !props.active) {
      setAnim("reverse");
    }

    dragging = false;
    cubeScene.current.style.top = 0 + "px";
    setActive(false);
    props.onDragEnd && props.onDragEnd();


    props.onDragEnd();
    document.onmouseup = null;
    document.ontouchend = null;
    cubeScene.current.ontouchmove = null;
    cubeScene.current.onmousemove = null;
  };

  let dir = props.active ? "reverse" : "forward";
  let animClass = anim === null ? "" : dir;
  animClass = animClass === "" && props.active ? "reverse" : animClass;
  animClass = anim === null ? "" : animClass;

  return (
    <div className="cube-scene-wrapper"
      onClick={() => { props.onClick(); animClass === "forward" && setAnim(null)}}
    >
      <div
        ref={cubeScene}
        className={`cube-scene ${active && "active"} ${
          props.disabled ? "disabled" : ""
        }`}
        onMouseDown={props.draggable ? dragMouseDown : null}
        onTouchStart={props.draggable ? dragMouseDown : null}
      >
        <div className={`cube ${anim === 1 ? 'reverse' : animClass}`}>
          <div className="cube__face cube__face--front" />
          <div className="cube__face cube__face--back" />
          <div className="cube__face cube__face--right" />
          <div className="cube__face cube__face--left" />
          <div className="cube__face cube__face--top" />
          <div className="cube__face cube__face--bottom" />
        </div>
      </div>
    </div>
  );
};

export default Cube;
