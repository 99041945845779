import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reqSetIsShowCustomerDetailModal, reqSetIsShowStartSessionModal } from '../../reduxs/home/action';
import customerApi from '../../apis/api/customer';
import { toast } from 'react-toastify';
import { useLocation, useHistory } from 'react-router';

const CustomerDetailModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const customerId = useQuery().get('customer');
  const isShowCustomerDetailModal = useSelector((state) => state.home.isShowCustomerDetailModal);
  const [customer, setCustomer] = useState();

  useEffect(() => {
    getCustomerInfo();
  }, [])

  const getCustomerInfo = async () => {
    try {
      const res = await customerApi.getCustomerDetail(customerId);
      setCustomer(res.data);
    } catch (error) {
      toast.error(error.message);
    }
  }

  const onCloseModal = () => {
    history.push({
      search: '',
    })
    dispatch(reqSetIsShowCustomerDetailModal(false))
  }

  const onStartSession = () => {
    dispatch(reqSetIsShowCustomerDetailModal(false))
    location.href = `/?customer=${customer?.id}`;
  }

  const onBacktoStartSessionModal = () => {
    dispatch(reqSetIsShowCustomerDetailModal(false));
    dispatch(reqSetIsShowStartSessionModal(true));
  }

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  return (
    <div className={`wrap-modal wrap-modal-session text-start ${isShowCustomerDetailModal ? 'show' : 'hide'}`}>
      <div className="h-100">
        <div className={`modal-form h-100`}>
          <div className="pe-5">
            <h3 className="modal-form__title">CONFIRM DETAILS OF USER SELECTED FOR TODAY’S SESSION</h3>
            <p className="modal-form__subtitle">This will be the user for today’s session</p>
          </div>
          <div className="modal-form__body">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="NAME"
                disabled
                value={`${customer?.firstname} ${customer?.surname}`}
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Email Address"
                disabled
                value={customer?.email}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="phone"
                placeholder="PHONE NUMBER"
                disabled
                value={customer?.mobile}
              />
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <button onClick={() => onBacktoStartSessionModal()} className="btn btn-lg btn-link ps-0 pb-0 ms-0">Back</button>
              <button
                onClick={() => onStartSession()}
                className="btn btn-lg btn-link pe-0 pb-0 me-0"
                >
                Start Session
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="close-btn p-4" onClick={() => onCloseModal()}>
        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="8.57918" y1="8.73918" x2="25.8968" y2="26.0568" stroke="#282A2D" strokeWidth="2" />
          <line x1="25.897" y1="8.5782" x2="8.57943" y2="25.8958" stroke="#282A2D" strokeWidth="2" />
        </svg>
      </div>
    </div>
  )
}

export default CustomerDetailModal;
