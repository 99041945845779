/* eslint-disable no-unreachable */
import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { routeOptions } from './options';
import path from './path';
import authApi from '../apis/api/auth';
import jwtAuth from '../apis/utils/jwtAuth';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

export const Router = () => {
  const authMiddleware = (routeRoles, history) => {
    if (routeRoles.length) {
      if (jwtAuth.checkToken()) {
        return getUserProfile(routeRoles, history);
      } else {
        return history.push('/sign-in');
      }
    }
  };

  const getUserProfile = async (routeRoles, history) => {
    try {
      const res = await authApi.getMe();
      const userData = res?.data;
      if (!routeRoles.includes(userData?.userGroup?.name)) {
        return history.push('/sign-in');
      }
    } catch (error) {
      return history.push('/sign-in');
    }
  };

  return (
    <BrowserRouter>
      <Switch>
        {routeOptions.map((item, index) => (
          <Route
            key={index}
            path={item.path}
            render={(props) => (
              <item.component {...props} roles={item.roles} authMiddleware={authMiddleware} />
            )}
            exact={item.exact}
          />
        ))}
        <Route path="*">
          <Redirect to={path.NOTFOUND_PAGE} />
        </Route>
      </Switch>
      <ToastContainer />
    </BrowserRouter>
  );
};

export default Router;
