import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import TopNav from './top-nav';
import CMSProperties from './properties';
import CMSCustomers from './customers';
import CMSFrequentlyAQS from './frequently-asked-questions';
import './index.scss';

const CMS = (props) => {
  const { roles, authMiddleware } = props;
  const history = useHistory();

  useEffect(() => {
    authMiddleware(roles, history);
  }, []);

  return (
    <div className="wrap-cms-page">
      <TopNav />
      <Switch>
        <Route exact path="/cms">
          <CMSCustomers />
        </Route>
        <Route path={`/cms/customers`}>
          <CMSCustomers />
        </Route>
        <Route path={`/cms/properties`}>
          <CMSProperties />
        </Route>
        <Route path={`/cms/frequently-asked-questions`}>
          <CMSFrequentlyAQS />
        </Route>
      </Switch>
    </div>
  );
};

export default CMS;
