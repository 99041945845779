import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { reqSetDeleableCustomer, reqSetEditableCustomer, reqSetIsVisibleCustomerFavorite, reqSetSelectedCustomer } from '../../reduxs/customer/action';
import config from '../../config/index';
import sessionApi from '../../apis/api/session';
import { toast } from 'react-toastify';

const CustomerRow = (props) => {
  const { customer } = props;
  const dispatch = useDispatch();
  const copyTooltipRef = useRef();
  const [sessionUrl, setSessionUrl] = useState(`${config.SITE_URL}/post-engagement/${customer?.id}`);
  const [tooltipText, setTooltipText] = useState('Copy url');

  const showEditForm = () => {
    dispatch(reqSetEditableCustomer(customer.id));
  }

  const showDeleteModal = () => {
    dispatch(reqSetDeleableCustomer(customer));
  }

  const showCustomerFavorite = async () => {
    await dispatch(reqSetSelectedCustomer(customer.id));
    dispatch(reqSetIsVisibleCustomerFavorite(true));
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(sessionUrl);
    setTooltipText('Copied');
    copyTooltipRef.current.style.visibility = 'visible';
    copyTooltipRef.current.style.opacity = 1;
    setTimeout(() => {
      copyTooltipRef.current.style.visibility = 'hidden';
      copyTooltipRef.current.style.opacity = 0;
      setTooltipText('Copy url');
    }, 1000)
  }

  const sendSessionInvitation = async () => {
    try {
      await sessionApi.sendSessionInvitation({
        email: customer?.email
      })
      toast.success('Sent invitation email successfully!');
    } catch (error) {
      toast.error(error?.message);
    }
  }

  return (
    <tr>
      <td className="w-250px">{customer?.firstname} {customer?.surname}</td>
      <td className="w-250px">{customer?.email}</td>
      <td className="w-125px">{customer?.mobile}</td>
      <td>
        <a href="#" onClick={() => showCustomerFavorite()} className="text-underline">VIEW</a>
      </td>
      <td>
        <a href={`${config.SITE_URL}/?customer=${customer?.id}`} target="_blank" className="text-underline" rel="noreferrer">Start Session</a>
      </td>
      <td>
        <div className="input-group ms-auto page-header__input-search my-n2">
          <input className="form-control" type="text" value={sessionUrl} disabled />
          <span
            onClick={() => copyToClipboard()}
            className="input-group-text text-muted btn-copy-session-url"
            role="button"
          >
            <span ref={copyTooltipRef} className="copy-tooltip">{tooltipText}</span>
            copy
          </span>
        </div>
      </td>
      <td>
        <div className="form-check form-switch my-n1">
          <input className="form-check-input" type="checkbox" id="customer-state-1" />
          <label className="form-check-label" htmlFor="customer-state-1" />
        </div>
      </td>
      <td className="w-80px">
        <a href="#" onClick={() => sendSessionInvitation()} className="text-underline">RESEND</a>
      </td>
      <td className="w-80px">
        <a href="#" onClick={() => showDeleteModal()} className="text-underline">DELETE</a>
      </td>
      <td>
        <button
          className={`btn btn-more rounded-pill shadow-0`}
          onClick={() => showEditForm()}
        >...</button>
      </td>
    </tr>
  )
}

export default CustomerRow;
