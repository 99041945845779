import React, { useEffect, useRef } from 'react';
import UnitList from './UnitList';
import UnitDetail from './UnitDetail';
import UnitFilter from './UnitFilter';
import './index.scss';
import FloorPlanGallery from '../floor-plan-gallery';
import Gallery from '../gallery';
import { useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import FloorPlanBtn from '../floor-plan-gallery/FloorPlanBtn';
import { ACTION_NAME, AREA_OPTION, PRICE_OPTION, SQUARE_FOOTAGE_OPTION } from '../../constants/options';
import { useDispatch } from 'react-redux';
import * as unitExploreAct from '../../reduxs/unit-explore/action';
import { emitUIActionEvent } from '../../helper/socket';

const UnitExplore = (props) => {
  const {
    handleUnitClick,
    setActiveObjectIds,
    handleAreaClick,
  } = props;
  const dispatch = useDispatch();
  const units = useSelector((state) => state.unitExplore.units);
  const filterUnitArea = useSelector((state) => state.unitExplore.filterUnitArea);
  const filterUnitStatus = useSelector((state) => state.unitExplore.filterUnitStatus);
  const filterUnitPrice = useSelector((state) => state.unitExplore.filterUnitPrice);
  const filterUnitEnclave = useSelector((state) => state.unitExplore.filterUnitEnclave);
  const filterUnitFloorplanName = useSelector((state) => state.unitExplore.filterUnitFloorplanName);
  const filterUnitFloorplanCode = useSelector((state) => state.unitExplore.filterUnitFloorplanCode);
  const filterUnitSize = useSelector((state) => state.unitExplore.filterUnitSize);
  const filterUnitParkingStall = useSelector((state) => state.unitExplore.filterUnitParkingStall);
  const isShowFloorplan = useSelector((state) => state.unitExplore.isShowFloorplan);
  const isShowGallery = useSelector((state) => state.unitExplore.isShowGallery);
  const isShowUnitDetail = useSelector((state) => state.unitExplore.isShowUnitDetail);
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const isShowFilter = useSelector((state) => state.unitExplore.isShowFilter);
  const isTransparent = useSelector((state) => state.home.isTransparent);
  const isPresentation = useSelector((state) => state.home.isPresentation);
  const authUser = useSelector((state) => state.user.data);

  const floorPlanGalleryRef = useRef();

  useEffect(() => {
    dispatch(unitExploreAct.reqGetUnitArea({
      'area_type[equal]': 'Units Area',
    }));
  }, []);

  useEffect(() => {
    const query = {};
    if (filterUnitArea) {
      query['area[equalId]'] = filterUnitArea;
    }

    if (filterUnitEnclave.length) {
      query['enclave[in]'] = JSON.stringify(filterUnitEnclave);
    }

    if (filterUnitFloorplanCode) {
      query['floorplanNumber[equal]'] = filterUnitFloorplanCode;
    }

    if (filterUnitFloorplanName) {
      query['floorplan_name'] = filterUnitFloorplanName;
    }

    if (filterUnitStatus) {
      query['availabilityStatus[equal]'] = filterUnitStatus;
    }

    if (filterUnitPrice) {
      const priceData = PRICE_OPTION.find((item) => item.id === filterUnitPrice);
      priceData.min && (query['price[gte]'] = priceData.min);
      // priceData.max && (query['price[lte]'] = priceData.max);
    }

    if (filterUnitSize) {
      const sizeData = SQUARE_FOOTAGE_OPTION.find((item) => item.id === filterUnitSize);
      sizeData.min && (query['totalHome[gte]'] = sizeData.min);
      // sizeData.max && (query['totalHome[lte]'] = sizeData.max);
    }

    if (filterUnitParkingStall.length) {
      query['features.parkingStallCount[in]'] = JSON.stringify(filterUnitParkingStall);
    }

    dispatch(unitExploreAct.reqSetUnitQuery(query));
    isFiltered() && resetUnitDetail();

  }, [
    filterUnitStatus, filterUnitArea, filterUnitSize, filterUnitParkingStall,
    filterUnitPrice, filterUnitEnclave, filterUnitFloorplanName,
    filterUnitFloorplanCode,
  ]);

  useEffect(() => {
    if (isFiltered()) {
      setActiveObjectIds(
        units.map((item) => {
          return item['3d_asset']?.id;
        })
      );
    } else if (selectedUnit) {
      const unitFiltereds = units.filter((item) => item.id == selectedUnit.id);
      if (unitFiltereds.length) setActiveObjectIds([unitFiltereds[0]['3d_asset']?.id]);
    } else {
      setActiveObjectIds([]);
    }
  }, [units]);

  useEffect(() => {
    if (!isFiltered() && selectedUnit && !units.filter((item) => item.id == selectedUnit.id).length) {
      resetFilter();
    }

    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    dispatch(unitExploreAct.reqIsShowUnitDetail(true));

  }, [selectedUnit]);

  const isFiltered = () => {
    return filterUnitStatus || filterUnitEnclave.length
      || filterUnitSize || filterUnitParkingStall.length || filterUnitArea
      || filterUnitFloorplanCode || filterUnitFloorplanName || filterUnitPrice;
  }

  const resetUnitDetail = () => {
    dispatch(unitExploreAct.reqSetSelectedUnit(''));
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    dispatch(unitExploreAct.reqIsShowUnitDetail(true));
  };

  const resetFilter = () => {
    dispatch(unitExploreAct.reqFilterUnitArea(''));
    dispatch(unitExploreAct.reqFilterUnitStatus(''));
    dispatch(unitExploreAct.reqFilterUnitBedroom(''));
    dispatch(unitExploreAct.reqFilterUnitSize(''));
    dispatch(unitExploreAct.reqFilterUnitParkingStall(''));
  };

  const setIsShowFilter = (data) => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.EXPLORE_SHOW_FILTER, {
        showFilter: data
      });
    }
    dispatch(unitExploreAct.reqSetIsShowFilter(data));
  }

  return (
    <>
      <div className={`wrap-sidenav`}>
        <CSSTransition in={isShowFilter} timeout={500} classNames="fade-left" unmountOnExit>
          <UnitFilter
            handleAreaClick={handleAreaClick}
            isTransparent={isTransparent}
          />
        </CSSTransition>
        <div className={`float-start position-relative ${isTransparent ? 'invisible' : ''}`}>
          <CSSTransition timeout={500} classNames="fade-left">
            <UnitList
              handleUnitClick={handleUnitClick}
            />
          </CSSTransition>

          <CSSTransition in={!isShowFilter} timeout={500} classNames="fade-item" unmountOnExit>
            <div className="wrap-btn-show-filter">
              <p onClick={() => setIsShowFilter(true)} className="text-center mt-3">
                <b
                  className={`text-uppercase ${
                    selectedUnit && isShowFloorplan ? 'text-dark' : ''
                  }`}
                >
                  Show Filters
                </b>
              </p>
            </div>
          </CSSTransition>
        </div>

        <CSSTransition
          in={!!selectedUnit && isShowFloorplan}
          timeout={500}
          classNames="fade-left"
          unmountOnExit
        >
          <FloorPlanBtn floorPlan={selectedUnit?.floor_plan} floorPlanGalleryRef={floorPlanGalleryRef} />
        </CSSTransition>
      </div>

      {/* <h3 className="mb-0 title-center-bottom f-title">{selectedUnit?.name}</h3> */}

      <CSSTransition
        in={!!(!!selectedUnit && isShowUnitDetail)}
        timeout={500}
        classNames="fade-item"
        unmountOnExit
      >
        <div className={`wrap-sidenav wrap-sidenav--right ${isTransparent ? 'invisible' : ''}`}>
          <UnitDetail/>
        </div>
      </CSSTransition>

      <CSSTransition in={isShowFloorplan} timeout={1000} classNames="fade-item" unmountOnExit>
        <FloorPlanGallery
          floorPlanGalleryRef={floorPlanGalleryRef}
        />
      </CSSTransition>

      <TransitionGroup>
        {isShowGallery && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <Gallery/>
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  );
};

export default UnitExplore;
