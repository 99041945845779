import React from 'react';
import { useState } from 'react';
import './index.scss';
import { ACTION_NAME, PAGES } from '../../constants/options';
import { useEffect } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import Cube from '../cube';
import socket, { emitUIActionEvent } from '../../helper/socket';

library.add(faSearch);

const TopNav = (props) => {
  const {
    isShow,
    handleClickImmerse,
    handleClickCube,
    handleClickExplore,
    handleClickGallery,
    handleClickTeam,
    handleClickLocation,
    handleClickSearch,
  } = props;
  const [isCollapse, setIsCollapse] = useState(true);
  const [firstShow, setFirstShow] = useState(true);
  const [isDragDown, setIsDragDown] = useState(false);
  const [isHideCubeOnDrag, setIsHideCubeOnDrag] = useState(true);
  const pageActive = useSelector((state) => state.home.page);
  const authUser = useSelector((state) => state.user.data);
  const isPresentation = useSelector((state) => state.home.isPresentation);
  const [linksDisabled, setLinksDisabled] = useState(true);

  const setClickTimer = (localCollapsed) => {
    if (localCollapsed) {
      setLinksDisabled(true);
      return;
    }
    setTimeout(() => {
      setLinksDisabled(false);
    }, 700);
  }

  const onClickCube = () => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.CLICK_CUBE_MENU);
    }
    handleClickCube();
    setIsCollapse(false);
    setClickTimer(false);
  };

  useEffect(() => {
    isShow && onClickCube();
  }, [isShow]);

  const startDrag = () => {
    setIsHideCubeOnDrag(false);
  };

  const endDrag = () => {
    setIsHideCubeOnDrag(true);
    setIsDragDown(true);
    setFirstShow(false);
    setIsCollapse(!isCollapse);
    setClickTimer(!isCollapse);
  };

  const isActive = (menu) => {
    return pageActive === menu ? 'active' : '';
  };

  return (
    <div
      className={`wrap-top-nav
      ${isCollapse ? 'hide' : 'show'}
      ${isPresentation ? 'invisible' : '' }
      `}
    >
      <div className={`nav-menu ${linksDisabled && 'disabled'}`}>
        <ul className="left">
          <li>&nbsp;</li>
          <li>
            <span className="menu-img p-0">
              <img src="/logo.png" />{' '}
            </span>
          </li>
          <li className={isActive(PAGES.UNIT_EXPLORER_PAGE)} onClick={() => !isPresentation && handleClickExplore()}>
            <span> EXPLORE</span>
          </li>
          <li className={isActive(PAGES.IMMERSE_PAGE)} onClick={() => !isPresentation && handleClickImmerse()}>
            <span> IMMERSE</span>
          </li>
        </ul>
        <ul className="nav-cube">
          {isShow && <Cube
            draggable={true}
            active={!isCollapse}
            onDragStart={startDrag}
            onDragEnd={isShow && endDrag}
            onClick={isShow && onClickCube}
            onDoubleClick={() => !isPresentation && isShow && handleClickCube()}
          />}
        </ul>
        <ul className="right">
          <li className={isActive(PAGES.LOCATION_PAGE)} onClick={() => !isPresentation && handleClickLocation()}>
            <span> LOCATION</span>
          </li>
          <li className={isActive(PAGES.GALLERY_PAGE)} onClick={() => !isPresentation && handleClickGallery()}>
            <span> MEDIA</span>
          </li>
          <li className={isActive(PAGES.TEAM_PAGE)} onClick={() => !isPresentation && handleClickTeam()}>
            <span> TEAM</span>
          </li>
          <li className={isActive(PAGES.SEARCH_PAGE)} onClick={() => !isPresentation && handleClickSearch()}>
            <span>
              <FontAwesomeIcon
                className="search-icon"
                icon={['fas', 'search']}
                style={{ fontSize: '16px', cursor: 'pointer' }}
              />
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TopNav;
