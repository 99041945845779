import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import auth from './auth/reducer';
import unit from './unit/reducer';
import err from './error/reducer';
import routing from './routing/reducer';
import amenities from './amenities/reducer';
import scene from './scene/reducer';
import user from './user/reducer';
import poi from './locations/reducer';
import exploreModal from './explore-modal/reducer';
import unitExplore from './unit-explore/reducer';
import home from './home/reducer';
import customer from './customer/reducer';
import property from './property/reducer';
import media from './media/reducer';

// Enable in Development
// const actionSanitizer = (action) => {
//   return action.type === 'RECEIVE_FBX_FILES' && action.payload
//     ? { ...action, payload: '<<LONG_BLOB>>' }
//     : action;
// };
const store = createStore(
  combineReducers({
    auth,
    unit,
    err,
    routing,
    amenities,
    scene,
    user,
    poi,
    exploreModal,
    unitExplore,
    home,
    customer,
    property,
    media,
  }),
  {},
  compose(applyMiddleware(thunkMiddleware))
    // applyMiddleware(thunk),
    // window.__REDUX_DEVTOOLS_EXTENSION__ &&
    //   window.__REDUX_DEVTOOLS_EXTENSION__({
    //     actionSanitizer,
    //     stateSanitizer: (state) => {
    //       return state.scene.fbxs
    //         ? { ...state, scene: { ...state.scene, fbxs: '<<LONG_BLOB>>' } }
    //         : state;
    //     },
    //   })
);

export default store;
