import React from 'react';
import './index.scss';

const CloseBtn = (props) => {
  const { handleClick } = props;
  return (
    <>
      <span className="btn-go-back" onClick={() => handleClick()}>
        <img className="img-fluid" src="/icons/icon-back.svg" alt="" />
      </span>
    </>
  );
};

export default CloseBtn;
