import React, { useContext } from "react";
import { AccordionContext, useAccordionToggle } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { reqSetActivePoi, reqSetActiveType } from "../../reduxs/locations/action";

const ContextAwareToggle = (props) => {
  const { children, eventKey, callback, setShouldFit, activeType } = props;
  const currentEventKey = useContext(AccordionContext);
  const dispatch = useDispatch();

  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    callback && callback(eventKey);
    dispatch(reqSetActiveType(eventKey == currentEventKey ? '' : eventKey));
    eventKey === currentEventKey && dispatch(reqSetActivePoi(''));
    setShouldFit(true);
  });

  return (
    <li
      onClick={decoratedOnClick}
      className={`list-group-item ${activeType == eventKey ? 'list-group-heading' : 'heading'}`}
    >
      {children}
    </li>
  );
}

export default ContextAwareToggle;
