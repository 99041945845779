import * as Types from './type';
import poiApi from '../../apis/api/poi';

const actGetPoiList = (data) => ({
  type: Types.GET_POI_LIST,
  data,
});

export const actSetActivePoi = (data) => ({
  type: Types.ACTIVE_POI,
  data,
});

export const actSetActiveType = (data) => ({
  type: Types.ACTIVE_TYPE,
  data,
});

export const actSetPoiModal = (data) => ({
  type: Types.POI_MODAL,
  data,
});

export const actSetIsShowPoiModal = (data) => ({
  type: Types.IS_SHOW_POI_MODAL,
  data,
});

export const actSetDefaultActiveKey = (data) => ({
  type: Types.DEFAULT_ACTIVE_KEY,
  data,
});

export const reqGetPoiList = () => (dispatch) => {
  return poiApi.getPoisList().then((data) => {
    dispatch(actGetPoiList(data));
  });
};

export const reqSetActivePoi = (data) => (dispatch) => {
  return dispatch(actSetActivePoi(data));
};

export const reqSetActiveType = (data) => (dispatch) => {
  return dispatch(actSetActiveType(data));
};

export const reqSetPoiModal = (data) => (dispatch) => {
  return dispatch(actSetPoiModal(data));
};

export const reqSetIsShowModal = (data) => (dispatch) => {
  return dispatch(actSetIsShowPoiModal(data));
};

export const reqSetDefaultActiveKey = (data) => (dispatch) => {
  return dispatch(actSetDefaultActiveKey(data));
};
