import ApiClient from '../apiClient';
import { authFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(authFetcher);

const get3DSettings = (data) => client.get(endPoint._3D_SETTINGS, data);

export default {
  get3DSettings,
};
