import React, { useEffect, useRef, useState } from 'react';
import CanvasBox from '../../components/3d-scene/CanvasBox';
import _3dSettings from '../../apis/api/_3dSettings';

import { connect } from 'react-redux';

import { Vector3, Color } from 'three';
import ReactUIPresentation from '../react-ui-presentation';

const RefCanvasBox = React.forwardRef((props, ref) => <CanvasBox ref={ref} {...props} />);
RefCanvasBox.displayName = 'RefCanvasBox';

const Index = (props) => {
  const { roles, authMiddleware } = props;
  const [isIntroduction, setIsIntroduction] = useState(false);
  const controls = useRef();
  let refScene = useRef();

  const renderCanvasBox = () => {
    if (props.isLoading == true) {
      return <div />;
    }
    if (!(!!props.assets.length && !!props.hotspots.length && Object.keys(props.settings).length && props.fbxs.length))
      return <div/>;

    return (
        <CanvasBox
          ref={refScene}
          controls={controls}
          objects={props.assets}
          hotspots={props.hotspots}
          _3dSetting={props.settings}
          fbxs={props.fbxs}
          isIntroduction={isIntroduction}
          isPreview={true}
          targetPosition={controls.current?.getCurrentTarget() ?? new Vector3()}
          needReloadSelectedHotspotId={controls.current?.needReloadSelectedHotspotId ?? false}
          selectedHotspotId={controls.current?.selectedHotspotId ?? ''}
        />
    )
  }
  return (
    <div className="wrap-index-page">
      {renderCanvasBox()}
      <ReactUIPresentation
        authMiddleware={authMiddleware}
        roles={roles}
        isLoading={props.isLoading}
        setIsIntroduction={setIsIntroduction}
        isIntroduction={isIntroduction}
        controls={controls}
        refScene={refScene} />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { scene } = state;
  return {
    settings: scene.settings,
    assets: scene.assets,
    hotspots: scene.hotspots,
    fbxs: scene.fbxs,
    isLoading: scene.isLoading,
  };
};
export default connect(mapStateToProps)(Index);

