import ApiClient from '../apiClient';
import { authFetcher, defaultFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(authFetcher);
const defaultClient = new ApiClient(defaultFetcher);

const signIn = (data) => {
  return client.post(endPoint.SIGN_IN, data);
};

const signOut = (data) => {
  return defaultClient.post(endPoint.SIGN_OUT, data);
};

const resetPassword = (data) => {
  return client.post(endPoint.RESET_PASSWORD, data);
};

const forgotPassword = (data) => {
  return client.post(endPoint.FORGOT_PASSWORD, data);
};

const getMe = () => {
  return defaultClient.get(endPoint.GET_ME);
};

export default {
  signIn,
  resetPassword,
  forgotPassword,
  signOut,
  getMe,
};
