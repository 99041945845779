import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_NAME, WEBSOCKET_CHANNEL } from '../../constants/options';
import socket, { emitUIActionEvent } from '../../helper/socket';
import { reqSetActiveFloorplan } from '../../reduxs/unit-explore/action';

const FloorPlanBtn = (props) => {
  const { floorPlan, floorPlanGalleryRef } = props;

  const dispatch = useDispatch();

  const isPresentation = useSelector((state) => state.home.isPresentation);
  const authUser = useSelector((state) => state.user.data);
  const activeFloorplan = useSelector((state) => state.unitExplore.activeFloorplan);
  useEffect(() => {if (floorPlanGalleryRef.current) floorPlanGalleryRef?.current?.slickGoTo(activeFloorplan);}, [activeFloorplan]);
  const special = [
    'zeroth',
    'first',
    'second',
    'third',
    'fourth',
    'fifth',
    'sixth',
    'seventh',
    'eighth',
    'ninth',
    'tenth',
    'eleventh',
    'twelfth',
    'thirteenth',
    'fourteenth',
    'fifteenth',
    'sixteenth',
    'seventeenth',
    'eighteenth',
    'nineteenth',
  ];

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CLICK_FLOORPLAN_SLIDE_BTN) {
          onGoToSlide(content?.data?.index);
        }
      });
    }
  }, []);

  const onGoToSlide = (index) => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.CLICK_FLOORPLAN_SLIDE_BTN, {
        index,
      });
    }
    dispatch(reqSetActiveFloorplan(index));
    if (floorPlanGalleryRef.current) floorPlanGalleryRef?.current?.slickGoTo(index);
  };

  return (
    <div className="float-start wrap-list-floor">
      <ul className="list-group">
        {(floorPlan?.media || []).map((item, index) => {
          return (
            <li
              className={`list-group-item ${activeFloorplan === index ? 'active' : ''}`}
              onClick={() => onGoToSlide(index)}
              key={index}
            >
              {special[index + 1]} Floor
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FloorPlanBtn;
