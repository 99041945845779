import * as type from './type';

const initialState = {
  data: [],
  activePoi: '',
  activeType: '',
  poiModal: '',
  isShowPoiModal: false,
  defaultActiveKey: '',
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case type.GET_POI_LIST:
      return {
        ...state,
        data: action.data.data,
      };
    case type.ACTIVE_TYPE:
      return {
        ...state,
        activeType: action.data,
      };
    case type.ACTIVE_POI:
      return {
        ...state,
        activePoi: action.data,
      };
    case type.POI_MODAL:
      return {
        ...state,
        poiModal: action.data,
      };
    case type.IS_SHOW_POI_MODAL:
      return {
        ...state,
        isShowPoiModal: action.data,
      };
    case type.DEFAULT_ACTIVE_KEY:
      return {
        ...state,
        defaultActiveKey: action.data,
      };
    default:
      return state;
  }
};
