import React from 'react';
import './index.scss';

const LoadingIcon = () => {
  return (
    <div className="wrap-loading-icon">
      <div className="ldio-h0h1lsshjn">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default LoadingIcon;
