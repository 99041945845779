import React, { useRef, useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GoogleMap, useJsApiLoader, InfoWindow, Marker } from '@react-google-maps/api';
import { Accordion, AccordionContext, Card, useAccordionToggle } from 'react-bootstrap';
import _ from 'lodash';
import { config, options, specialLocations } from './map-config';

import './index.scss';
import { reqGetPoiList, reqSetActivePoi, reqSetActiveType } from '../../reduxs/locations/action';
import socket, { emitUIActionEvent } from '../../helper/socket';
import { ACTION_NAME, WEBSOCKET_CHANNEL } from '../../constants/options';
import ContextAwareToggle from './ContextAwareToggle';

const activeMarkers = [];

const Location = (props) => {
  const pois = useSelector((state) => state.poi.data);
  const activePoi = useSelector((state) => state.poi.activePoi);
  const activeType = useSelector((state) => state.poi.activeType);
  const defaultActiveKey = useSelector((state) => state.poi.defaultActiveKey);
  const dispatch = useDispatch();
  const mapRef = useRef(false);
  const [shouldFit, setShouldFit] = useState(false);
  const authUser = useSelector((state) => state.user.data);
  const isPresentation = useSelector((state) => state.home.isPresentation);

  useEffect(async () => {
    dispatch(reqGetPoiList());
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CLOSE_POI_MODAL) {
          closeInfoWindow();
        }
        if (content.action === ACTION_NAME.CHANGE_ACTIVE_POI) {
          dispatch(reqSetActivePoi(content.data.activePoi))
          setShouldFit(true)
        }
        if (content.action === ACTION_NAME.CHANGE_ACTIVE_TYPE) {
          dispatch(reqSetActiveType(content.data.activeType))
          setShouldFit(true)
        }
      })
    }
  }, [isPresentation]);

  useEffect(() => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.CHANGE_ACTIVE_TYPE, {
        activeType,
      })
    }
  }, [activeType]);

  useEffect(() => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.CHANGE_ACTIVE_POI, {
        activePoi,
      })
    }
  }, [activePoi]);

  useEffect(() => {
    // Fit the bounds to the currently shown markers
    if (shouldFit && activeMarkers.length && mapRef) {
      let bounds = new google.maps.LatLngBounds();
      activeMarkers.forEach((marker) => {
        bounds.extend(marker);
      });

      if (!!mapRef?.current?.state?.map) {
        mapRef.current.state.map.setOptions({ maxZoom: 13 });
        mapRef.current.state.map.fitBounds(bounds);
        mapRef.current.state.map.setCenter(bounds.getCenter());
        mapRef.current.state.map.setOptions({ maxZoom: undefined });
      }
    }
    setShouldFit(false);
  });

  const getShowClass = (key) => {
    if (!isPresentation) return;

    return activeType == key ? 'show' : '';
  }

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyC2fmTkp-ummiBHRE2ee4MVD_rr-pZ9fcI',
  });

  const showInfoWindow = (data) => {
    dispatch(reqSetActivePoi(''));
    dispatch(reqSetActivePoi(data.id));
  };

  const closeInfoWindow = () => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.CLOSE_POI_MODAL)
    }
    dispatch(reqSetActivePoi(''));
  };

  const ListPois = () => {
    const poiGroups = _.groupBy(pois || [], 'group');
    const listPois = [];

    for (const key in poiGroups) {
      listPois.push(
        <Card key={key}>
          <ul className="list-group">
            <ContextAwareToggle activeType={activeType}  setShouldFit={setShouldFit} eventKey={key}>{key}</ContextAwareToggle>
            <Accordion.Collapse eventKey={key} className={`mb-4 ${getShowClass(key)}`}>
              <ListPoiItems group={poiGroups[key]} />
            </Accordion.Collapse>
          </ul>
        </Card>
      );
    }

    return listPois;
  };

  const ListPoiItems = (props) => {
    const poiTypes = _.groupBy(props.group, 'type');
    const listPoiItems = [];
    for (const key in poiTypes) {
      if (key !== 'undefined') {
        listPoiItems.push(
          <li key={`${key}_type`} className="list-group-item list-group-type">
            {key}
          </li>
        );
      }
      {
        poiTypes[key].map((item, index) => {
          listPoiItems.push(
            <li
              key={`${item.id}_id`}
              className={`list-group-item ${activePoi === item.id ? 'active' : ''}`}
              onClick={() => showInfoWindow(item)}
            >
              {item.name}
            </li>
          );
        });
      }
    }

    return listPoiItems;
  };

  const renderMap = () => {
    // reset active markers on each render
    return (
      <GoogleMap
        ref={mapRef}
        mapContainerStyle={{ width: '100%', height: '100%' }}
        center={config.center}
        zoom={config.zoom}
        options={options}
      >
        {specialLocations.map((poi) => {
          return (
            <Marker
              key={`${poi.id}_marker`}
              position={{
                lat: poi.coordinate.lat,
                lng: poi.coordinate.long,
              }}
              icon={poi.icon}
              onClick={(e) => {
                if (poi.modal == undefined) {
                  return;
                }
                showInfoWindow(poi);
              }}
              onLoad={(e) => {
                activeMarkers.push(e.getPosition());
              }}
              onUnmount={(e) => {
                let lat = e.getPosition().lat();
                let lng = e.getPosition().lng();
                activeMarkers.splice(
                  activeMarkers.findIndex((am) => {
                    return am.lng() === lng && am.lat === lat;
                  }, 1)
                );
              }}
              data={poi}
            >
              {activePoi === poi.id && (
                <InfoWindow
                  key={`${poi.id}_info_window`}
                  onCloseClick={closeInfoWindow}
                  position={{
                    lat: poi.coordinate.lat,
                    lng: poi.coordinate.long,
                  }}
                  options={{ closeBoxMargin: '100px 20px 2px 2px' }}
                >
                  <div dangerouslySetInnerHTML={{ __html: poi.modal.content }} />
                </InfoWindow>
              )}
            </Marker>
          );
        })}
        {pois.map((poi) => {
          if (activeType === poi.group)
            return (
              <Marker
                key={`${poi.id}_marker`}
                position={{
                  lat: poi.coordinate.lat,
                  lng: poi.coordinate.long,
                }}
                icon={poi.icon}
                onClick={(e) => {
                  showInfoWindow(poi);
                }}
                onLoad={(e) => {
                  activeMarkers.push(e.getPosition());
                }}
                onUnmount={(e) => {
                  let lat = e.getPosition().lat();
                  let lng = e.getPosition().lng();
                  activeMarkers.splice(
                    activeMarkers.findIndex((am) => {
                      return am.lng() === lng && am.lat === lat;
                    }, 1)
                  );
                }}
                data={poi}
              >
                {activePoi === poi.id && (
                  <InfoWindow
                    key={`${poi.id}_info_window`}
                    onCloseClick={closeInfoWindow}
                    position={{
                      lat: poi.coordinate.lat,
                      lng: poi.coordinate.long,
                    }}
                    options={{ closeBoxMargin: '100px 20px 2px 2px' }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: poi.modal.content }} />
                  </InfoWindow>
                )}
              </Marker>
            );
        })}
      </GoogleMap>
    );
  };

  return (
    <>
      <div className="wrap-location">
        {isLoaded && renderMap()}
        <div className={`card wrap-list-location side-card left-card`}>
          <h2 className="card-title">Location</h2>
          <div className="card-body">
            <Accordion defaultActiveKey={defaultActiveKey}>
              <ListPois />
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default Location;
