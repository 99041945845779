import qs from 'qs';
import Config from '../config';

export default class ApiClient {
  constructor(fetcher) {
    this.api_host = Config.LIFX_URL;
    this.fetcher = fetcher;
  }

  get(endpoint, params) {
    const query = params
      ? `?${qs.stringify(params, { skipNulls: true, skipEmptyStrings: true })}`
      : '';
    return this.fetcher(this.api_host + endpoint + query);
  }

  post(endpoint, data) {
    return this.fetcher(this.api_host + endpoint, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        apptype: 'WEB_APP',
      },
      body: JSON.stringify(data),
    });
  }

  put(endpoint, data) {
    return this.fetcher(this.api_host + endpoint, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  }
}
