import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { reqSetIsShowCreateCustomerModal, reqSetIsShowStartSessionModal } from '../../reduxs/home/action';
import customerApi from '../../apis/api/customer';
import { toast } from 'react-toastify';

const CreateCustomerModal = () => {
  const dispatch = useDispatch();
  const isShowCreateCustomerModal = useSelector((state) => state.home.isShowCreateCustomerModal);
  const [firstname, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [note, setNote] = useState('');
  const [error, setError] = useState();

  const onCloseModal = () => {
    dispatch(reqSetIsShowCreateCustomerModal(false));
  }

  const onBacktoStartSessionModal = () => {
    dispatch(reqSetIsShowCreateCustomerModal(false));
    dispatch(reqSetIsShowStartSessionModal(true));
  }

  const validattionSchema = yup.object().shape({
    firstname: yup.string().required(),
    surname: yup.string().required(),
    email: yup.string().email().required(),
    mobile: yup.string().notRequired(),
    note: yup.string().notRequired(),
  });

  const handleCreateCustomer = async () => {
    const data = !!mobile ? {
      firstname: firstname,
      surname: surname,
      email: email,
      mobile: mobile,
      note: note,
    } : {
      firstname: firstname,
      surname: surname,
      email: email,
      note: note,
    };

    try {
      await validattionSchema.validate(data);
    } catch (err) {
      return setError(err);
    }

    try {
      await customerApi.createCustomer(data);
      toast.info('Created customer successfully!');
      dispatch(reqSetIsShowCreateCustomerModal(false));
      dispatch(reqSetIsShowStartSessionModal(true));
    } catch (error) {
      return setError({
        arg: error?.data[0]?.arg,
        message: error?.data[0].reason,
      })
    }
  };

  return (
    <div className={`wrap-modal wrap-modal-session text-start ${isShowCreateCustomerModal ? 'show' : 'hide'}`}>
      <div className="h-100">
        <div className={`modal-form h-100`}>
          <div className="pe-5">
            <h3 className="modal-form__title">SET UP A NEW USER FOR THE STANLY RANCH EXPERIENCE APP</h3>
            <p className="modal-form__subtitle">Create a new user for today’s session</p>
          </div>
          <div className="modal-form__body">
            <div className="form-group d-flex justify-content-between">
              <input
                value={firstname || ""}
                onChange={(e) => setFirstName(e.target.value)}
                type="text"
                className="form-control border-dashed me-2"
                placeholder="Firstname (required)"
              />
              <input
                value={surname || ""}
                onChange={(e) => setSurname(e.target.value)}
                type="text"
                className="form-control border-dashed"
                placeholder="Surname (required)"
              />
            </div>
            <div className="form-group">
              <input
                value={email || ""}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                className="form-control border-dashed"
                id="email"
                placeholder="Email Address (required)"
              />
            </div>
            <div className="form-group">
              <select className="form-control border-dashed form-select" aria-label="select role">
                <option value="2">Customer</option>
              </select>
            </div>
            <div className="form-group">
              <input
                value={mobile || ""}
                onChange={(e) => setMobile(e.target.value)}
                type="number"
                className="form-control border-dashed"
                id="phone"
                placeholder="PHONE NUMBER"
              />
              <p className="text-danger mt-2 mb-0">{error?.message}</p>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <button onClick={() => onBacktoStartSessionModal()} className="btn btn-lg btn-link ps-0 pb-0 ms-0">Back</button>
              <button
                onClick={() => handleCreateCustomer()}
                className="btn btn-lg btn-link pe-0 pb-0 me-0"
                >
                Create new User
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="close-btn p-4" onClick={() => onCloseModal()}>
        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="8.57918" y1="8.73918" x2="25.8968" y2="26.0568" stroke="#282A2D" strokeWidth="2" />
          <line x1="25.897" y1="8.5782" x2="8.57943" y2="25.8958" stroke="#282A2D" strokeWidth="2" />
        </svg>
      </div>
    </div>
  )
}

export default CreateCustomerModal;
