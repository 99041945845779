import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.scss';

const Gallery = (props) => {
  const { images } = props;
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    className: 'slides',
    focusOnSelect: false,
    adaptiveHeight: false,
    arrows: false,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
  };

  return (
    <Slider {...settings}>
      {(images || []).map((item, index) => {
        return (
          <div key={index} className="executive-gallery-item">
            <div className="detail" style={{ backgroundImage: `url(${item})` }} />
          </div>
        );
      })}
    </Slider>
  );
};

export default Gallery;
