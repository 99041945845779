import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { getMediaUrl } from '../../helper/media';
import jwtAuth from '../../apis/utils/jwtAuth';
import './top-nav.scss';

const TopNav = (props) => {
  const { pathname } = useLocation();
  const history = useHistory();

  const getActiveClass = (path) => {
    return path === pathname ? 'active' : '';
  }

  const logout = () => {
    jwtAuth.removeToken();
    history.push('/sign-in');
  }

  return (
    <div className={`wrap-top-nav-cms show`}>
      <div className="nav-menu">
        <ul>
          <li>&nbsp;</li>
          <li>
            <Link to="/">
              <span className="menu-img p-0">
                <img src="/logo.png" />{' '}
              </span>
            </Link>
          </li>
            <li className={getActiveClass('/cms/properties')}>
              <Link to="/cms/properties" className="text-decoration-none">
                <span> PROPERTIES</span>
              </Link>
            </li>
            <li className={getActiveClass('/cms/customers')}>
              <Link to="/cms/customers" className="text-decoration-none">
              <span> CUSTOMERS</span>
              </Link>
            </li>
          <li>
            <Link to="/">
              <div id="wrap-cube-model" draggable="true">
                <div id="cube-model">
                  <img src={`${getMediaUrl('/images/cube/cube_forward.gif')}`} alt="" className="img-fluid" />
                </div>
              </div>
            </Link>
          </li>
          <li className={getActiveClass('/cms/frequently-asked-questions')}>
            <Link to="/cms/frequently-asked-questions" className="text-decoration-none">
              <span> FAQS</span>
            </Link>
          </li>
          <li onClick={() => logout()}>
            <span> LOG OUT</span>
          </li>
          <li>
          &nbsp;
          </li>
          <li>&nbsp;</li>
        </ul>
      </div>
    </div>
  );
};

export default TopNav;
