import React from 'react';
import { Provider } from 'react-redux';
import { Router } from './routers/Router';
import store from './reduxs/store';
import './App.scss';
import { fetch3DSettings, fetchAssetsList, fetchHotspotsList } from './reduxs/scene/action';

store.dispatch(fetch3DSettings());
store.dispatch(fetchAssetsList());
store.dispatch(fetchHotspotsList());

const App = () => (
  <Provider store={store}>
    <Router />
  </Provider>
);

export default App;
