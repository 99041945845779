import React from "react";
import { useSelector } from 'react-redux';
import socket from '../../helper/socket';
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { reqGetUserProfile } from "../../reduxs/user/action";
import Panorama from "./panorama";
import { useHistory } from "react-router-dom";

const PanoramaPage = (props) => {
  const { roles, authMiddleware } = props;
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.user.data);
  const history = useHistory();

  useEffect(() => {
    authMiddleware(roles, history);
    dispatch(reqGetUserProfile());
  }, []);

  useEffect(() => {
    if (authUser && authUser.id) {
      socket.auth = {
        userId: authUser.id
      };
      socket.connect();

      return () => {
        socket.disconnect();
      }
    }
  }, [authUser]);

  const renderPanorama = () => {
    if (authUser == null || authUser == undefined || authUser.id == undefined) {
      return <div />;
    }

    const names = [
      'The Village',
      'Lavender Pool',
      'Lavender Bowl',
      'Villa Wetlands',
      'Hale House',
    ];

    const images = [
      '/uploads/panorama/The_Village.jpg',
      '/uploads/panorama/Lavender_Pool.jpg',
      '/uploads/panorama/Lavender_Bowl.jpg',
      '/uploads/panorama/Villa_Wetlands.jpg',
      '/uploads/panorama/Hale_House.jpg',
      ];
    
    const firstImage = '/uploads/panorama/The_Village_Small.jpg';

    return (
      <Panorama name={"The Village"} isPresentation={true} names={names} images={images} firstImage={firstImage} />
    )
  }

  return (
    <div>
      {renderPanorama()}
    </div>
  );
}

export default PanoramaPage;
