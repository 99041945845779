import * as responseProcessors from './responseProcessors';
import jwtAuth from './jwtAuth';
import lifxAuth from './lifxAuth';

const jwtFetcher = jwtAuth.getFetchFn();
const lifxFetcher = lifxAuth.getFetchFn();

export const defaultFetcher = (url, options) =>
  jwtFetcher(url, options)
    .then(responseProcessors.logoutOnUnauthorized)
    .then(responseProcessors.toJson)
    .then(responseProcessors.rejectInvalidStatusCode)
    .then(responseProcessors.getJson)
    .then(responseProcessors.checkErr);

export const authFetcher = (url, options) =>
  fetch(url, options)
    .then(responseProcessors.toJson)
    .then(responseProcessors.rejectInvalidStatusCode)
    .then(responseProcessors.getJson)
    .then(responseProcessors.checkErr);

export const lifxAuthFetcher = (url, options) =>
  lifxFetcher(url, options)
    .then(responseProcessors.toJson)
    .then(responseProcessors.rejectInvalidStatusCode)
    .then(responseProcessors.getJson)
    .then(responseProcessors.checkErr);
