import * as type from './type';

const initialState = {
  isShowFilms: false
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case type.IS_SHOW_FILMS:
      return {
        ...state,
        isShowFilms: action.data,
      };
    default:
      return state;
  }
};
