import React from 'react';
import { useSelector } from 'react-redux';
import socket from '../../helper/socket';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { reqGetUserProfile } from '../../reduxs/user/action';
import Panorama from './panorama';
import { useHistory } from 'react-router-dom';
import './index.scss';


const PanoramaVineyardPage = (props) => {
  const { roles, authMiddleware } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const authUser = useSelector((state) => state.user.data);

  const isPresentation = useSelector((state) => state.home.isPresentation);

  useEffect(() => {
    dispatch(reqGetUserProfile());
    authMiddleware(roles, history);
  }, []);

  useEffect(() => {
    if (authUser && authUser.id) {
      socket.auth = {
        userId: authUser.id,
      };
      socket.connect();

      return () => {
        socket.disconnect();
      };
    }
  }, [authUser]);

  const renderPanorama = () => {
    if (authUser == null || authUser == undefined || authUser.id == undefined) {
      return <div />;
    }

    const names = [
      'Entry',
      'Loggia',
      'Kitchen',
      'Primary Bedroom',
      'Primary Bathroom',
      'Firepit',
    ];

    const images = [
      '/uploads/panorama/Vineyard_Entry.jpg',
      '/uploads/panorama/Vineyard_Loggia.jpg',
      '/uploads/panorama/Vineyard_Kitchen.jpg',
      '/uploads/panorama/Vineyard_Master_Bedroom.jpg',
      '/uploads/panorama/Vineyard_Master_Bathroom.jpg',
      '/uploads/panorama/Vineyard_Firepit.jpg',
      ];

    const firstImage = '/uploads/panorama/Vineyard_Entry.jpg';

    return <Panorama name={"VINEYARD HOMES"} isPresentation={isPresentation} names={names} images={images} firstImage={firstImage} />;
  };

  return (
    <div>
      {renderPanorama()}
    </div>
  );
};

export default PanoramaVineyardPage;
