import React, { useEffect, useState } from 'react';
import * as PANOLENS from 'panolens';
import * as THREE from 'three';
import { useSelector } from 'react-redux';
import socket from '../../helper/socket';
import { WEBSOCKET_CHANNEL } from '../../constants/options';
import { ImageLoader } from 'three';
import LoadingIcon from '../../components/loading-icon';
import { reqSetPanoName } from '../../reduxs/home/action';

const Panorama = (props) => {
  const { isPresentation, name, names, images, firstImage } = props;
  const authUser = useSelector((state) => state.user.data);
  const [isLoading, setIsLoading] = useState(true);

  let latestTime = 0;
  let currentIndex = 0;

  if (authUser == null || authUser == undefined) {
    return (
      <>
        <div id="wrap-panorama" />
      </>
    );
  }

  var viewer;

  let panos = [undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, undefined, undefined, undefined];

  const loader = new ImageLoader();

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_PANORAMA_ACTION, ({ content }) => {
        const position = content.position;
        viewer.camera.position.set(position.x, position.y, position.z);
        viewer.camera.zoom = content.zoom;
        viewer.camera.quaternion.set(content.quaternion.x, content.quaternion.y, content.quaternion.z, content.quaternion.w);
        viewer.camera.fov = content.fov;
        viewer.camera.updateProjectionMatrix();
        if (content.index != undefined && content.index != currentIndex && panos[content.index]) {
          viewer.setPanorama(panos[content.index]);
          currentIndex = content.index;
        }
      });

      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({content}) => {
        switch (content.action) {
          case "GO_PANO_GENERAL":
            if (panos[content.index] && content.time > latestTime) {
              viewer.setPanorama(panos[content.index]);
              latestTime = content.time;
              currentIndex = content.index;
            }
            break;
          default:
            break;
        }
      });
    }

  }, [isPresentation]);

  useEffect(() => {
    if (name) {
      // reqSetPanoName(name);
    }
  }, [name]);

  const onEmitSharePanoramaAction = (content) => {
    socket.emit(WEBSOCKET_CHANNEL.SHARE_PANORAMA_ACTION, {
      content,
      to: content.authUserId,
      from: content.authUserId,
    });
  }

  const onInitPanorama = () => {
    panos = [];
    const length = images.length;
    if (length <= 0) {
      return;
    }

    for (let index = 0; index < length; index++) {
      panos.push(undefined);
    }

    if (firstImage != undefined) {
      loader.load(firstImage, function ( image ) {
        let panoTemp = new PANOLENS.ImagePanorama(image);

        panos[0] = panoTemp;
        viewer.add(panoTemp);
        viewer.setPanorama(panoTemp);

        const image1stURL = images[0];

        loader.load(image1stURL, function ( image ) {
          let pano = new PANOLENS.ImagePanorama(image);
          
          panos[0] = pano;
          viewer.add(pano);
          viewer.setPanorama(pano);
          
          console.log("Loaded 1st image after 1st le 22");

          for (let index = 1; index < images.length; index++) {
            const imageURL = images[index];
      
            loader.load(imageURL, function ( image ) {
              let pano = new PANOLENS.ImagePanorama(image);
      
              panos[index] = pano; 
              viewer.add(pano);
              
            }, undefined, function (error) {
              console.error( 'An error happened.' , error);
            });
          };

        }, undefined, function (error) {
          console.error( 'An error happened.' , error);
        });

      }, undefined, function (error) {
        console.error( 'An error happened.' , error);
      });
    } else {
      const image1stURL = images[0];

      loader.load(image1stURL, function ( image ) {
        let pano = new PANOLENS.ImagePanorama(image);

        panos[0] = pano;
        viewer.add(pano);
        viewer.setPanorama(pano);

        console.log("Loaded 1st image");

        for (let index = 1; index < images.length; index++) {
          const imageURL = images[index];
    
          loader.load(imageURL, function ( image ) {
            let pano = new PANOLENS.ImagePanorama(image);
    
            panos[index] = pano; 
            viewer.add(pano);

            console.log("Loaded image ", index);

          }, undefined, function (error) {
            console.error( 'An error happened.' , error);
          });
        };

      }, undefined, function (error) {
        console.error( 'An error happened.' , error);
      });
    }

    viewer = new PANOLENS.Viewer({
      container: document.querySelector("#wrap-panorama")
    });
    let cameraPos = {};

    if (viewer.camera != null && authUser != null) {
      viewer.camera.userData.id = authUser.id;
    }
    viewer.camera.fov = 80;
    viewer.camera.updateProjectionMatrix();

    let control = viewer.getControl();
    control.noZoom = true;
    viewer.onChange = () => {
      viewer.update();
      viewer.render();

      let position = {
        x: viewer.camera.position.x,
        y: viewer.camera.position.y,
        z: viewer.camera.position.z
      };

      let quaternion = {
        x: viewer.camera.quaternion.x,
        y: viewer.camera.quaternion.y,
        z: viewer.camera.quaternion.z,
        w: viewer.camera.quaternion.w,
      }

      let content = {
        position: position,
        quaternion: quaternion,
        zoom: viewer.camera.zoom,
        fov: viewer.camera.fov,
        authUserId: viewer.camera.userData.id,
        index: currentIndex
      };

      if (cameraPos !== JSON.stringify(content)) {
        cameraPos = JSON.stringify(content);

        if (!isPresentation) {
          onEmitSharePanoramaAction(content);
        }
      }
    }
  }

  onInitPanorama();

  const renderListPanorama = () => {
    // if (isPresentation) {
    //   return <div />;
    // }
    let i = 0;
    const loading = () => {
      if (i == 0) {
        i = 1;
        let bar = document.getElementById('myBar');
        let progress = document.getElementById('myProgress');
        bar.style.display = 'block';
        progress.style.display = 'block';
        let width = 1;
        let id = setInterval(frame, 10);
        function frame() {
          if (width >= 100) {
            bar.style.display = 'none';
            progress.style.display = 'none';
            clearInterval(id);
            i = 0;
          } else {
            width++;
            bar.style.width = width + '%';
          }
        }
      }
    };
    const select = () => {
      let item = document.getElementsByClassName('item-name');
      for (var i = 0; i < item.length; i++) {
        item[i].addEventListener('click', function () {
          var current = document.getElementsByClassName('active');
          current[0].className = current[0].className.replace(' active', '');
          this.className += ' active';
        });
      }
    }
    return (
      <div className="panorama-side-bar">
        <div className="list-panorama">
          {names.map((item, index) => (
            <div key={index} className="list-panorama-item">
              <span
                id={`e${index}`}
                className={`item-name ${index === 0 ? 'active' : ''}`}
                onClick={() => {
                  if (isPresentation) return;

                  loading();
                  select();
                  if (panos[index] == undefined) {
                    return;
                  }
                  viewer.setPanorama(panos[index]);
                  let content = {
                    action: 'GO_PANO_GENERAL',
                    index: index,
                    time: Date.now()
                  };
                  currentIndex = index;
                  socket.emit(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, {
                    content,
                    to: viewer.camera.userData.id,
                    from: viewer.camera.userData.id,
                  });
                }}
              >
                {item}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      {isLoading && 
        <div className="position-absolute top-50 start-50 translate-middle intro-content pt-3">
          <div className="wrap-text-center">
            <figure className="mx-auto" style={{ width: '92px', height: '92px' }}>
              <img src="./images/logo-loader.png" alt="" className="img-fluid" />
            </figure>
            <p />
            <figure className="mx-auto" style={{ width: '492px', height: '163px' }}>
              <img src="./images/StanlyRanch_logo.png" alt="" className="img-fluid" />
            </figure>
            <figure className="mx-auto" style={{ width: '492px', height: '163px' }}>
              <h1>{name}</h1>
            </figure>
          </div>
          <div className="loader-wrapper">
            <LoadingIcon />
          </div>
        </div>
      }
      <div id="wrap-panorama" />
      {renderListPanorama()}
      <div id="myProgress">
        <div id="myBar" />
      </div>
    </>
  );
};

export default Panorama;
