/* eslint-disable no-unused-vars */
import React from 'react';
import './reset-password.scss';
import TopNav from '../../components/top-nav';
import { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { validatePassword } from '../../helper/validation';
import authApi from '../../apis/api/auth';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ResetPassword = () => {
  const query = useQuery();
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [message, setMessage] = useState({});

  useEffect(() => {
    if (validatePassword(password)) {
      delete message.password;
      setMessage({ ...message });
    } else {
      message.password =
        'The password must be minimum eight characters, at least one letter and one number.';
      setMessage({ ...message });
    }
  }, [password]);

  useEffect(() => {
    if (password === passwordConfirmation) {
      delete message.passwordConfirmation;
      setMessage({ ...message });
    } else {
      message.passwordConfirmation = 'The password confirmation does not match.';
      setMessage({ ...message });
    }
  }, [passwordConfirmation]);

  const handleResetPassword = async () => {
    const res = await authApi.resetPassword({
      password: password,
      password_confirmation: passwordConfirmation,
      token: query.get('token'),
    });
    if (res.data) {
      history.push('/sign-in');
    } else {
      message.passwordConfirmation = res.message;
      setMessage({ ...message });
    }
  };

  return (
    <div className="wrap-reset-password-page">
      <TopNav isShow={false} handleClickCube={() => {}} />
      <div className={`wrap-intro show`}>
        <video
          autoPlay
          muted
          loop
          preload="none"
          id="intro-video"
          style={{ backgroundImage: `url("./uploads/images/poster.jpg")` }}
        >
          <source src="./uploads/images/introduce.mp4" type="video/mp4" />
        </video>
        <div className="position-absolute top-50 start-50 translate-middle">
          <div className={`modal-form`}>
            <h3 className="modal-form__title">Reset Password</h3>
            <div className="modal-form__body">
              <div className="form-group">
                <input
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-control"
                  id="password"
                  placeholder="Password"
                />
                <p className="text-danger mt-n2 mb-4">{message.password}</p>
              </div>
              <div className="form-group">
                <input
                  type="password"
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  className="form-control"
                  id="password-confimation"
                  placeholder="Password Confirmation"
                />
                <p className="text-danger mt-n2 mb-4">{message.passwordConfirmation}</p>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="login">
                  <Link to="/sign-in" className="btn-text">
                    Sign In
                  </Link>
                </div>
                <button
                  type="submit"
                  onClick={() => handleResetPassword()}
                  className="btn-login btn btn-lg m-0"
                  disabled={!!Object.keys(message).length}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
