/* eslint-disable no-unused-vars */
import React from 'react';
import { useParams } from 'react-router';
import socket from '../../helper/socket';
import { useEffect } from 'react';
import { useState } from 'react';
import PostEngagement from '../../components/post-engagement';

const Customer = () => {
  const { customerId } = useParams();
  const [content, setContent] = useState();

  useEffect(() => {
    socket.auth = { userId: customerId };
    socket.connect();

    socket.on('private_message', ({ content }) => {
      setContent(content);
    });

    socket.on('connect_error', (err) => {
      if (err.message === 'invalid user name') {
        socket.off('connect_error');
      }
    });

    return () => {
      socket.disconnect();
    }
  }, []);

  return (
    <PostEngagement content={content} />
  );
};

export default Customer;
