import React from 'react';
import './index.scss';

const RelatedAsset = () => {
  return (
    <>
      <div className="wrap-related-asset">
        <h1>IMAGE GALLERY HERE</h1>
      </div>
    </>
  );
};

export default RelatedAsset;
